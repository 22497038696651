import { DateTime } from "luxon";

import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import { TimeInMs } from "~/constants/measurements";
import { t } from "~/i18n";
import { ScoreMeal } from "~/typing/sidekickTypes";

export default function useScoreMeals({
  programId,
  locale,
  userId,
  startDate,
  endDate,
  disabled
}: {
  programId: string;
  locale: string;
  userId: string;
  startDate?: Date;
  endDate?: Date;
  disabled?: boolean;
}) {
  let path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/scoremeals`;

  if (startDate) {
    path += `?startDate=${DateTime.fromJSDate(startDate)
      .minus({ days: 1 })
      .toISODate()}`;
  }
  if (endDate) {
    path += `${startDate ? "&" : "?"}endDate=${DateTime.fromJSDate(endDate)
      .plus({ days: 1 })
      .toISODate()}`;
  }

  const { data, isError, isLoading, invalidate } = useApi<{
    scoremeals: ScoreMeal[];
  }>({
    path,
    queryKey: QueryKeyFactory.users.scoreMeals(programId, locale, userId),
    staleTime: TimeInMs.Minute * 5,
    errorToastMessage: t("errors.fetch", { resource: t("general.meals") }),
    disabled: !programId || !locale || !userId || disabled
  });

  return {
    scoreMeals: data?.scoremeals ?? [],
    isError,
    isLoading,
    invalidate
  };
}

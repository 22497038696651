import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import styles from "./ActivityPanel.module.scss";

import Accordion from "~/components/accordion/Accordion";
import { UserContextInfo } from "~/typing/graphql/types";

type ActivityPanelProps = {
  userContextInfo?: UserContextInfo;
};

const ActivityPanel = ({ userContextInfo }: ActivityPanelProps) => {
  const { t } = useTranslation();

  return (
    <Accordion
      title={t("nextStep.activity")}
      subTitle={t("nextStep.lastSevenDays")}
    >
      <div className={styles.activity}>
        {Object.entries(userContextInfo?.userActivityLastWeek?.activeDates).map(
          ([date, isActive], index) => (
            <div
              key={index}
              className={
                isActive
                  ? `${styles.activityPill} ${styles.active}`
                  : styles.activityPill
              }
            >
              <div style={isActive ? { color: "green" } : undefined}>
                {DateTime.fromISO(date).toFormat("EEEE")}
              </div>
            </div>
          )
        )}
      </div>
    </Accordion>
  );
};

export default ActivityPanel;

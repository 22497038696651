import { GraphQLClient, ClientError } from 'graphql-request'
import { type RequestConfig, Variables } from 'graphql-request/src/types'

import { Exception } from '~/classes/exception/Exception'


/**
 * @class GraphqlClient
 * A class that wraps the `graphql-request` library to make requests to the server with graphql
 * All GraphQL requests should be made through this class to ensure that all requests are handled in a consistent manner,
 * as well as to ensure that all errors are logged and displayed to the user in a consistent manner
 * @param url - The URL of the graphql server
 * @param requestConfig - The request configuration to be used for all requests made with this client
 * @see
 * [graphql-request](https://www.npmjs.com/package/graphql-request)
 * [Exception](../exceptions/Exception.ts)
 */
export class GraphqlClient {
  private client: GraphQLClient

  constructor(
    url: string, 
    public readonly requestConfig: RequestConfig = {}
  ) {
    this.client = new GraphQLClient(url, requestConfig)
  }

  /**
   * Make a request to the server with graphql
   * @param query - The graphql query to be made
   * @param variables - The variables to be used in the query
   * @param headers - The headers to be used in the request
   * @returns {Promise<T>} - The response from the server
   * @throws {Exception} - An exception is thrown if an error occurs while making the request
   * @see
   * [Exception](../exceptions/Exception.ts)
   **/
  async request<T>(query: string, variables?: Variables, headers?: HeadersInit, display: boolean = true): Promise<T> {
    try {
      return await this.client.request(query, variables, headers).then((data: any) => {
        // If the server returns an error, log and display the error to the user using the Exception class
        if (data?.errors) {
          data.errors.forEach((error: any) => {
            new Exception({
              message: error?.message || JSON.stringify(error, null, 2),
              title: error?.name || 'An error occurred while making a request to the server (GraphQL)',
              context: {
                query,
                variables,
                headers,
                error
              },
              path: query.toString()
            })
              .log()
          })
        }

        // If the server returns a response, return the response to the caller
        return Promise.resolve(data)
      })
    } catch (error: any) {
      let statusCode = 400
      if (error?.code) {
        statusCode = error.code
      }
      if (error?.statusCode) {
        statusCode = error.response.status
      }
      if (error?.response?.status) {
        statusCode = error.response.status
      }
      // If the server returns an error, log and display the error to the user using the Exception class
      if (error instanceof ClientError) {
        const exception = new Exception({
          message: error.message,
          statusCode,
          title: error.name || 'Bad Request',
          context: {
            query,
            variables,
            headers,
            stack: error.stack
          },
          path: query.toString()
        })
        exception.log()
        throw exception
      }
      // If the server returns an error that is not an instance of Error,
      // log and display the error to the user using the Exception class
      const exception = new Exception({
        message: JSON.stringify(error, null, 2),
        statusCode,
        title: 'An error occurred while making a request to the server (GraphQL)',
        context: {
          query,
          variables,
          headers,
          error
        },
        path: query.toString()
      })
      exception.log()
      throw exception
    }
  }
}




import {
  setColumns,
  toggleColumn,
  setFilter,
  setCurrentPage
} from "./customListSlice";

import store from "../store";

import { TableHeading } from "~/typing/carePortalTypes";

export const toggleListColumn = (key: string, column: TableHeading) => {
  store.dispatch(toggleColumn({ key, column }));
};

export const setListColumns = (key: string, columns: TableHeading[]) => {
  store.dispatch(setColumns({ key, columns }));
};

export const setListFilter = (key: string, filterKey: string, value: any) => {
  store.dispatch(setFilter({ key, filterKey, value }));
};

export const setCurrentPaginationPage = (key: string, currentPage: number) => {
  store.dispatch(setCurrentPage({ key, currentPage }));
};

import { useState, useEffect } from "react";

import styles from "./GoToUserPage.module.scss";

import api from "~/api/api";
import Button from "~/components/button/Button";
import Input from "~/components/input/Input";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import config from "~/config";
import useUrlQuery from "~/hooks/useUrlQuery";

export default function GoToUserPage() {
  const query = useUrlQuery();
  const [anthemHRSID, setAnthemHRSID] = useState(query.get("memberID") || "");
  const [caseIdentifier, setCaseIdentifier] = useState(
    query.get("caseID") || ""
  );
  const [userId, setUserId] = useState(query.get("userId") || "");
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(undefined);
    const path = "/coach/findexternaluserprograminfo";
    const payload = {
      anthemHRSID: anthemHRSID,
      caseIdentifier: caseIdentifier,
      linkedUserId: userId
    };

    await api
      .post(path, payload)
      .then((res) => {
        const { data } = res;

        if (data) {
          const { programCatalogItemId, locale, userId } = data.items[0];
          const redirectPath = `/program/${programCatalogItemId}/locale/${locale}/users/${userId}`;
          window.location.href = redirectPath;
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  //If userId is present in the query params, the user should be automatically redirected,
  //so we submit the form without user interaction
  useEffect(() => {
    if (userId) {
      handleSubmit(new Event("submit"));
    }
  }, []);

  return (
    <SentryErrorBoundary transactionName="GoToUserPage">
      <div className={styles.GoToUserPage}>
        <h1 className={styles.title}>Go to user</h1>
        <form onSubmit={handleSubmit}>
          {config.isAnthem && (
            <Input
              placeholder="anthemHRSID / memberID"
              autoFocus
              value={anthemHRSID}
              onChange={setAnthemHRSID}
            />
          )}
          {config.isAnthem && (
            <Input
              placeholder="caseIdentifier"
              value={caseIdentifier}
              onChange={setCaseIdentifier}
            />
          )}
          <Input placeholder="User ID" value={userId} onChange={setUserId} />
          <Button type="submit" disabled={isLoading}>
            Go to user
          </Button>
          {error && <p className={styles.error}>{error}</p>}
        </form>
      </div>
    </SentryErrorBoundary>
  );
}

export const linesOfBusiness = [
  "MEDICARE",
  "MCR INDIV",
  "MCR GRS",
  "MCR DSNP",
  "INDIV",
  "LOC",
  "MEDICAID",
  "HLINK",
  "WLP",
  "Commercial National"
];

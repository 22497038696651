import { DateTime } from "luxon";

import { getTimeZoneAdjustedDate } from "./date/adjustToTimeZone";
import { isBetweenDates } from "./date/isBetweenDates";
import getHealthinessText from "./getHealthinessText";
import getMissionValueText from "./mission/getMissionValueText";

import { t } from "~/i18n";
import {
  getMealTypeText,
  getPortionSizeCharacter
} from "~/pages/user/performance/journals/journalhelper";
import { ChatMessage, SMSMessage } from "~/typing/sidekickTypes";

export function foodJournalText(
  scoremeals,
  timeZoneOffset,
  startDate,
  endDate
) {
  let text = "";
  text += sectionTitle(t("performance.journals.title"), 5);
  scoremeals?.forEach((journal) => {
    const date = isoToLocaleStringWithTime(
      getTimeZoneAdjustedDate(journal.serverDate, timeZoneOffset)
    );
    if (
      isBetweenDates(journal.serverDate, startDate, endDate, timeZoneOffset)
    ) {
      const title = `${t(getMealTypeText(journal.mealType))} (${date})`;
      text += sectionTitle(title, 1);

      text += `• ${t("performance.journals.review.portionSize")}: `;
      text += `${getPortionSizeCharacter(journal.portionSize)}\n`;

      if (journal.coachPortionSize) {
        text += `• ${t("performance.journals.review.coachPortionSize")}: `;
        text += `${getPortionSizeCharacter(journal.coachPortionSize)}\n`;
      }

      text += `• ${t("performance.journals.review.userRating")}: `;
      text += `${getHealthinessText(journal.healthyness)}\n`;

      if (journal.coachHealthyness) {
        text += `• ${t("performance.journals.review.coachRating")}: `;
        text += `${getHealthinessText(journal.coachHealthyness)}\n`;
      }

      if (journal.coachReview) {
        text += `• ${t("performance.journals.review.comment")}: `;
        text += `${journal.coachReview}\n`;
      }
    }
  });

  text += "\n\n";
  return text;
}

export function messagesText({
  messages,
  getMessageSenderName,
  startDate,
  endDate,
  isSms
}: {
  messages: ChatMessage[] | SMSMessage[];
  getMessageSenderName: (message: ChatMessage) => string;
  startDate: Date;
  endDate?: Date;
  isSms: boolean;
}) {
  if (!messages?.length) return "";
  let text = "";
  text += isSms
    ? sectionTitle(t("exportPreview.smsMessages"))
    : sectionTitle(t("general.messages"));
  messages.forEach((message) => {
    if (isBetweenDates(message.createdDate, startDate, endDate)) {
      const date = isoToLocaleStringWithTime(message.createdDate);
      const name = getMessageSenderName(message);
      const messageText = isSms ? message.message : message.text;
      text += `• ${name} (${date}): ${messageText}\n`;
    }
  });
  text += "\n\n";
  return text;
}

export function notesText(notes, startDate, endDate) {
  let text = "";
  text += sectionTitle(t("general.notes"));

  notes.forEach((note) => {
    const date = isoToLocaleStringWithTime(note.createdDate);
    if (isBetweenDates(note.createdDate, startDate, endDate)) {
      text += `• ${note.coachUser?.displayName} (${date}): ${note.text}\n`;
    }
  });
  text += "\n\n";

  return text;
}

export function proText(surveysData, startDate, endDate) {
  let text = "";
  text += sectionTitle(t("general.pro"), 5);
  Object.keys(surveysData).forEach((surveyName) => {
    text += sectionTitle(surveyName, 3);
    const surveyData = surveysData[surveyName];
    surveyData.forEach((result) => {
      const date = isoToLocaleStringWithTime(result.date);
      if (isBetweenDates(result.date, startDate, endDate)) {
        text += sectionTitle(date, 1);

        result.questions?.map((question) => {
          const answers = question.answers
            ?.map((answer) => answer.title || answer.answer)
            .join(" || ");
          text += `• ${question.title}: ${answers}\n`;
        });
      }
    });
    if (surveyData.length === 0) {
      text += `• ${t("exportPreview.noResults", "No results")}\n`;
    }
  });
  text += "\n\n";
  return text;
}

export function performanceText(
  slotDays,
  performanceData,
  fitnessTestData,
  sleepTestData,
  stressTestData,
  energyTestData,
  useMetric,
  startDate,
  endDate
) {
  let text = "";
  text += missionsText(slotDays, startDate, endDate);
  text += physicalPerformanceText(
    performanceData,
    fitnessTestData,
    useMetric,
    startDate,
    endDate
  );
  text += mentalPerformanceText(
    sleepTestData,
    stressTestData,
    energyTestData,
    startDate,
    endDate
  );
  return text;
}

function missionsText(slotDays, startDate, endDate) {
  let text = "";
  text += sectionTitle(t("exportPreview.missions"), 5);
  slotDays.forEach((day) => {
    const date = isoToLocaleStringWithTime(day.date);
    if (isBetweenDates(day.date, startDate, endDate)) {
      text += sectionTitle(date, 1);
      day.tasks?.forEach((task) => {
        const { unit, currentValue, targetValue } = task;
        text += `• ${task.name} `;
        text += `(${getMissionValueText(unit, currentValue, targetValue)})\n`;
      });
      if (!day.tasks || day.tasks.length === 0) {
        text += `• ${t("exportPreview.noResults", "No results")}\n`;
      }
    }
  });
  text += "\n";
  return text;
}

function physicalPerformanceText(
  performanceData,
  fitnessTestData,
  useMetric,
  startDate,
  endDate
) {
  let text = "";
  text += sectionTitle(t("exportPreview.physicalperformance"), 5);

  const subtitle =
    t("exportPreview.activity") +
    ` ${t("general.and")} ` +
    t("exportPreview.stepCounter");

  text += sectionTitle(subtitle, 3);

  performanceData.forEach((point) => {
    const date = isoToLocaleStringWithTime(point.date);
    if (isBetweenDates(point.date, startDate, endDate)) {
      text += sectionTitle(date, 1);
      text += `• ${t("exportPreview.activity")}: `;
      text += `${point.physicalActivityMinutes} ${t("units.mins")}\n`;
      text += `• ${t("exportPreview.stepCounter")}: `;
      text += `${point.stepCount} ${t("units.steps")}\n`;
    }
  });
  text += "\n";

  text += sectionTitle(t("exportPreview.fitnessTest"), 3);
  fitnessTestData.forEach((point) => {
    const date = isoToLocaleStringWithTime(point.date);
    if (isBetweenDates(point.date, startDate, endDate)) {
      text += `• ${point.mins} ${t("units.mins")}: ${point.distance} `;
      text += useMetric ? t("units.meters") : t("units.miles");
      text += ` (${date})\n`;
    }
  });
  text += "\n";
  return text;
}

function mentalPerformanceText(
  sleepTestData,
  stressTestData,
  energyTestData,
  startDate,
  endDate
) {
  let text = "";
  text += sectionTitle(t("exportPreview.mentalPerformance"), 5);
  text += sectionTitle(t("performance.mental.sleepQuality"), 3);
  sleepTestData.forEach((point) => {
    const date = isoToLocaleStringWithTime(point.date);
    if (isBetweenDates(date, startDate, endDate)) {
      text += `• ${point.value} (${date})\n`;
    }
  });

  text += sectionTitle(t("exportPreview.stressLevel"), 3);
  stressTestData.forEach((point) => {
    const date = isoToLocaleStringWithTime(point.date);
    if (isBetweenDates(point.date, startDate, endDate)) {
      text += `• ${point.value} (${date})\n`;
    }
  });

  text += sectionTitle(t("exportPreview.energyLevel"), 3);
  energyTestData.forEach((point) => {
    const date = isoToLocaleStringWithTime(point.date);
    if (isBetweenDates(point.date, startDate, endDate)) {
      text += `• ${point.value} (${date})\n`;
    }
  });
  text += "\n\n";
  return text;
}

const isoToLocaleStringWithTime = (isoDate) => {
  return DateTime.fromISO(isoDate).toLocaleString({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
};

const sectionTitle = (text, num = 5) => {
  const decorator = "-".repeat(num);

  return `${decorator}${text}${decorator}\n`;
};

import styles from "./Switch.module.scss";

type SwitchProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
> & {
  onChange: (value: boolean) => void;
  checked: boolean;
};

const Switch = ({ onChange, ...rest }: SwitchProps) => {
  return (
    <label className={styles.toggleSwitchBoolean}>
      <input
        type="checkbox"
        onChange={(event) => onChange(event.target.checked)}
        {...rest}
      />
      <span className={styles.slider}></span>
    </label>
  );
};

export default Switch;

import Input from "../input/Input";

import SearchIcon from "~/assets/svgComponents/SearchIcon";
type SearchInputProps = {
  value?: string;
  onChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
  autoFocus?: boolean;
  dataTestId?: string;
  className?: string;
  isLoading?: boolean;
};

const SearchInput = ({
  value,
  onChange,
  placeholder,
  disabled,
  autoFocus,
  dataTestId,
  className,
  isLoading
}: SearchInputProps) => (
  <Input
    data-testid={dataTestId}
    autoFocus={autoFocus}
    className="input"
    value={value ?? ""}
    onChange={(value) => onChange(value)}
    placeholder={placeholder}
    disabled={disabled}
    leadingIcon={<SearchIcon />}
    wrapperClassName={className}
    isLoading={isLoading}
  />
);

export default SearchInput;

import round from "../number/round";
import kgToLbs from "../units/kgToLbs";

export const getWeightString = ({
  weight,
  useMetric = true
}: {
  weight?: number;
  useMetric?: boolean;
}): string => {
  if (!weight) return "";
  return useMetric ? round(weight, 1) + " kg" : kgToLbs(weight) + " lbs";
};

import api from "../api";

import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { t } from "~/i18n";

export const setCoachRatingForSurvey = async ({
  programId,
  locale,
  userId,
  surveyResultId,
  coachRatingValue,
  showSuccessToast = true
}: {
  programId: string;
  locale: string;
  userId: string;
  surveyResultId: string;
  coachRatingValue: number;
  showSuccessToast?: boolean;
}) => {
  const path = `/coach/programs/${programId}/locales/${locale}/users/${userId}/surveyresults/${surveyResultId}/coachrating`;
  return await api
    .post(path, { coachRatingValue })
    .then((res) => {
      if (showSuccessToast) {
        displaySuccessToast({ message: t("pro.success") });
      }
      return res.data;
    })
    .catch(() => displayErrorToast({ message: t("pro.error") }));
};

import { useTranslation } from "react-i18next";

import styles from "./SurveyResult.module.scss";

import Paragraph from "~/components/paragraph/Paragraph";
import ButtonR from "~/components/robinDesignSystem/button/ButtonR";
import { humanizedTimeSince } from "~/helpers/date/dateHelpers";
import { useApproveAllSurveyResults } from "~/hooks/graphql/useApproveAllSurveyResults";
import { useGetCarePriorities } from "~/hooks/graphql/useCareManagerTasks";
import { SurveyResultMetadata } from "~/typing/graphql/types";

type SurveyResultRowProps = {
  userId: string;
  metadata: SurveyResultMetadata;
  handleOpenSurvey: () => void;
};

const SurveyResultRow = ({
  userId,
  metadata,
  handleOpenSurvey
}: SurveyResultRowProps) => {
  const { t } = useTranslation();
  const { invalidate: invalidateCarePriorities } = useGetCarePriorities(userId);

  const { approveAllSurveyResultsAsync } = useApproveAllSurveyResults();

  const handleApprove = async () => {
    await approveAllSurveyResultsAsync({
      userId,
      surveyId: metadata.surveyId,
      coachRatingValue: metadata.score
    });
    //Invalidate the care priorities to refetch the data and show that the survey has been approved
    await invalidateCarePriorities();
  };

  return (
    <>
      <tr key={metadata?.surveyResultId}>
        <td>{humanizedTimeSince(metadata?.createdDate, false)}</td>
        <td>
          <span className={styles.score}>{metadata?.score}</span>
        </td>
        <td>{metadata?.surveyName}</td>
        <td className={styles.actions}>
          <ButtonR
            type="quaternary"
            label={t("nextStep.survey.view")}
            size="xs"
            onClick={handleOpenSurvey}
          />
          {metadata?.approved ? (
            <Paragraph
              text={t("nextStep.survey.approved")}
              size="xs"
              className={styles.approved}
            />
          ) : (
            <ButtonR
              type="quaternary"
              label={t("nextStep.survey.approve")}
              size="xs"
              onClick={handleApprove}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default SurveyResultRow;

import { useCallback, useState, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./Signup.module.scss";

import Button from "~/components/button/Button";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import Input from "~/components/input/Input";
import config from "~/config";

type SignupProps = {
  onSignup: (name: string, email: string, password: string) => void;
  isLoading?: boolean;
  error: ReactNode;
  showSuccessMessage?: boolean;
  privacyHref?: string;
  termsHref?: string;
};

const Signup = ({
  onSignup,
  error,
  isLoading = false,
  showSuccessMessage = false,
  privacyHref = "https://legal.sidekickhealth.com/2/en/care/privacy.html",
  termsHref = "https://legal.sidekickhealth.com/2/en/care/terms.html"
}: SignupProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPW, setInvalidPW] = useState(false);
  const autoFocus = useCallback((el) => (el ? el.focus() : null), []);

  // get query parameters from url
  const urlParams = new URLSearchParams(window.location.search);

  // get the value of the 'token' and 'email' query parameter
  const token = urlParams.get("token"); // TODO: Should we use the token to look up and validate the e-mail being used?
  const emailParam = urlParams.get("email");

  //If token and email is provided, this is a signup from an invite email.
  //If we are running on anthem-prod, and the email is anything other than @sidekickhealth.com,
  //we redirect to the Elevance SSO page, as they should not sign up with email and password.
  useEffect(() => {
    if (token && emailParam) {
      if (config.isAnthem && !emailParam.endsWith("@sidekickhealth.com")) {
        window.location.href = `/auth/acceptinvite?token=${token}&email=${emailParam}`;
      }
    }
  }, [emailParam, token]);

  const validate = () => {
    let valid = true;
    if (!name) {
      setInvalidName(true);
      valid = false;
    } else {
      setInvalidName(false);
    }

    // Leaving regex validation of email up to the browser
    if (!email) {
      setInvalidEmail(true);
      valid = false;
    } else {
      setInvalidEmail(false);
    }

    if (!password) {
      setInvalidPW(true);
      valid = false;
    } else {
      setInvalidPW(false);
    }

    return valid;
  };

  const hasAcceptedTermsAndPolicy = () => {
    if (config.isAnthem) {
      return true;
    } else {
      return termsAccepted && policyAccepted;
    }
  };

  const checkboxData = [
    {
      value: termsAccepted,
      setValue: setTermsAccepted,
      key: "termsAccepted",
      href: termsHref,
      linkText: t("signup.terms")
    },
    {
      value: policyAccepted,
      setValue: setPolicyAccepted,
      key: "policyAccepted",
      href: privacyHref,
      linkText: t("signup.privacyPolicy")
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return false;
    onSignup(name, email, password);
  };

  const formClasses = `${styles.Signup} ${error && styles["Signup--error"]}`;

  useEffect(() => {
    if (emailParam) {
      setEmail(emailParam.replace(" ", "+"));
    }
  }, [emailParam]);

  return (
    <div className={formClasses}>
      <div>
        <h1>{t("signup.signup")}</h1>
        {showSuccessMessage ? (
          <div>
            <h3>{t("signup.success.title")}</h3>
            <div>
              <p>{t("signup.success.message")}</p>
            </div>
            <div className={styles.Signup__success}>
              <Link to="/login">
                <button className="btn-primary">{t("login.login")}</button>
              </Link>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="form--block">
            <Input
              id="name"
              autoComplete="name"
              placeholder={t("signup.enterName")}
              ref={autoFocus}
              value={name}
              onChange={setName}
              label={t("signup.name_label")}
              error={invalidName ? t("signup.errors.missingName") : ""}
              required
            />
            <Input
              id="email"
              type="email"
              pattern=".+@.+\..+"
              autoComplete="username"
              placeholder={t("signup.enterEmail")}
              value={email}
              disabled={!!emailParam}
              onChange={setEmail}
              label={t("signup.email_label")}
              error={invalidEmail ? t("signup.errors.missingEmail") : ""}
              required
            />
            <Input
              id="password"
              type="password"
              autoComplete="password"
              placeholder={t("signup.enterPassword")}
              value={password}
              onChange={setPassword}
              label={t("signup.password_label")}
              error={invalidPW ? t("signup.errors.missingPassword") : ""}
              required
            />
            {!config.isAnthem &&
              checkboxData.map(({ value, setValue, key, href, linkText }) => {
                return (
                  <CheckboxInput
                    key={key}
                    checked={value}
                    onChange={(checked) => setValue(checked)}
                    label={
                      <>
                        {t("signup.acceptTerms")}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={href}
                        >
                          &nbsp;{linkText}&nbsp;
                        </a>
                      </>
                    }
                  />
                );
              })}

            {error && !invalidPW && !invalidEmail && !invalidName && (
              <div className="input__error-block">{error}</div>
            )}

            <div className={styles.Signup__actions}>
              <Link to="/login">{t("signup.useExisting")}</Link>
              <Button
                disabled={isLoading || !hasAcceptedTermsAndPolicy()}
                isLoading={isLoading}
              >
                {t("signup.signupSubmit")}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Signup;

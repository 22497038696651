import useApi from "../useApi";

import { CareTeamMember } from "~/typing/sidekickTypes";

type CoachesForProgramProps = {
  programId: string;
  locale: string;
};

export const useCoachesForProgram = ({
  programId,
  locale
}: CoachesForProgramProps) => {
  const { data, ...rest } = useApi<{ items: CareTeamMember[] }>({
    queryKey: ["coachesForProgram", programId],
    path: `/coach/programs/${programId}/locales/${locale}/coaches`
  });

  return {
    coaches: data?.items,
    ...rest
  };
};

import { useSelector } from "react-redux";

import { RootState } from "~/state/store";

// convenience hook to check logged in state
export default function useLoggedIn() {
  const { user } = useSelector((state: RootState) => state.user);

  return !!user;
}

import api from "../api";

import { FileUploadType } from "~/constants/fileUploadTypes";
import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import {
  ExternalUser,
  ExternalUserHistory,
  ExternalUserType
} from "~/typing/sidekickTypes";

export const getImportableExternalUserType = async (): Promise<
  ExternalUserType[]
> => {
  return await api
    .get(`/admin/importableexternalusertypes`)
    .then((res) => res.data.items ?? [])
    .catch(() => {
      displayErrorToast({ message: t("errors.generic") });
      return [];
    });
};

export const getExternalUserHistory = async (
  externalUserTypeId: string,
  externalUserId: string
): Promise<ExternalUserHistory | undefined> => {
  const path = `/admin/externalusertypes/${externalUserTypeId}/externalusers/${externalUserId}/history`;

  return await api
    .get(path)
    .then((res) => res.data)
    .catch(() => {
      displayErrorToast({
        message: t("errors.fetch", { resource: t("user.details.history") })
      });
    });
};

export const uploadExternalUsers = async ({
  externalUserTypeName,
  uploadToken,
  encodedFile,
  fileUploadType,
  uploadName
}: {
  externalUserTypeName?: string;
  uploadToken: string;
  encodedFile: string;
  fileUploadType: FileUploadType;
  uploadName?: string;
}): Promise<{ id: number; status: string } | undefined> => {
  return await api
    .post(
      `/admin/extras/uploads?externalUserTypeName=${externalUserTypeName}`,
      {
        uploadToken,
        file: encodedFile,
        fileUploadType,
        uploadName
      }
    )
    .then((res) => res.data)
    .catch(() => displayErrorToast({ message: t("errors.generic") }));
};

export const getExternalUserLinkedUser = async ({
  programId,
  locale,
  externalUserTypeId,
  userId
}: {
  programId: string;
  locale: string;
  externalUserTypeId: string;
  userId: string;
}): Promise<any> => {
  const path = `/coach/programs/${programId}/locales/${locale}/externalusertypes/${externalUserTypeId}/externalusers/${userId}/`;
  return await api
    .get(path)
    .then((res) => res.data.linkedUser)
    .catch(() =>
      displayErrorToast({
        message: t("errors.fetch", { resource: "Linked user" })
      })
    );
};

export const updateExternalUser = async ({
  programId,
  locale,

  externalUser
}: {
  programId: string;
  locale: string;

  externalUser: ExternalUser;
}): Promise<any> => {
  const path = `/coach/programs/${programId}/locales/${locale}/externalusertypes/${externalUser.externalUserTypeId}/externalusers/${externalUser.id}`;
  return await api.put(path, externalUser).catch(() =>
    displayErrorToast({
      message: t("errors.action", { resource: "Update external user" })
    })
  );
};

import { timeSince } from "../date/dateHelpers";
import { programHasBehaviour } from "../program/programHelpers";

import { TimeInMs } from "~/constants/measurements";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import {
  UserProgramStatus,
  UserProgramStatusReason
} from "~/constants/programStatus";
import { t } from "~/i18n";
import {
  CoachUser,
  ExternalUser,
  ExternalUserGroupOutput,
  Program,
  UserReadmission
} from "~/typing/sidekickTypes";

export const isPausedInProgram = (programStatus: number) => {
  return programStatus === UserProgramStatus.Paused;
};

export const hasQuitProgram = (
  programStatus: number,
  programStatusReason?: number
) => {
  return (
    programStatus === UserProgramStatus.Removed &&
    (programStatusReason === UserProgramStatusReason.Quit ||
      !programStatusReason)
  );
};

export const hasBeenDischargedFromProgram = (
  programStatus: number,
  programStatusReason?: number
) => {
  return (
    programStatus === UserProgramStatus.Removed &&
    programStatusReason === UserProgramStatusReason.Discharged
  );
};

export const hasFinishedProgram = (
  programStatus: number,
  programStatusReason?: number
) => {
  return (
    programStatus === UserProgramStatus.AlmostFinished ||
    programStatus == UserProgramStatus.Finished ||
    (programStatus === UserProgramStatus.Removed &&
      programStatusReason === UserProgramStatusReason.Exited)
  );
};

export const hasQuitOrFinished = (
  programStatus: number,
  programStatusReason?: number
) =>
  hasQuitProgram(programStatus) ||
  hasFinishedProgram(programStatus, programStatusReason);

export const isScaleInactive = (user: Partial<CoachUser>) => {
  if (!user.latestScaleDate) return false;
  return timeSince(new Date(user.latestScaleDate)) > 2 * TimeInMs.Week;
};

export const programIncludesMaintenance = (program?: Partial<Program>) => {
  if (!program || !program.behaviour) {
    return false;
  }

  return !!(
    program.behaviour & ProgramBehaviour.showMaintenanceStatusInCarePortal
  );
};

export const isInMaintenance = (
  user?: Partial<CoachUser>,
  program?: Partial<Program>
) => {
  if (
    !program ||
    !user ||
    !user.programStatus ||
    !programIncludesMaintenance(program)
  ) {
    return false;
  }

  // Finished or quit users cannot be in maintenance
  if (hasQuitOrFinished(user.programStatus, user.userProgramStatusReason)) {
    return false;
  }

  return user.isInMaintenanceSlot;
};

export const userHasActionAwaiting = (user?: Partial<CoachUser>) => {
  if (!user?.programStatus) return false;

  if (
    !user ||
    hasQuitProgram(user.programStatus, user.userProgramStatusReason) ||
    hasFinishedProgram(user.programStatus, user.userProgramStatusReason)
  ) {
    return false;
  }

  return (
    user.hasMealLogActions ||
    user.hasSurveyActions ||
    user.hasMessageActions ||
    user.unseenSms ||
    isScaleInactive(user)
  );
};

export const hasStatusDurationExpired = (
  statusDate: string,
  duration?: string
) => {
  // If the duration is not set, assume the status has not expired
  if (!duration) return false;

  return (
    new Date(statusDate).getTime() <
    new Date().getTime() - parseInt(duration) * TimeInMs.Second
  );
};

export const shouldShowRestartedStatusByRestartedDate = (
  user: Partial<CoachUser>,
  program?: Program
) => {
  if (
    !programHasBehaviour(program, ProgramBehaviour.Restartable) ||
    !user.restartedDate
  ) {
    return false;
  } else {
    if (program?.restartedStatusDurationSeconds) {
      return !hasStatusDurationExpired(
        user.restartedDate,
        program.restartedStatusDurationSeconds
      );
    } else {
      return true;
    }
  }
};

export const shouldShowRestartedStatusByUserReadmission = (
  userReadmission: UserReadmission,
  program?: Program
) => {
  if (
    !programHasBehaviour(program, ProgramBehaviour.Restartable) ||
    !userReadmission
  ) {
    return false;
  } else {
    if (program?.restartedStatusDurationSeconds) {
      return !hasStatusDurationExpired(
        userReadmission.createdDate,
        program.restartedStatusDurationSeconds
      );
    } else {
      return true;
    }
  }
};

export const getRetriggeredValue = (
  eligibleForRestart?: boolean,
  eligibleForRestartDate?: string,
  retriggeredStatusDurationSeconds?: string
) => {
  if (eligibleForRestart && eligibleForRestartDate) {
    if (retriggeredStatusDurationSeconds) {
      // If the user is eligible for restart but the duration has expired, we show the user as not retriggered
      return !hasStatusDurationExpired(
        eligibleForRestartDate,
        retriggeredStatusDurationSeconds
      )
        ? "1"
        : "0";
    } else {
      return "1";
    }
  } else {
    return "0";
  }
};

export const getRetriggeredText = (
  eligibleForRestart?: boolean,
  eligibleForRestartDate?: string,
  retriggeredStatusDurationSeconds?: string
) => {
  const retriggeredValue = getRetriggeredValue(
    eligibleForRestart,
    eligibleForRestartDate,
    retriggeredStatusDurationSeconds
  );
  return retriggeredValue === "1" ? t("general.yes") : t("general.no");
};

export const shouldNotMentionElevance = (
  groups: ExternalUserGroupOutput[] | undefined,
  externalUser: ExternalUser | undefined
) => {
  if (!groups || groups.length === 0 || !externalUser) {
    return false;
  }

  return !!groups.find(
    ({ number, fullOffshoreRestrictions, memberFacingOffshoreRestrictions }) =>
      number === externalUser.groupNumber &&
      (fullOffshoreRestrictions || memberFacingOffshoreRestrictions)
  );
};

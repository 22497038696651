import { useEffect } from "react";

import styles from "./CustomList.module.scss";

import CheckboxInput from "../checkboxInput/CheckboxInput";
import CustomCheckBoxSelect from "../select/CustomCheckBoxSelect";
import CustomSelect from "../select/CustomSelect";
import SkeletonUserListFilters from "../skeletons/listFilters/SkeletonUserListFilters";

import useListFilters from "~/hooks/useListFilters";
import { setListFilter } from "~/state/customList/customListStateHelpers";
import {
  FilterCheckboxData,
  CustomListFilterData
} from "~/typing/carePortalTypes";

const CustomListFilters = ({
  filterSelectData,
  filterCheckboxData,
  disabled,
  loading,
  hide,
  listId
}: {
  filterSelectData: CustomListFilterData[];
  filterCheckboxData: FilterCheckboxData[];
  disabled?: boolean;
  loading?: boolean;
  hide?: boolean;
  listId: string;
}) => {
  const { filters } = useListFilters(listId);

  useEffect(() => {
    filterSelectData.map((filter) => {
      if (
        filters[filter.key] === undefined &&
        filter.defaultValue !== undefined
      ) {
        setListFilter(listId, filter.key, filter.defaultValue);
      }
    });
  }, [filterSelectData]);

  if (hide) return null;

  if (loading) return <SkeletonUserListFilters />;

  return (
    <div className={styles.filters}>
      {filterSelectData?.map((filter, index) => {
        return filter.conditional === undefined || filter.conditional ? (
          <div key={`${filter.key}-${index}`} className={styles.control}>
            {filter.usesCheckBoxes ? (
              <CustomCheckBoxSelect
                {...filter}
                onChange={(value) => {
                  setListFilter(listId, filter.key, value);
                  filter.onChange?.(value);
                }}
                className="input--font-sm"
                disabled={!filter.alwaysEnabled && disabled}
                selected={filters[filter.key] as string[]}
                includeNoValueOption={filter.includeNoValueOption}
                key={filter.key}
              />
            ) : (
              <CustomSelect
                {...filter}
                value={filters[filter.key]}
                onChange={(e) => {
                  setListFilter(listId, filter.key, e.target.value);
                  filter?.onChange?.(e.target.value);
                }}
                className="input--font-sm"
                disabled={!filter.alwaysEnabled && disabled}
                key={filter.key}
              />
            )}
          </div>
        ) : null;
      })}
      {filterCheckboxData?.map(
        ({ text, key, conditional, defaultValue }, index) => {
          return conditional === undefined || conditional ? (
            <CheckboxInput
              label={text}
              checked={Boolean(filters[key]) ?? defaultValue}
              onChange={(checked) => setListFilter(listId, key, checked)}
              disabled={disabled}
              key={`checkbox-data-${key}-${index}`}
              className={styles.checkbox}
            />
          ) : null;
        }
      )}
    </div>
  );
};

export default CustomListFilters;

import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import styles from "./RegistrationList.module.scss";
import { RegistrationListContext } from "./registrationListContext";

import MagnifyingGlassIcon from "~/assets/magnifying-glass.svg";
import CustomList from "~/components/customList/CustomList";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import config from "~/config";
import { isCancerProgramByUserType } from "~/helpers/program/programHelpers";
import useExternalusers from "~/hooks/useExternalUsers";
import { t } from "~/i18n";
import { setListFilter } from "~/state/customList/customListStateHelpers";
import {
  fetchExternalUsers,
  setExternalUsers,
  setExternalUsersView,
  setSearchString
} from "~/state/externalUsers/externalUsersSlice";
import { AppDispatch } from "~/state/store";
import { ProgramURLParams } from "~/typing/carePortalTypes";
import { ExternalUser } from "~/typing/sidekickTypes";

const RegistrationListWithSearch = () => {
  const {
    filterCheckboxData,
    filterData,
    externalUsers,
    columns,
    searchData,
    externalUserTypeId,
    customListUniqueId,
    externalUserTypes
  } = useContext(RegistrationListContext);

  const { program_id = "", locale = "" } = useParams<ProgramURLParams>();

  const {
    loading: usersLoading,
    searchString,
    showUnseenSms
  } = useExternalusers(program_id);

  const dispatch = useDispatch<AppDispatch>();

  const clearAllFilters = () => {
    dispatch(
      setExternalUsers({
        users: [],
        programId: program_id,
        userTypeId: externalUserTypeId ?? ""
      })
    );
    // Filters
    filterData.forEach((data) =>
      setListFilter(customListUniqueId, data.key, data.defaultValue)
    );
    filterCheckboxData.forEach((data) =>
      setListFilter(customListUniqueId, data.key, data.defaultValue)
    );

    // Search string
    dispatch(
      setSearchString({
        searchString: "",
        programId: program_id,
        userTypeId: externalUserTypeId ?? ""
      })
    );
  };

  const renderEmptyState = () => {
    const hasCancerTypes =
      isCancerProgramByUserType(
        externalUserTypes.find((eut) => eut.id === externalUserTypeId)
      ) ?? false;

    return (
      <div className={styles.customEmptyState}>
        <figure>
          <img src={MagnifyingGlassIcon} />
        </figure>
        <p>
          {searchString?.length > 2
            ? "We couldn't find any users matching your search"
            : "Start typing in the search bar to see results"}
        </p>
        {searchString?.length > 2 && (
          <p>
            {
              "Try modifying your search or selecting a different 'User Type' to see additional results"
            }
          </p>
        )}
        <ul>
          <li>
            {`Type exact match to find search results that match the Case ID, HRS
            ID, phone number, HCID or ${
              config.isAnthem ? "SK Registration ID" : "Registration ID"
            }`}
          </li>
          <li>
            {`Type at least three letters to see results that match one of the following: MBR name, MBR email${
              hasCancerTypes ? ", Cancer type" : ""
            }`}
          </li>
        </ul>
        {searchString?.length > 2 && (
          <span className={styles.clearFilters}>
            <button onClick={clearAllFilters}>Clear all filters</button>
          </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (showUnseenSms) {
      dispatch(
        fetchExternalUsers({
          programId: program_id,
          locale,
          userTypeId: externalUserTypeId,
          unseenSmsOnly: true
        })
      );
      return;
    }

    if (!showUnseenSms && searchString && searchString.length > 2) {
      dispatch(
        fetchExternalUsers({
          programId: program_id,
          locale,
          userTypeId: externalUserTypeId ?? "",
          searchString
        })
      );
    } else {
      dispatch(
        setExternalUsers({
          users: [],
          programId: program_id,
          userTypeId: externalUserTypeId ?? ""
        })
      );
    }
  }, [program_id, externalUserTypeId, showUnseenSms, searchString]);

  const updateSearchString = (value: string) => {
    dispatch(
      setSearchString({
        searchString: value,
        programId: program_id,
        userTypeId: externalUserTypeId ?? ""
      })
    );
  };

  const toggleView = (value: boolean) => {
    if (usersLoading) return;

    dispatch(
      setExternalUsersView({
        view: value ? "unseenSms" : "all",
        programId: program_id
      })
    );
  };

  return (
    <SentryErrorBoundary transactionName="ProgramRegistrationsPage">
      <div className={styles.registrationTable}>
        <ToggleSwitch
          className={styles.toggle}
          currentValue={showUnseenSms}
          setValue={toggleView}
          switchData={[
            {
              value: true,
              label: t("programRegistrationList.unreadSms")
            },
            {
              value: false,
              label: t("programRegistrationList.allUsers")
            }
          ]}
        />

        <CustomList<ExternalUser>
          entities={externalUsers}
          isLoading={usersLoading}
          searchData={searchData}
          filterSelectData={filterData}
          filterCheckboxData={filterCheckboxData}
          columns={columns}
          noEntitiesText={
            !showUnseenSms
              ? t("programUserList.noUsers")
              : t("programUserList.noUsersSMS")
          }
          defaultSortingData={{ sortBy: ["submittedByDate"], reverse: true }}
          onSearchChange={!showUnseenSms ? updateSearchString : undefined}
          renderEmptyState={!showUnseenSms ? renderEmptyState : undefined}
          initialSearchString={!showUnseenSms ? searchString : undefined}
          hideFilters={
            !showUnseenSms ? !searchString || searchString?.length < 3 : true
          }
          customListUniqueId={customListUniqueId}
          pagination={{ entitiesPerPage: 20 }}
        />
      </div>
    </SentryErrorBoundary>
  );
};

export default RegistrationListWithSearch;

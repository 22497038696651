import { DateTime } from "luxon";
import { useRef, useState } from "react";

import styles from "./DateInput.module.scss";
import inputStyles from "./Input.module.scss";

import config from "~/config";

type DateInputProps = {
  initialDate?: DateTime;
  label: string;
  required?: boolean;
  onChange: (value: DateTime) => void;
  error?: string;
};

const DATE_FORMAT = config.isAnthem ? "MM/dd/yyyy" : "dd/MM/yyyy";

const DateInput = ({
  initialDate,
  required,
  label,
  onChange,
  error
}: DateInputProps) => {
  const sanitizeInput = (input: string, maxLength: number) => {
    if (input.length > maxLength) return input.substring(0, maxLength);
    return input.replace(/[^\d.]/gi, "");
  };

  const firstPartRef = useRef<HTMLInputElement>(null);
  const secondPartRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const [firstPart, setFirstPart] = useState(
    !config.isAnthem
      ? initialDate?.day?.toString() ?? ""
      : initialDate?.month?.toString() ?? ""
  );
  const [secondPart, setSecondPart] = useState(
    !config.isAnthem
      ? initialDate?.month?.toString() ?? ""
      : initialDate?.day?.toString() ?? ""
  );
  const [year, setYear] = useState(initialDate?.year?.toString() ?? "");

  const toTwoDigit = (input: string) =>
    `${input.length < 2 ? "0" : ""}${input}`;

  const handleFirstPartChange = (input: string) => {
    const sanitizedInput = sanitizeInput(input, 2);
    setFirstPart(sanitizedInput);

    const newDate = DateTime.fromFormat(
      `${toTwoDigit(sanitizedInput)}/${toTwoDigit(secondPart)}/${year}`,
      DATE_FORMAT
    );

    onChange(newDate);

    if (sanitizedInput.length === 2) {
      secondPartRef?.current?.focus();
    }
  };

  const handleSecondPartChange = (input: string) => {
    const sanitizedInput = sanitizeInput(input, 2);
    setSecondPart(sanitizedInput);

    const newDate = DateTime.fromFormat(
      `${toTwoDigit(firstPart)}/${toTwoDigit(sanitizedInput)}/${year}`,
      DATE_FORMAT
    );
    onChange(newDate);
    if (sanitizedInput.length === 2) {
      yearRef?.current?.focus();
    }
  };

  const handleYearChange = (year: string) => {
    const sanitizedInput = sanitizeInput(year, 4);
    setYear(sanitizedInput);
    const newDate = DateTime.fromFormat(
      `${toTwoDigit(firstPart)}/${toTwoDigit(secondPart)}/${sanitizedInput}`,
      DATE_FORMAT
    );
    onChange(newDate);
  };

  return (
    <div className={inputStyles.wrapper}>
      {label && (
        <label className={inputStyles.label}>
          {label}
          {required && <span className={inputStyles.star}>*</span>}
        </label>
      )}
      <div
        data-testid="date-input-wrapper"
        className={`${styles.wrapper} ${error ? inputStyles.errorBorder : ""}`}
      >
        <input
          data-testid="date-input"
          ref={firstPartRef}
          value={firstPart}
          placeholder={config.isAnthem ? "MM" : "DD"}
          className={styles.input}
          onChange={(e) => handleFirstPartChange(e.target.value)}
        />
        <span>/</span>
        <input
          ref={secondPartRef}
          value={secondPart}
          placeholder={config.isAnthem ? "DD" : "MM"}
          className={styles.input}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && secondPart.length === 0) {
              firstPartRef?.current?.focus();
            }
          }}
          onChange={(e) => handleSecondPartChange(e.target.value)}
        />

        <span>/</span>
        <input
          ref={yearRef}
          value={year}
          placeholder="YYYY"
          className={`${styles.input} ${styles.double}`}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && year.length === 0) {
              secondPartRef?.current?.focus();
            }
          }}
          onChange={(e) => handleYearChange(e.target.value)}
        />
      </div>
      {error && (
        <p data-testid="date-input-error" className={inputStyles.error}>
          {error}
        </p>
      )}
    </div>
  );
};

export default DateInput;

import { DateTime } from "luxon";
import { ReactNode } from "react";

import { Coach } from "./sidekickTypes";

import { OptionDependencies } from "~/components/select/CustomSelect.types";

export type ProgramURLParams = {
  program_id: string;
  locale: string;
};

export type MentalURLParams = UserURLParams & {
  mental_category: string;
};

export type PROURLParams = UserURLParams & {
  survey_id: string;
};

export type PhysicalPageURLParams = UserURLParams & {
  physical_category: string;
};

export type UserURLParams = ProgramURLParams & {
  user_id: string;
};

export type SortableData = {
  reverse?: boolean;
  sortBy: (string | number)[];
  customSortFunction?: (a: any, b: any) => number;
};

export type TableHeading = {
  sortable?: SortableData;
  tooltip?: {
    text: string;
    useIcon?: boolean;
  };
  text: string;
  className?: string;
  hideByDefault?: boolean;
};

export type ActionData = {
  key?: string;
  text: string;
  onClick: () => void;
  className?: string;
  iconSrc?: string;
  conditional?: boolean;
  dataTestId?: string;
};

export type ToggleData = {
  switchData: {
    label: string;
    value: any;
  }[];
  setValue: (value: any) => void;
  currentValue: any;
  className?: string;
  disabled?: boolean;
  conditional?: boolean;
};

export type SearchData = {
  data: { exactMatch?: boolean; key: string[] }[];
  placeholder: string;
  className?: string;
};

export type SelectOption<T = string> = {
  value: T;
  text: string;
  colour?: string;
  checkBoxData?: { checked: boolean };
  hide?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  renderOption?: () => ReactNode;
};

export type FilterCheckboxData<T = any> = {
  key: string;
  text: string;
  conditional: boolean;
  defaultValue?: boolean;
  filter: ({
    entity,
    filterValue
  }: {
    entity: T;
    filterValue: boolean;
  }) => boolean;
};

type BaseFilterData<K = any> = {
  key: string;
  options: SelectOption<K>[];
  conditional?: boolean;
  label: string;
  renderOption?: (option: SelectOption) => ReactNode;
  usesCheckBoxes?: boolean;
  placeholder?: string;
  includeNoValueOption?: boolean;
  dataTestId?: string;
  allowSearch?: boolean;
  dependencies?: OptionDependencies;
};

export type CustomListFilterData<T = any, K = any> = BaseFilterData<K> & {
  onChange?: (value: K) => void;
  defaultValue?: K;
  filter: ({ entity, filterValue }: { entity: T; filterValue: K }) => boolean;
  alwaysEnabled?: boolean;
};

export type CustomListCheckboxFilterData<
  T = any,
  K = any
> = BaseFilterData<K> & {
  onChange?: (value: K[]) => void;
  defaultValue?: K[];
  filter: ({ entity, filterValue }: { entity: T; filterValue: K[] }) => boolean;
};

export type Location = {
  name: string;
  countryCode: string;
  defaultPhoneCountryCode: string;
  state: string;
  id: string;
  createdDate: string;
  deleted: boolean;
};

export type PinnedNoteInfo = {
  journalItemId: string;
  id: string;
  createdDate: string;
  programCatalogItemId: string;
  userId: string;
};

export type AvailableTime = {
  startTime: string;
  endTime: string;
  id?: number;
};

export type CommunicationPreferencesData = {
  agreeableToPhone?: boolean;
  agreeableToSms?: boolean;
  agreeableToEmail?: boolean;
  comment?: string;
  availableTimes?: AvailableTime[];
};

export type SMSSettings = {
  useSMS: boolean;
  userHasOptedOut: boolean;
};

export enum SettingNames {
  HasDiabetes = "HRM_HasDiabetes",
  HasHypertension = "HRM_HasHTN",
  DiebetesType = "HRM_TypeOfDiabetes",
  BabyBorn = "HRM_BabyBorn",
  PregnancyWeekInitialSetting = "HRM_WhatToExpectSlotNumber",
  PregnancyDueDate = "PregnancyDueDate"
}

export type ProgramState = {
  programCatalogItemId?: string;
  locale?: string;
  name?: string;
  programCatalogItemProjectType?: string;
};

export type MedicationLogSanitised = {
  medicationName: string;
  medicationType?: string;
  icon?: number;
  iconColor?: string;
  scheduledDate: string;
  completedDate?: string;
  status: string;
  id: string;
  reminders?: Reminder[];
  time: string;
  upcoming: boolean;
};

export type Reminder = {
  periodically: {
    time: string[];
    startDate: string;
    every: number;
    period: number;
  };
  weekdaySchedule: {
    time: string[];
    startDate: string;
    weekDays: number[];
  };
  userReminderScheduleId: string;
};

export type MedicationOption = {
  value: string;
  title: string;
};

export enum MedicationReminderType {
  MedicationCard,
  MedicationLog
}

export type SurveyResultsModalDetails = {
  displayName: string;
  columnTitle: string;
  userId: string;
  surveyId: string | undefined;
};

export type PhysicalActivity = {
  thisWeek: number;
  lastWeek: number;
  weekBefore: number;
  date: DateTime;
};

export type HeartRateLineData = {
  date: string;
  heartrate: number;
};

export type BloodSugarLineData = {
  value: number;
  rating: number;
  date: string;
};

export type BloodPressureLineData = {
  systolic: number;
  diastolic: number;
  date: string;
};

export type OptionItem = {
  func: () => void;
  title?: string;
  renderOption?: () => ReactNode;
  hidden?: boolean;
  className?: string;
};

export type NavData = {
  url?: string;
  title?: string;
  iconSrc?: string;
  notificationCount?: number;
  preventRouting?: boolean;
};

export type UTMParameters = {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
};

export type AvatarUser = {
  imageHref?: string;
  fullName?: string;
  role?: string;
};

export type CoachOptionUserList = {
  isMe: boolean;
  userId: string;
  displayName: string;
};

export interface CoachWithSpecialty extends Coach {
  specialty?: { localizedName: string };
}

export type UserMetricData = {
  title: MetricType;
  value?: string | number;
  metric?: string;
  change?: {
    value: number;
    negative?: boolean;
  };
  info?: string;
};

export type PreviewFormData = {
  heading?: string;
  values: {
    value?: string;
    renderOption: () => ReactNode;
  }[];
  withBorder?: boolean;
  hidden?: boolean;
};

export type PaginationData = {
  entitiesPerPage: number;
};

export enum MetricType {
  ActiveDays = "Active days",
  TaskCompliance = "Task Compliance",
  PhysicalActivityMinutes = "Physical Activity",
  MissionsCompleted = "Missions Completed",
  StepCount = "Step Count",
  SleepQuality = "Sleep quality",
  EnergyLevels = "Energy levels",
  StressLevels = "Stress levels",
  Age = "Age",
  Weight = "Weight",
  Height = "Height",
  BMI = "BMI",
  Gender = "Gender"
}

export type BehavioralPROAnswerData = {
  title: string;
  description: string;
  values: string[];
  shortValues: string[];
};

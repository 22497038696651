import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useState } from "react";

import styles from "./CarePriorities.module.scss";
import CarePriority, { CarePriorityProps } from "./CarePriority";

import Button from "~/components/button/Button";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import Modal from "~/components/modal/Modal";
import ButtonR from "~/components/robinDesignSystem/button/ButtonR";
import { filterOutUndefined } from "~/helpers/array/arrayHelpers";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import { usePrefetchCoachSuggestedMessages } from "~/hooks/useApi/useCoachSuggestedMessages";
import {
  CareManagerActions,
  useNextRecommendedUserActions
} from "~/hooks/useApi/useNextRecommendedUserActions";
import useUser from "~/hooks/useUser";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  CarePriorities as Priorities,
  CarePriorityType,
  CarePriority as Priority,
  UserContextInfo
} from "~/typing/graphql/types";
import { MessageSuggestionType } from "~/typing/sidekickTypes";

type CarePrioritiesProps = {
  userId: string;
  userContextInfo?: UserContextInfo;
  carePriorities?: Priorities;
  onAllCarePrioritiesCompleted?: () => void;
};

const cx = classNames.bind(styles);

const CarePriorities = ({
  userId,
  userContextInfo,
  carePriorities,
  onAllCarePrioritiesCompleted
}: CarePrioritiesProps) => {
  const { user } = useUser();

  const queryClient = useQueryClient();

  const [
    showSurveyResultWarningModal,
    setShowSurveyResultWarningModal
  ] = useState(false);

  const [expandedCarePriority, setExpandedCarePriority] = useState<
    CarePriorityType | undefined
  >();
  const [markedAsDoneLoading, setMarkedAsDoneLoading] = useState(false);
  const [checkedCarePriorities, setCheckedCarePriorities] = useState<
    CarePriorityType[]
  >([]);
  const [completedCarePriorities, setCompletedCarePriorities] = useState<
    CarePriorityType[]
  >([]);
  const {
    registerActionForRecommendedUser,
    // isPending: registerActionPending, //TODO: Figure out best way to display loading state for this action
    isError: registerActionError
  } = useNextRecommendedUserActions({
    userId: userId
  });

  const {
    trackCarePriorityMarkedDone,
    trackCarePriorityTabChanged
  } = useAmplitudeTracking();

  usePrefetchCoachSuggestedMessages({
    programId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? "",
    userId: userId,
    messageType: MessageSuggestionType.FirstWeek,
    condition: carePriorities?.priorities.some(
      (p) => p?.type === CarePriorityType.FirstWeek
    )
  });

  usePrefetchCoachSuggestedMessages({
    programId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? "",
    userId: userId,
    messageType: MessageSuggestionType.ChurnRisk,
    condition: carePriorities?.priorities.some(
      (p) => p?.type === CarePriorityType.HighChurnRisk
    )
  });

  usePrefetchCoachSuggestedMessages({
    programId: userContextInfo?.programCatalogItemId ?? "",
    locale: userContextInfo?.locale ?? "",
    userId: userId,
    messageType: MessageSuggestionType.WeeklyFeedback,
    condition: carePriorities?.priorities.some(
      (p) => p?.type === CarePriorityType.WeeklyFeedback
    )
  });

  const handleCheckCarePriority = (type: CarePriorityType) => {
    if (completedCarePriorities.includes(type)) return;
    if (checkedCarePriorities.includes(type)) {
      setCheckedCarePriorities(
        checkedCarePriorities.filter((checkedType) => checkedType !== type)
      );
    } else {
      setCheckedCarePriorities([...checkedCarePriorities, type]);

      if (expandedCarePriority === type) {
        setExpandedCarePriority(undefined);
      }
    }
  };

  const markPriorityAsDone = async (carePriorityType: CarePriorityType) => {
    let canApprove = true;

    //We need to check if all the PROs are approved before marking the SurveyResultCreated care priority as done
    if (carePriorityType === CarePriorityType.SurveyResultCreated) {
      const surveyResultMetadata = carePriorities?.priorities.find(
        (p) => p?.type === CarePriorityType.SurveyResultCreated
      )?.metadata;

      const allApproved = surveyResultMetadata?.surveyResultMetadataList?.every(
        (metadata) => metadata?.approved
      );

      if (!allApproved) {
        setShowSurveyResultWarningModal(true);
        canApprove = false;
      }
    }

    if (!canApprove) return false;

    await registerActionForRecommendedUser({
      careManagerAction: CareManagerActions.CMA_MARK_ROR_AS_DONE,
      timeOfRanking: carePriorities?.timeOfRanking,
      userId: userId,
      carePriorityType
    });

    //Refresh the care priorities after marking one as done in the cache
    queryClient.invalidateQueries({
      queryKey: QueryKeyFactory.users.careManagerTasks(user?.id ?? "")
    });

    trackCarePriorityMarkedDone({
      carePriority: carePriorityType,
      userId: userId
    });

    return true;
  };

  const handleMarkAsDone = async () => {
    setMarkedAsDoneLoading(true);
    const failedPriorities: CarePriorityType[] = [];

    for (const type of checkedCarePriorities) {
      const success = await markPriorityAsDone(type);
      if (!success) {
        failedPriorities.push(type);
      }
    }
    //If we encounter an error, we should not mark the care priority as done
    if (registerActionError) return;

    setCompletedCarePriorities([
      ...completedCarePriorities,
      ...checkedCarePriorities.filter(
        (type) => !failedPriorities.includes(type)
      )
    ]);

    setCheckedCarePriorities([]);

    setMarkedAsDoneLoading(false);
  };

  useEffect(() => {
    setCompletedCarePriorities([]);
    setCheckedCarePriorities([]);
  }, [userId]);

  useEffect(() => {
    if (completedCarePriorities.length === carePriorities?.priorities.length) {
      onAllCarePrioritiesCompleted?.();
    }
  }, [completedCarePriorities]);

  const getCarePriorityProps = (priority: Priority): CarePriorityProps => {
    return {
      userContext: userContextInfo,
      userId,
      onChange: () => handleCheckCarePriority(priority.type),
      completed: completedCarePriorities.includes(priority.type),
      checked: checkedCarePriorities.includes(priority.type),
      onClick: () => handleClickCarePriority(priority.type),
      expanded: expandedCarePriority === priority.type,
      carePriorityData: {
        type: priority.type,
        metadata: priority.metadata
      }
    };
  };

  const handleClickCarePriority = (type: CarePriorityType) => {
    if (expandedCarePriority === type) {
      setExpandedCarePriority(undefined);
    } else {
      const previousExpandedCarePriority = expandedCarePriority;

      setExpandedCarePriority(type);

      trackCarePriorityTabChanged({
        from: previousExpandedCarePriority ?? "None",
        to: type
      });
    }
  };

  const uncompletedCarePrioritiesAmount = carePriorities?.priorities.length
    ? carePriorities.priorities.length - completedCarePriorities.length
    : 0;

  const allCarePrioritiesCompleted = uncompletedCarePrioritiesAmount === 0;

  if (!carePriorities?.priorities.length)
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p>
            <strong>{t("nextStep.careManagerTasks.noTasksDescription")}</strong>
          </p>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CheckboxInput
          checked={
            uncompletedCarePrioritiesAmount === checkedCarePriorities.length ||
            allCarePrioritiesCompleted ||
            false
          }
          indetermined={Boolean(checkedCarePriorities.length)}
          onChange={(checked) =>
            checked
              ? setCheckedCarePriorities(
                  carePriorities?.priorities
                    .filter(filterOutUndefined)
                    .filter((p) => !completedCarePriorities.includes(p.type))

                    .map((p) => p.type) ?? []
                )
              : setCheckedCarePriorities([])
          }
          disabled={allCarePrioritiesCompleted}
          label={"Care Priorities"}
          className={styles.checkbox}
        />
        <div className={styles.headerContent}>
          <div className={styles.headerInfo}>
            <p className={styles.amount}>{uncompletedCarePrioritiesAmount}</p>
          </div>

          <Button
            className={cx({
              [styles.button]: true,
              [styles.hidden]: !checkedCarePriorities.length
            })}
            onClick={handleMarkAsDone}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleMarkAsDone();
              }
            }}
            isLoading={markedAsDoneLoading}
            tabIndex={!checkedCarePriorities.length ? -1 : 0}
          >
            {t("nextStep.markAsDone")}
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {carePriorities?.priorities.map((priority, index) => {
          if (!priority) return null;
          return (
            <CarePriority key={index} {...getCarePriorityProps(priority)} />
          );
        })}
      </div>
      {showSurveyResultWarningModal && (
        <Modal
          title={t("nextStep.survey.errors.unapprovedModalTitle")}
          onClose={() => setShowSurveyResultWarningModal(false)}
          className={styles.unapprovedModal}
        >
          <>
            <p>{t("nextStep.survey.errors.unapprovedModalMessage1")}</p>
            <p>{t("nextStep.survey.errors.unapprovedModalMessage2")}</p>
            <p> {t("nextStep.survey.errors.unapprovedModalMessage3")}</p>
            <div className={styles.modalButtons}>
              <ButtonR
                type="primary"
                label={t("general.done")}
                onClick={() => setShowSurveyResultWarningModal(false)}
              />
            </div>
          </>
        </Modal>
      )}
    </div>
  );
};

export default CarePriorities;

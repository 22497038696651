import { INCORRECT_OPTION } from "../../registration/helper";

import config from "~/config";
import { DEFAULT_FILTER_VALUE, NOT_SUBMITTED } from "~/constants/filters";
import states from "~/constants/states";
import { getPregnancyWeekFromDueDate } from "~/helpers/date/dateHelpers";
import { capitalizeWord } from "~/helpers/string/stringHelpers";
import { hasFinishedProgram } from "~/helpers/user/userHelpers";
import { getProRatingFilterText } from "~/helpers/userLists/filterHelper";
import { t } from "~/i18n";
import { CoachOptionUserList, SelectOption } from "~/typing/carePortalTypes";
import {
  CoachUser,
  EnrichedAuthenticatedUser,
  ExternalUserGroupOutput,
  Program,
  ProgramSpecialty,
  Survey,
  SurveyOption
} from "~/typing/sidekickTypes";

export enum CoachAssignedValue {
  Assigned = "assigned",
  Unassigned = "unassigned"
}

export const getCoachesInProgram = (
  program: Program | undefined,
  loggedInUser: EnrichedAuthenticatedUser | undefined,
  programSpecialties: ProgramSpecialty[] | undefined
): CoachOptionUserList[] => {
  if (!program) return [];

  let coaches: CoachOptionUserList[] = [];

  if (programSpecialties) {
    coaches = program.coaches.map((coach) => ({
      userId: coach.userId ?? "",
      displayName: coach.displayName ?? "",
      isMe: coach.userId === loggedInUser?.id
    }));
  } else {
    coaches =
      program.userProgramGroups
        ?.filter((group) => group.coach !== undefined)
        .map((group) => ({
          userId: group.coach?.userId ?? "",
          displayName: group.coach?.displayName ?? "",
          isMe: group.coach?.userId === loggedInUser?.id
        })) ?? [];
  }

  return coaches;
};

export const coachFilterOptions = (
  coaches: CoachOptionUserList[],
  users: CoachUser[],
  hasProgramSpecialties: boolean
): SelectOption[] => {
  const me = coaches.find((coach) => coach.isMe);
  const out = [
    {
      value: DEFAULT_FILTER_VALUE,
      text: t("programUserList.coachFilter.all")
    },
    {
      value: CoachAssignedValue.Assigned,
      text: t("programUserList.coachFilter.assigned")
    },
    {
      value: CoachAssignedValue.Unassigned,
      text: t("programUserList.coachFilter.unassigned")
    }
  ];

  if (me) {
    out.push({
      value: me.userId,
      text: t("programUserList.coachFilter.me")
    });
  }

  const filterOutCoaches = (coach: CoachOptionUserList) => {
    const isAssigned = users.some((user) => {
      if (hasProgramSpecialties) {
        return user.coaches?.some(
          (userCoach) => userCoach.coachUserId === coach.userId
        );
      } else {
        return user.assignedCoachUserId === coach.userId;
      }
    });

    return !coach.isMe && isAssigned;
  };

  coaches
    .filter(filterOutCoaches)
    .reduce(
      (prev, curr) =>
        prev.some((p) => p.userId === curr.userId) ? prev : [curr, ...prev],
      [] as CoachOptionUserList[]
    )
    .sort((a, b) =>
      a?.displayName?.toLowerCase() > b?.displayName?.toLowerCase() ? 1 : -1
    )
    .forEach((coach) => {
      out.push({
        value: coach.userId,
        text: coach.displayName
      });
    });

  return out;
};

export const colourFilterOptions = (
  surveys: Survey[],
  proFilter: string
): SelectOption[] => {
  const options: SurveyOption[] = [];

  if (proFilter === DEFAULT_FILTER_VALUE) {
    surveys?.forEach((survey) => {
      if (!survey.options.quiz) {
        survey.ratings?.forEach((rating) => {
          let text = getProRatingFilterText(rating);

          text += ` (${survey.title})`;

          options.push({
            value: rating.id,
            text: text,
            colour: rating.colour
          });
        });
      }
    });
  } else {
    const survey = surveys?.find((survey) => survey.surveyName === proFilter);

    survey?.ratings?.forEach((rating) => {
      const text = getProRatingFilterText(rating);

      options.push({
        value: rating.id,
        text: text,
        colour: rating.colour
      });
    });

    options.push({
      value: NOT_SUBMITTED,
      text: t("programUserList.notSubmitted", "Not Submitted")
    });
  }

  options.unshift({
    value: DEFAULT_FILTER_VALUE,
    text: t("programUserList.allProResults", "All PRO Results")
  });

  return options;
};

export const proFilterOptions = (surveys: Survey[]): SelectOption[] => {
  const options = [
    {
      value: DEFAULT_FILTER_VALUE,
      text: t("programUserList.mostRecentPRO", "Most recent PRO")
    }
  ];

  surveys?.forEach((survey) => {
    if (!survey.options.quiz) {
      options.push({
        value: survey.surveyName,
        // TODO TVT: Remove when surveys support Care Portal Content Items
        text:
          survey.title != null &&
          survey.title.includes("Covid-19 Symptom Survey")
            ? survey.surveyName
            : survey.title
      });
    }
  });

  return options;
};

export const weekFilterOptions = (users: CoachUser[]): SelectOption[] => {
  const weeks: number[] = [];

  const weekOptions = [
    {
      value: DEFAULT_FILTER_VALUE,
      text: t("programUserList.allWeeks")
    }
  ];

  users.forEach((user) => {
    if (
      !hasFinishedProgram(user.programStatus, user.userProgramStatusReason) &&
      !weeks.includes(user.currentSlot) &&
      user.currentSlot !== undefined
    )
      weeks.push(user.currentSlot);
  });

  if (weeks.length == 0) {
    return weekOptions;
  }

  return weekOptions.concat(
    weeks
      .sort((a, b) => (a > b ? 1 : -1))
      .map((week) => ({
        value: week.toString(),
        text: `${t("time.week")} ${week}`
      }))
  );
};

export const MessageFilterOption = {
  UnreadToYou: "unreadToYou",
  UnreadToOther: "unreadToOther",
  Negative: "negative",
  Technical: "technical"
};

export const messagesFilterOptions = (): SelectOption[] => {
  return [
    {
      value: DEFAULT_FILTER_VALUE,
      text: t("programUserList.messagesFilter.all")
    },
    {
      value: MessageFilterOption.UnreadToYou,
      text: t("programUserList.messagesFilter.unreadToYou")
    },
    {
      value: MessageFilterOption.UnreadToOther,
      text: t("programUserList.messagesFilter.unreadToOther")
    },
    {
      value: MessageFilterOption.Negative,
      text: t("programUserList.messagesFilter.negative"),
      hide: config.isAnthem
    },
    {
      value: MessageFilterOption.Technical,
      text: t("programUserList.messagesFilter.technical"),
      hide: config.isAnthem
    }
  ];
};

export const memberStateFilterOptions = (
  users: CoachUser[]
): SelectOption[] => {
  const usStates = states.map((state) => ({
    value: state.code,
    text: state.name
  }));

  const filteredStates = usStates.filter((state) =>
    users.some((user) => user.memberState === state.value)
  );

  const usersHaveIncorrectState = users.some(
    (user) =>
      user.memberState &&
      !usStates.some((state) => state.value === user.memberState)
  );

  if (usersHaveIncorrectState) {
    filteredStates.unshift({ value: INCORRECT_OPTION, text: "Incorrect" });
  }

  return filteredStates;
};

export const planStateFilterOptions = (users: CoachUser[]): SelectOption[] => {
  const usStates: SelectOption[] = states.map((state) => ({
    value: state.code,
    text: state.name
  }));

  const filteredStates = usStates.filter((state) =>
    users.some((user) => user.planState === state.value)
  );

  const usersHaveIncorrectState = users.some(
    (user) =>
      user.planState &&
      !usStates.some((state) => state.value === user.planState)
  );

  if (usersHaveIncorrectState) {
    filteredStates.unshift({ value: INCORRECT_OPTION, text: "Incorrect" });
  }

  return filteredStates;
};

export const lineOfBusinessOptions = (users: CoachUser[]): SelectOption[] => {
  const options = Array.from(
    new Set(
      users
        .filter((user) => Boolean(user.lineOfBusiness))
        .map((user) => user.lineOfBusiness ?? "")
    )
  ).map((value) => ({ value, text: value }));

  options.unshift({
    value: DEFAULT_FILTER_VALUE,
    text: t("programUserList.allLOB", "All LOB")
  });

  return options;
};

export const pregnancyWeekOptions = (users: CoachUser[]): SelectOption[] => {
  const options = Array.from(
    new Set(
      users
        .filter((user) => user.pregnancyDueDate !== undefined)
        .map((user) => getPregnancyWeekFromDueDate(user.pregnancyDueDate) ?? 0)
    )
  )
    .sort((a, b) => (a > b ? 1 : -1))
    .map((value) => ({
      value: value?.toString() ?? "",
      text: `${t("time.week")} ${value}`
    }));

  options.unshift({
    value: DEFAULT_FILTER_VALUE,
    text: t("programUserList.allPregnancyWeeks", "All pregnancy weeks")
  });

  return options;
};

export const riskLevelOptions = (users: CoachUser[]): SelectOption[] => {
  const options = Array.from(
    new Set(
      users
        .filter((user) => user.riskLevel !== undefined)
        .map((user) => capitalizeWord(user.riskLevel) ?? "")
    )
  ).map((value) => ({
    value: value.toUpperCase(),
    text: value
  }));

  options.unshift({
    value: DEFAULT_FILTER_VALUE,
    text: t("programUserList.allRiskLevels", "All risk levels")
  });

  return options;
};

export const layerFilterOptions = (users: CoachUser[]): SelectOption[] => {
  const layerOptions: SelectOption[] = [];

  users.forEach((user) => {
    (user?.coachUserLayersForDisplay ?? []).forEach((layer) => {
      const option = {
        value: layer.id,
        text: layer.carePortalAbbreviation || layer.title || layer.name
      };

      if (
        !layerOptions.some(
          (existingOption) => existingOption.value === option.value
        )
      ) {
        layerOptions.push(option);
      }
    });
  });

  return layerOptions;
};

export const programTypeFilterOptions = [
  {
    value: DEFAULT_FILTER_VALUE,
    text: t("programs.allPrograms")
  },
  {
    value: "live",
    text: t("programs.livePrograms")
  },
  {
    value: "test",
    text: t("programs.testPrograms")
  }
];

export const groupFilterOptions = (
  groups: ExternalUserGroupOutput[]
): SelectOption[] => {
  const groupOptions: SelectOption[] = [];

  groups.map((group) => {
    groupOptions.push({
      text: group.number,
      value: group.number
    });
  });

  return groupOptions;
};

export const groupNameFilterOptions = (
  groups: ExternalUserGroupOutput[]
): SelectOption[] => {
  const groupOptions: SelectOption[] = [];

  groups.map((group) => {
    groupOptions.push({
      text: group.shortName || group.name,
      value: group.name
    });
  });

  return groupOptions;
};

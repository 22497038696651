import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ReactNode } from "react";

import {
  MarkdownChangePlugin,
  MarkdownLinkPlugin,
  ToolbarPlugin
} from "./RichTextEditor.components";
import styles from "./RichTextEditor.module.scss";

const EditorTheme = {
  paragraph: "paragraph",
  text: {
    bold: "textBold",
    italic: "textItalic",
    strikethrough: "textStrikethrough",
    underline: "textUnderline",
    underlineStrikethrough: "textUnderlineStrikethrough",
    code: "textCode"
  },
  list: {
    nested: {
      listitem: "nestedListItem"
    },
    ol: "listOl",
    ul: "listUl",
    listitem: "listItem"
  }
};

export const RichTextEditor = ({
  value,
  placeholder,
  namespace = "markdown-text-editor",
  onChange = () => {},
  footer
}: {
  value: string;
  placeholder: string;
  namespace?: string;
  onChange: (md: string) => void;
  footer?: ReactNode;
}) => {
  const handleOnChange = (markdown) => {
    onChange(markdown);
  };

  const editorConfig = {
    namespace,
    editorState: () => $convertFromMarkdownString(value, TRANSFORMERS),
    onError(error: Error) {
      console.error(error);
    },
    nodes: [
      LinkNode,
      AutoLinkNode,
      CodeNode,
      CodeHighlightNode,
      HeadingNode,
      QuoteNode,
      ListNode,
      ListItemNode
    ],
    theme: EditorTheme
  };
  return (
    <LexicalComposer key={namespace} initialConfig={editorConfig}>
      <div className={styles.wrapper}>
        <ToolbarPlugin />
        <div className={styles.inputWrapper}>
          <RichTextPlugin
            ErrorBoundary={LexicalErrorBoundary}
            contentEditable={
              <ContentEditable
                className={styles.editor}
                aria-placeholder={placeholder}
                placeholder={
                  <div className={styles.placeholder}>{placeholder}</div>
                }
              />
            }
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <MarkdownLinkPlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <MarkdownChangePlugin
            value={value}
            onMarkdownChange={handleOnChange}
          />
        </div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </LexicalComposer>
  );
};

import { useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./UserPage.module.scss";

import ExportPreview from "../export/ExportPreview";
import { TimeZoneOffsetState } from "../reducers/timeZoneReducer";

import ExportIcon from "~/assets/export.svg";
import UserIcon from "~/assets/user.svg";
import Breadcrumbs from "~/components/breadcrumbs/Breadcrumbs";
import config from "~/config";
import getLanguageFromLocale from "~/helpers/getLanguageFromLocale";
import { getProgramBreadcrumbName } from "~/helpers/program/programHelpers";
import { useExternalUser } from "~/hooks/useApi/useExternalUser";
import useProgramLocales from "~/hooks/useApi/useProgramLocales";
import { t } from "~/i18n";
import RegisterPatient from "~/pages/program/registration/registerPatient/RegisterPatient";
import { UserURLParams } from "~/typing/carePortalTypes";
import {
  Program,
  EnrichedAuthenticatedUser,
  ExternalUserType,
  UserDetails
} from "~/typing/sidekickTypes";

type UserPageTopNavProps = {
  program?: Program;
  userDetail?: UserDetails;
  authUser?: EnrichedAuthenticatedUser;
  timeZoneState: TimeZoneOffsetState;
  externalUserType?: ExternalUserType;
  leftDate: string;
  setShowSMSModal: (value: boolean) => void;
};

const UserPageTopNav = ({
  program,
  userDetail,
  authUser,
  externalUserType,
  timeZoneState,
  leftDate,
  setShowSMSModal
}: UserPageTopNavProps) => {
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showExportPreview, setShowExportPreview] = useState(false);

  const { externalUser, invalidate: invalidateExternalUser } = useExternalUser({
    externalUserTypeId: externalUserType?.id,
    externalUserId: userDetail?.externalUser?.id
  });

  const { program_id = "", locale = "" } = useParams<UserURLParams>();

  const { programLocales } = useProgramLocales({
    programCatalogItemId: program_id,
    disabled: !config.isAnthem
  });

  const breadcrumbLinkData = [
    {
      url: `/program/${program_id}/locale/${locale}/users`,
      text: getProgramBreadcrumbName(program, programLocales)
    },
    {
      text: userDetail?.user?.fullName ?? ""
    }
  ];

  const handlePatientModalClose = () => {
    setShowPatientModal(false);
  };

  const language = getLanguageFromLocale(program?.locale);

  return (
    <>
      <div className={styles.nav}>
        <Breadcrumbs linkData={breadcrumbLinkData} />
        <div className={styles.buttons}>
          <button
            className={`btn-secondary btn-sm ${styles.export}`}
            onClick={() => setShowExportPreview(true)}
          >
            <img src={ExportIcon} />
            <p>{t("general.export")}</p>
          </button>
          {externalUser && (
            <button
              className={`btn-secondary btn-sm ${styles.export}`}
              onClick={() => setShowPatientModal(true)}
            >
              <>
                <img src={UserIcon} />
                {t("registerPatient.openRegistration")}
              </>
            </button>
          )}
        </div>
      </div>
      {showExportPreview && userDetail && authUser && (
        <ExportPreview
          detail={userDetail}
          close={() => setShowExportPreview(false)}
          coach={userDetail.assignedCoach}
          program={program}
          language={language}
          timeZoneOffset={timeZoneState.timeZoneOffset ?? 0}
          leftDate={leftDate}
        />
      )}
      {showPatientModal && externalUser && (
        <RegisterPatient
          externaluser={externalUser}
          externalUserTypeId={externalUserType?.id}
          onClose={handlePatientModalClose}
          externalUserTypes={program?.externalUserTypes || []}
          location={program?.location}
          smsSettings={{
            userOptedOut: externalUser?.smsOptOutType === "STOP",
            openSMSModal: () => {
              setShowPatientModal(false);
              setShowSMSModal(true);
            }
          }}
          onExternalUserUpdate={invalidateExternalUser}
        />
      )}
    </>
  );
};

export default UserPageTopNav;

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InformationPageHeaderCareTeam from "./info/InformationPageHeaderCareTeam";
import InformationPageHeaderLayers from "./info/InformationPageHeaderLayers";
import InformationPageHeaderPrograms from "./info/InformationPageHeaderPrograms";
import styles from "./InformationPageHeader.module.scss";

import InformationPageDeviceOrder from "../devices/InformationPageDeviceOrder";
import { InformationPageContext } from "../informationPageContext";

import CustomSelect from "~/components/select/CustomSelect";
import { ExternalUserTypeBehaviour } from "~/constants/externalUserBehaviours";
import getExternalLinkOptions, {
  ExternalLinkOption
} from "~/helpers/getExternalLinkOptions";

type InformationPageHeaderProps = {
  className?: string;
};

const InformationPageHeader = ({ className }: InformationPageHeaderProps) => {
  const [filteredExternalLinks, setFilteredExternalLinks] = useState<
    ExternalLinkOption[]
  >([]);

  const { userDetail, externalUser, externalUserType, program } = useContext(
    InformationPageContext
  );

  const { t } = useTranslation();

  const isPrintPage = className?.includes("print");
  const isCancerProgram =
    externalUserType &&
    !!(externalUserType?.behaviour & ExternalUserTypeBehaviour.CancerProgram);

  useEffect(() => {
    const filteredExternalLinkOptions = getExternalLinkOptions(externalUser);
    setFilteredExternalLinks(filteredExternalLinkOptions);
  }, [externalUser]);

  if (!program) return null;

  return (
    <>
      <div className={className}>
        <InformationPageHeaderCareTeam />
        <InformationPageHeaderPrograms isPrintPage={isPrintPage} />
        <InformationPageHeaderLayers />

        <InformationPageDeviceOrder
          externalUser={externalUser}
          userDetails={userDetail}
        />

        {isCancerProgram && externalUser?.diseaseName && (
          <div className={styles.diseaseName}>
            <span>{`${t("registerPatient.cancerType")}: `}</span>
            {externalUser.diseaseName}
          </div>
        )}
        {!isPrintPage && filteredExternalLinks.length > 0 && (
          <div className={styles.externalLinks}>
            <CustomSelect
              placeholder={t("registerPatient.externalLinks")}
              options={filteredExternalLinks}
              renderOption={(option) => option.value}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InformationPageHeader;

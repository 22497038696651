import classNames from "classnames";
import React, { SVGProps, ReactElement } from "react";

import styles from "./ButtonR.module.scss";

const cx = classNames.bind(styles);

type ButtonRPropsBase = {
  onClick?: () => void;
  disabled?: boolean;
  type?: "primary" | "secondary" | "tertiary" | "quaternary" | "warning";
  size?: "xs" | "sm" | "md" | "lg";
  iconPosition?: "leading" | "trailing";
  className?: string;
};

type ButtonRPropsWithLabel = ButtonRPropsBase & {
  label: string;
  icon?: ReactElement<SVGProps<SVGSVGElement>>;
};

type ButtonRPropsWithIcon = ButtonRPropsBase & {
  label?: string;
  icon: ReactElement<SVGProps<SVGSVGElement>>;
};

type ButtonRProps = ButtonRPropsWithLabel | ButtonRPropsWithIcon;

const ButtonR = ({
  label,
  onClick,
  disabled,
  type = "primary",
  size = "md",
  icon,
  iconPosition = "leading",
  className
}: ButtonRProps) => {
  const buttonClasses = cx(styles.button, styles[type], styles[size], {
    [styles.reverse]: iconPosition === "trailing",
    [styles.square]: !label,
    [styles.leading]: icon && iconPosition === "leading",
    [styles.trailing]: icon && iconPosition === "trailing",
    [className || ""]: className
  });

  return (
    <button
      type="button"
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <span className={styles.iconContainer}>{icon}</span>}
      {label}
    </button>
  );
};

export default ButtonR;

import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import MessagePreview from "./MessagePreview";
import { MessageWindowContext } from "./messageWindowContext";
import styles from "./MessageWindowFooter.module.scss";

import ArrowRight from "~/assets/arrow-right-thick.svg";
import CloseIcon from "~/assets/close.svg";
import MessagesIcon from "~/assets/messages.svg";
import Close from "~/assets/svgComponents/Close";
import AutoresizeTextarea from "~/components/autoresizeTextarea/AutoresizeTextarea";
import Input from "~/components/input/Input";
import Options from "~/components/options/Options";
import { Segmentation } from "~/components/segmentation/Segmentation";
import Switch from "~/components/switch/Switch";
import config from "~/config";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { useAutoTranslate } from "~/contexts/translationContext/useAutoTranslate";
import { generateRandomString } from "~/helpers/string/stringHelpers";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import useUser from "~/hooks/useUser";
import { t } from "~/i18n";
import colors from "~/styles/colors";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { SMSSettings, UserURLParams } from "~/typing/carePortalTypes";
import { MessageType } from "~/typing/sidekickTypes";

type MessageWindowFooterProps = {
  smsSettings: SMSSettings;
  replyingToMessage?: MessageType;
};

const MessageWindowFooter = ({
  smsSettings,
  replyingToMessage
}: MessageWindowFooterProps) => {
  const {
    usersForAvatars,
    minimized,
    setMessageBeingRepliedTo,
    messageInInput,
    setMessageInInput,
    setShowRecommendedReplies,
    onSubmitMessage
  } = useContext(MessageWindowContext);

  const [uniqueId] = useState(generateRandomString(10));

  const { user } = useUser();

  const { locale, user_id } = useParams<UserURLParams>();

  const [newMessage, setNewMessage] = useState("");
  const [address, setAddress] = useState("");
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showTranslationSettings, setShowTranslationSettings] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const { trackAddressInsertedInChat } = useAmplitudeTracking();

  const { isEnabled: assistanctCoachLinkEnabled } = useFeatureToggle({
    featureName: SegmentationKey.AssistantCoachLink
  });

  const { isEnabled: messageTranslationSegment } = useFeatureToggle({
    featureName: SegmentationKey.MessageTranslationSegment,
    patientId: user_id
  });

  const handleSendMessage = (e) => {
    e.preventDefault();
    onSubmitMessage({ message: newMessage, replyingToMessage });
    if (textAreaRef?.current) {
      textAreaRef.current.style.height = "auto";
    }
    setNewMessage("");
    setMessageInInput("");
  };

  const toggleShowAddress = () => {
    if (showAddAddress) {
      textAreaRef.current?.focus();
    } else {
      setShowPreview(false);
      setShowTranslationSettings(false);
    }

    setShowAddAddress(!showAddAddress);
  };

  const toggleShowPreview = () => {
    if (!showPreview) {
      setShowAddAddress(false);
      setShowTranslationSettings(false);
    }
    setShowPreview(!showPreview);
    textAreaRef.current?.focus();
  };

  const toggleShowTranslationSettings = () => {
    if (!showTranslationSettings) {
      setShowAddAddress(false);
      setShowPreview(false);
    }
    setShowTranslationSettings(!showTranslationSettings);
    textAreaRef.current?.focus();
  };

  const handleInsertAddress = () => {
    const addressString = `[${address}](maps:${encodeURI(address)})`;
    setNewMessage(newMessage + addressString);
    setShowAddAddress(false);
    setAddress("");
    textAreaRef.current?.focus();
    trackAddressInsertedInChat();
  };

  const handleRouteToAssistantCoach = () => {
    const address = `${config.assistanctCoachUrl}/admin/?user_id=${user_id}&coach_id=${user?.id}&session_id=${uniqueId}&locale=${locale}`;

    if (window) {
      window.open(address, "_blank")?.focus();
    }
  };

  const { autoTranslate, setAutoTranslate } = useAutoTranslate();

  useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  useEffect(() => {
    setNewMessage(messageInInput ?? "");
  }, [messageInInput]);

  return (
    <div className={`${styles.footer} ${minimized ? styles.minimized : ""}`}>
      <form onSubmit={handleSendMessage} ref={formRef}>
        <div className={styles.formContent}>
          {!smsSettings?.useSMS && (
            <div className={styles.footerActions}>
              <Options
                className={styles.footerOptions}
                options={[
                  {
                    title: t("messages.messagesWindow.chatAssistant"),
                    func: handleRouteToAssistantCoach,
                    hidden: !assistanctCoachLinkEnabled
                  },

                  {
                    title: t("messages.messagesWindow.addLocation"),
                    func: toggleShowAddress
                  },
                  {
                    title: t("messages.messagesWindow.previewMessage"),
                    func: toggleShowPreview
                  },
                  ...(messageTranslationSegment
                    ? [
                        {
                          title: "Auto translations",
                          func: toggleShowTranslationSettings
                        }
                      ]
                    : [])
                ]}
              />
              {!replyingToMessage && config.showMessageSuggestions && (
                <Segmentation featureName={SegmentationKey.RecommendedMessages}>
                  <button
                    type="button"
                    className={styles.replyButton}
                    onClick={() => setShowRecommendedReplies?.(true)}
                    data-testid="message-window-rec-replies-button"
                  >
                    <img src={MessagesIcon} />
                  </button>
                </Segmentation>
              )}
            </div>
          )}
          <div
            className={`${styles.textAreaWrapper} ${
              minimized ? styles.minimized : ""
            }`}
          >
            {replyingToMessage && "text" in replyingToMessage && (
              <>
                <div
                  className={styles.replyMessage}
                  title={replyingToMessage?.text}
                >
                  <p>{`${t("general.to")} ${
                    usersForAvatars[replyingToMessage?.recipientUserId]
                      ?.fullName
                  }`}</p>
                  <p>{replyingToMessage?.text}</p>
                </div>
                <button
                  className={styles.closeReplyButton}
                  onClick={() => setMessageBeingRepliedTo(undefined)}
                  data-testid="message-window-footer-close-reply"
                >
                  <Close color={colors.white} />
                </button>
              </>
            )}

            <AutoresizeTextarea
              value={newMessage}
              onChange={(value) => setNewMessage(value)}
              placeholder={t("messages.inputPlaceholder", "Enter here...")}
              ref={textAreaRef}
              className={styles.textarea}
              dataTestId="message-window-footer-textarea"
            />
          </div>
          <button
            type="submit"
            className={`btn-sm btn-circle ${styles.send} ${
              smsSettings?.useSMS ? "btn-primary-green" : "btn-primary"
            }`}
            disabled={newMessage.trim() === ""}
            data-testid="message-window-footer-send-button"
          >
            <img src={ArrowRight} alt="Send Message" />
          </button>
        </div>
      </form>
      {showAddAddress && (
        <div className={styles.popup}>
          <div className={styles.popupHeader}>
            <div className={styles.title}>Insert address</div>
            <button className={styles.close} onClick={toggleShowAddress}>
              <img src={CloseIcon} />
            </button>
          </div>
          <Input
            value={address}
            onChange={setAddress}
            placeholder={"Enter an address..."}
            autoFocus
          />
          <button
            type="button"
            className="btn-primary btn-sm"
            onClick={handleInsertAddress}
          >
            {t("general.insert")}
          </button>
        </div>
      )}
      {showPreview && (
        <MessagePreview
          message={newMessage}
          toggleMessagePreview={toggleShowPreview}
        />
      )}
      {showTranslationSettings && (
        <div className={styles.popup}>
          <div className={styles.popupHeader}>
            <div className={styles.title}>
              {t("translation.settings.header")}
            </div>
            <button
              className={styles.close}
              onClick={toggleShowTranslationSettings}
            >
              <img src={CloseIcon} />
            </button>
          </div>
          <div className={styles.toggleContainer}>
            <p>{t("translation.settings.autoTranslation")}</p>
            <Switch onChange={setAutoTranslate} checked={autoTranslate} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageWindowFooter;

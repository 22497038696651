import { useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input/input";
import { useParams } from "react-router-dom";

import DeviceCard from "./DeviceCard";
import styles from "./DeviceOrderModal.module.scss";

import {
  DeviceInformation,
  orderDevicesForUser
} from "~/api/requests/deviceRequests";
import Button from "~/components/button/Button";
import CustomPhoneInput from "~/components/customPhoneInput/CustomPhoneInput";
import Input from "~/components/input/Input";
import Modal from "~/components/modal/Modal";
import CustomSelect from "~/components/select/CustomSelect";
import states from "~/constants/states";
import { isValidEmail } from "~/helpers/string/stringHelpers";
import { t } from "~/i18n";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";
import { ExternalUser } from "~/typing/sidekickTypes";

type OrderDeviceProps = {
  onClose: () => void;
  externalUser?: ExternalUser;
  allowedDevices: DeviceInformation[];
  onOrder: () => void;
};

enum InputErrors {
  PhoneNumber = "PhoneNumber",
  Devices = "Devices"
}

const DeviceOrderModal = ({
  onClose,
  onOrder,
  externalUser,
  allowedDevices
}: OrderDeviceProps) => {
  const { program_id = "", user_id = "" } = useParams<UserURLParams>();

  const nameComponents = externalUser?.name.split(" ");

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState(nameComponents?.[0] || "");
  const [lastName, setLastName] = useState(
    nameComponents?.[nameComponents.length - 1] || ""
  );
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    externalUser?.phoneNumber || ""
  );

  const { trackDeviceOrder } = useAmplitudeTracking();

  const fieldData = [
    {
      label: "First name",
      key: "firstName",
      value: firstName,
      setValue: setFirstName,
      placeholder: "First name",
      autoFocus: true,
      required: true,
      isValid: (value) => value?.length > 0,
      errorMessage: "Required"
    },
    {
      label: "Last name",
      key: "lastName",
      value: lastName,
      setValue: setLastName,
      placeholder: "Last name",
      required: true,
      isValid: (value) => value?.length > 0,
      errorMessage: "Required"
    },
    {
      label: "State",
      key: "state",
      value: state,
      setValue: setState,
      placeholder: "Select state",
      required: true,
      select: {
        renderOption: (option) => option.name,
        options: [...states],
        keyValue: "code"
      },
      className: styles.select,
      isValid: (value) => value?.length > 0,
      errorMessage: "Required"
    },
    {
      label: "Address (not a PO box)",
      key: "address",
      value: address,
      setValue: setAddress,
      placeholder: "Address",
      required: true,
      isValid: (value) => value?.length > 0,
      errorMessage: "Required"
    },
    {
      label: "Address 2",
      key: "address2",
      value: address2,
      setValue: setAddress2,
      placeholder: "Address",
      isValid: (value) => value?.length > 0
    },
    {
      label: "City",
      key: "city",
      value: city,
      setValue: setCity,
      placeholder: "City",
      required: true,
      isValid: (value: string) => value?.length > 0,
      errorMessage: "Required"
    },

    {
      label: "Postal code",
      key: "postalCode",
      value: postalCode,
      setValue: setPostalCode,
      placeholder: "Postal code ( 5 or 9 digits )",
      required: true,
      isValid: (value: string) => value?.length === 5 || value?.length === 9,
      errorMessage: "Required (5 or 9 digits)"
    },
    {
      label: "Email",
      key: "email",
      value: email,
      setValue: setEmail,
      placeholder: "Email",
      required: true,
      isValid: (value: string) => isValidEmail(value),
      errorMessage: "Required in correct format"
    }
  ];

  const requiredFields = fieldData.filter((field) => field.required);

  const updateErrorMap = (key, value) => {
    setErrors((errors) => ({ ...errors, [key]: value }));
  };

  const validateForm = () => {
    setErrors({});
    const errors = {};

    requiredFields.forEach((field) => {
      if (!field.isValid(field.value)) {
        errors[field.key] = "Must not be blank";
      }
    });

    if (!phoneNumber) {
      errors[InputErrors.PhoneNumber] = "Must not be blank";
    } else if (!isPossiblePhoneNumber(phoneNumber)) {
      errors[InputErrors.PhoneNumber] = "Invalid phone number";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      await orderDevicesForUser({
        address,
        address2,
        city,
        deviceIds: allowedDevices.map((device) => device.id),
        email,
        firstName,
        lastName,
        phone: phoneNumber,
        postalCode,
        programId: program_id,
        userId: user_id,
        state
      })
        .then(() => {
          onOrder();
          onClose();
          trackDeviceOrder({
            deviceIds: allowedDevices.map((device) => device.id) ?? [],
            deviceNames: allowedDevices.map((device) => device.name) ?? []
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Modal
      contentClass={styles.modalContent}
      className={`${styles.modal} ${isLoading ? styles.loading : ""}`}
      onClose={onClose}
      title="Device ordering"
    >
      <form onSubmit={handleSubmit} className={`${styles.form} form--block`}>
        <section className={styles.orderInfo}>
          <div className={styles.devicesWrapper}>
            <div className={styles.devices}>
              {allowedDevices.map((device, index) => (
                <DeviceCard
                  key={`allowed-device-${index}`}
                  imageHref={device.imageHref}
                  name={device.name}
                  subtitle={device.subtitle}
                />
              ))}
            </div>
          </div>

          <p className={styles.title}>Add shipping information</p>
          <p className={styles.subtitle}>Include clients name and address</p>
          <div className={styles.fields}>
            {fieldData.map(
              ({
                key,
                label,
                value,
                setValue,
                autoFocus,
                placeholder,
                required,
                select,
                className,
                isValid,
                errorMessage
              }) => {
                const onSelectChange = (e) => setValue(e.target.value);
                const onInputChange = (value: string) => {
                  setValue(value);
                  updateErrorMap(key, "");
                };

                return (
                  <div className={styles.inputContainer} key={key}>
                    {select ? (
                      <CustomSelect
                        value={value}
                        valueKey={select.keyValue}
                        onChange={onSelectChange}
                        renderOption={select.renderOption}
                        options={select.options}
                        placeholder={placeholder}
                        label={label}
                        className={className}
                        required={required}
                      />
                    ) : (
                      <Input
                        label={label}
                        required={required}
                        placeholder={placeholder}
                        value={value}
                        onChange={onInputChange}
                        autoFocus={autoFocus}
                        error={errors[key]}
                        onBlur={() => {
                          if (required && !isValid(value)) {
                            updateErrorMap(key, errorMessage);
                          }
                        }}
                      />
                    )}
                  </div>
                );
              }
            )}
            <div className={styles.inputContainer}>
              <label className="label label--block">
                Phone number{<span className="required-star">*</span>}
              </label>
              <div className="inputContainer">
                <CustomPhoneInput
                  value={phoneNumber}
                  onChange={(val) => {
                    setPhoneNumber(val);
                    updateErrorMap(InputErrors.PhoneNumber, "");
                  }}
                  defaultCountry="US"
                  useCountrySelect={false}
                  onBlur={() => {
                    if (!isPossiblePhoneNumber(phoneNumber ?? "", "US")) {
                      updateErrorMap(
                        InputErrors.PhoneNumber,
                        "Invalid phone number"
                      );
                    }
                  }}
                  error={errors[InputErrors.PhoneNumber]}
                />
              </div>
            </div>
          </div>
        </section>
        <div className={styles.actions}>
          <Button
            isLoading={isLoading}
            size="lg"
            type="submit"
            disabled={
              isLoading ||
              fieldData.some(
                (data) => data.required && !data.isValid(data.value)
              ) ||
              !isPossiblePhoneNumber(phoneNumber ?? "", "US")
            }
          >
            {t("devices.order")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DeviceOrderModal;

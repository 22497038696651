import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import NavHeaderLink from "./link/NavHeaderLink";
import MobileMenu from "./menu/MobileMenu";
import styles from "./NavHeader.module.scss";
import NavProfile from "./NavProfile";
import NavHeaderBanner from "./notification/NavHeaderBanner";

import AnthemLogo from "~/assets/concierge-care-logo.png";
import SidekickLogo from "~/assets/sidekick-logo.svg";
import config from "~/config";
import { UserPrivileges } from "~/constants/privileges";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { isMedicareLeadCoach } from "~/helpers/navigation/navigationHelpers";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import usePrograms from "~/hooks/useApi/usePrograms";
import useLoggedIn from "~/hooks/useLoggedIn";
import useUser from "~/hooks/useUser";
import { t } from "~/i18n";
import { removeNavBanner } from "~/state/navHeaderNotification/navHeaderSlice";
import { AppDispatch } from "~/state/store";

const cx = classNames.bind(styles);

const NavHeader = () => {
  const { user } = useUser();
  const { programs } = usePrograms();
  const showGroupsUploader = user && isMedicareLeadCoach({ user, programs });

  const isLoggedIn = useLoggedIn();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(removeNavBanner());
    }
  }, [isLoggedIn]);

  const { isEnabled: nextStepEnabled } = useFeatureToggle({
    featureName: SegmentationKey.CoachCentricView
  });

  const { isEnabled: accessManagementEnabled } = useFeatureToggle({
    featureName: SegmentationKey.AccessManagement
  });

  const hasLeadCoachPrivileges =
    parseInt(user?.privileges ?? "0") >= UserPrivileges.LeadCoach;

  return (
    <>
      <div
        className={cx(styles.NavHeader, {
          [styles.nextStep]: nextStepEnabled
        })}
      >
        <div className={styles.HeaderContent}>
          <Link tabIndex={-1} to="/">
            <img
              src={config.isAnthem ? AnthemLogo : SidekickLogo}
              alt={`${config.isAnthem ? "Anthem" : "Sidekick"} Logo`}
              className={styles.HeaderContent__Logo}
            />
          </Link>

          {user && (
            <>
              <MobileMenu />

              <div className={styles.HeaderContent__Right}>
                <NavHeaderLink
                  href="/programs"
                  text={t("programs.title")}
                  highlightOnPath="/program"
                />

                <NavHeaderLink
                  href="/access-management"
                  text={t("accessManagement.accessManagement")}
                  hidden={!accessManagementEnabled || !hasLeadCoachPrivileges}
                />

                <NavHeaderLink
                  href="/iframe?src=anthemDashboard"
                  text={t("header.anthemDashboard")}
                  highlightOnPath="/iframe"
                  hidden={!config.isAnthem}
                  data-testid="anthem-dashboard-nav-button"
                />

                <NavHeaderLink
                  href="/userguide"
                  text={t("header.userGuide")}
                  hidden={!config.isAnthem}
                  data-testid="user-guide-nav-button"
                />
                <NavHeaderLink
                  href="/groups"
                  text={t("header.groups")}
                  hidden={!showGroupsUploader}
                  data-test-id={"anthem-medicare-groups"}
                />
                <NavProfile user={user} />
              </div>
            </>
          )}
        </div>
      </div>
      <NavHeaderBanner />
    </>
  );
};

export default NavHeader;

import { GraphQLError } from "graphql";
import { gql } from "graphql-request";
import { t } from "i18next";

import { gqlClient } from "../api";

import { CreateInvitesDtoInput } from "~/gql/graphql";
import {
  ActOnInviteOutput,
  CareManagerWithPcIs,
  RemoveCareManagerDtoInput
} from "~/typing/graphql/types";

export const getAllCoachesAndInvites = async (
  coachId: number
): Promise<CareManagerWithPcIs[]> => {
  const query = gql`
    query GetAllCoachesAndInvites($coachId: Long!) {
      getAllCoachesAndInvites(coachId: $coachId) {
        pcis {
          locale
          programCatalogItemId
        }
        careManager {
          careManagerExists
          careManagerId
          email
          imageHref
          name
          privileges
          role
        }
        coachUserStatistics {
          activeUserCount
          coachId
          locale
          programCatalogItemId
          totalUserCount
        }
      }
    }
  `;

  return await gqlClient
    .request(query, {
      coachId
    })
    .then((data: any) => data?.getAllCoachesAndInvites);
};

export const inviteCareManagers = async (invite: CreateInvitesDtoInput) => {
  const mutation = gql`
    mutation CreateInvites($createInvites: CreateInvitesDtoInput!) {
      createInvites(createInvites: $createInvites)
    }
  `;

  return await gqlClient.request(mutation, {
    createInvites: invite
  });
};

export const actOnInvite = async (
  coachId: string,
  token: string
): Promise<ActOnInviteOutput | null> => {
  const mutation = gql`
    mutation ActOnInvite($coachId: Long!, $token: String!) {
      actOnInvite(coachId: $coachId, token: $token) {
        successPCIs {
          locale
          programCatalogItemId
        }
        failedPCIs {
          locale
          programCatalogItemId
        }
      }
    }
  `;

  try {
    return await gqlClient.request(mutation, {
      coachId,
      token
    });
  } catch (error: any) {
    console.error(error);
    if (error.response?.errors?.[0]?.message) {
      //Clean up the error message.
      //Typical format: "Exception while fetching data (/actOnInvite) : {{error message}}"
      const errorMessage = error.response.errors[0].message.replace(
        "Exception while fetching data (/actOnInvite) : ",
        ""
      );
      throw new GraphQLError(errorMessage);
    }

    throw new GraphQLError(t("accessManagement.errors.unknownError"));
  }
};

export const removeCareManager = async (
  removeCareManagerDto: RemoveCareManagerDtoInput
): Promise<boolean> => {
  const mutation = gql`
    mutation RemoveCareManager(
      $removeCareManagerDto: RemoveCareManagerDtoInput!
    ) {
      removeCareManager(removeCareManagerDto: $removeCareManagerDto)
    }
  `;

  return await gqlClient
    .request(mutation, {
      removeCareManagerDto
    })
    .then((data: any) => {
      if (!data.removeCareManager) {
        //If removeCareManager is false, the action failed.
        throw Error(t("accessManagement.errors.errorRemovingCareManager"));
      }
      return data;
    });
};

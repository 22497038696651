import classNames from "classnames";

import styles from "./Paragraph.module.scss";

const cx = classNames.bind(styles);

type ParagraphProps = {
  text: string;
  className?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

const Paragraph = ({ text, className, size = "md" }: ParagraphProps) => {
  return (
    <p
      className={cx(styles.paragraph, {
        [styles[size]]: true,
        [className ?? ""]: !!className
      })}
    >
      {text}
    </p>
  );
};

export default Paragraph;

import api from "../api";

import { UserProgramCoach } from "~/typing/sidekickTypes";

export const assignSpecialtyCoachToUser = async ({
  userId,
  programId,
  locale,
  specialtyId,
  coachId
}: {
  userId: string;
  programId: string;
  locale: string;
  specialtyId: string;
  coachId: string;
}) => {
  return await api
    .post<UserProgramCoach>(
      `/coach/programs/${programId}/locales/${locale}/users/${userId}/coaches/`,
      {
        coachId,
        specialtyId
      }
    )
    .then((res) => res.data);
};

export const assignCMToUser = async ({
  userId,
  programId,
  locale,
  coachId
}: {
  userId: string;
  programId: string;
  locale: string;
  coachId: string;
}) => {
  return await api.post(
    `/coach/programs/${programId}/locales/${locale}/users/${userId}/assigntocoach`,
    { coachUserId: coachId }
  );
};

export const autoAssignCoachesToUser = async ({
  userId,
  programId,
  locale,
  specialtyId
}: {
  userId: string;
  programId: string;
  locale: string;
  specialtyId: string;
}) => {
  return await api.post(
    `/coach/programs/${programId}/locales/${locale}/users/${userId}/coaches/autoassign`,
    { specialtyId }
  );
};

export const removeCoachFromUser = async ({
  userId,
  programId,
  locale,
  coachId,
  specialtyId
}: {
  userId: string;
  programId: string;
  locale: string;
  coachId: string;
  specialtyId: string;
}) => {
  return await api.delete(
    `/coach/programs/${programId}/locales/${locale}/users/${userId}/coaches/${coachId}?specialtyId=${specialtyId}`
  );
};

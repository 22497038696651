import { useContext, useEffect, useRef, useState } from "react";

import { readUnreadMessages } from "./NextStepChatView.helpers";
import styles from "./NextStepChatView.module.scss";

import { TaskPageContext } from "../TaskPage.context";

import ArrowRight from "~/assets/arrow-right-next-step.svg";
import Close from "~/assets/svgComponents/Close";
import Button from "~/components/button/Button";
import CoachRecommendedMessageModal from "~/components/messageModal/CoachRecommendedMessageModal";
import MessageList from "~/components/messages/messageWindow/MessageList";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import { RichTextEditor } from "~/components/richTextEditor/RichTextEditor";
import SearchInput from "~/components/searchInput/SearchInput";
import CustomSelect from "~/components/select/CustomSelect";
import { DEFAULT_FILTER_VALUE } from "~/constants/options";
import { useMarkMessageSuggestionAsSent } from "~/hooks/graphql/useCareManagerTasks";
import { useMessageThreads } from "~/hooks/useApi/messages/useMessageThreads";
import { useSendMessage } from "~/hooks/useApi/messages/useSendMessage";
import { CareManagerActions } from "~/hooks/useApi/useNextRecommendedUserActions";
import {
  KeyboardShortcuts,
  useKeyboardShortcut
} from "~/hooks/useKeyboardShortcut";
import useUser from "~/hooks/useUser";
import { t } from "~/i18n";
import { useUsersForAvatarInMessageThreads } from "~/pages/user/userPage/UserPage.helpers";
import colors from "~/styles/colors";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { ChatMessage } from "~/typing/sidekickTypes";

const NextStepChatView = () => {
  useKeyboardShortcut(
    [
      {
        keys: KeyboardShortcuts.sendMessage,
        callback: () => handleMessageSending()
      }
    ],
    { ignoreInputs: false }
  );
  const [messageInInput, setMessageInInput] = useState("");
  const [selectedCoachId, setSelectedCoachId] = useState<string>(
    DEFAULT_FILTER_VALUE
  );
  const [replyingToMessage, setMessageBeingRepliedTo] = useState<
    ChatMessage | undefined
  >();
  const [
    showRecommendedMessagesModal,
    setShowRecommendedMessagesModal
  ] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    nextUserInfo,
    registerActionForRecommendedUser,
    userDetails,
    userDetailsLoading,
    program,
    programLoading,
    copiedMessage,
    copiedMessageId,
    setCopiedMessage,
    setCopiedMessageId,
    newNextStep
  } = useContext(TaskPageContext);

  const { markMessageSuggestionAsSent } = useMarkMessageSuggestionAsSent();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const {
    trackNextStepRecommendedMessageCopied,
    trackSendMessage
  } = useAmplitudeTracking();

  const {
    messageThreads,
    isLoading: messageThreadsLoading,
    unreadMessages
  } = useMessageThreads({
    programCatalogItemId: nextUserInfo?.program_catalog_item_id ?? "",
    locale: nextUserInfo?.locale ?? "",
    userId: nextUserInfo?.user_id ?? "",
    messagesThreadOpen: true
  });

  const usersForAvatars = useUsersForAvatarInMessageThreads({
    messageThreads,
    userDetail: userDetails
  });

  const { user: authUser } = useUser();

  const { sendMessage, isSuccess: messageSuccessfullySent } = useSendMessage({
    userId: nextUserInfo?.user_id ?? "",
    programId: nextUserInfo?.program_catalog_item_id ?? "",
    locale: nextUserInfo?.locale ?? "",
    trackOnSend: false,
    wrapWithMarkdown: false
  });

  const filteredMessages = () => {
    if (selectedCoachId === DEFAULT_FILTER_VALUE)
      return messageThreads?.messages ?? [];
    return (
      messageThreads?.messages?.filter((msg) => {
        return (
          msg.senderUserId === selectedCoachId ||
          msg.recipientUserId === selectedCoachId
        );
      }) ?? []
    );
  };

  const getCoachOptions = () => {
    const defaultOption = {
      value: DEFAULT_FILTER_VALUE,
      label: "All Care Managers"
    };

    const coaches = Object.values(usersForAvatars)
      .filter((coach) => coach.userId !== userDetails?.user.userId)
      .map((coach) => ({
        value: coach.userId,
        label: coach.displayName
      }));

    return [defaultOption, ...coaches];
  };

  const getInputFooter = () =>
    newNextStep && (
      <button
        className={styles.sendArrow}
        disabled={!copiedMessage}
        onClick={handleMessageSending}
        tabIndex={0}
      >
        <img src={ArrowRight} alt="Send" className={styles.sendArrowImage} />
      </button>
    );

  useEffect(() => {
    setSelectedCoachId(DEFAULT_FILTER_VALUE);
  }, [userDetails]);

  useEffect(() => {
    setSelectedCoachId(
      authUser?.id || authUser?.userId || DEFAULT_FILTER_VALUE
    );
  }, [authUser]);

  useEffect(() => {
    if (messageSuccessfullySent) {
      registerActionForRecommendedUser({
        careManagerAction: CareManagerActions.CMA_MESSAGE_SENT,
        timeOfRanking: nextUserInfo?.time_of_ranking,
        userId: nextUserInfo?.user_id,
        carePriorityType: undefined
      });

      //If we are in a new next step, we should clear the copied message and messageId.
      //Clearing the messageId is important because we don't want to tell the backend that the same message is being sent again.
      if (newNextStep) {
        setCopiedMessage?.("");
        setCopiedMessageId?.("");
      }
    }
  }, [messageSuccessfullySent]);

  useEffect(() => {
    textAreaRef.current?.focus();
  }, [textAreaRef]);

  if (messageThreadsLoading || userDetailsLoading || programLoading)
    return (
      <section className={styles.loadingWrapper}>
        <PulseLoader inverted />
      </section>
    );

  const readMessages = () => {
    readUnreadMessages({
      urneadMessages: unreadMessages.unreadMessages ?? [],
      nextUserInfo: nextUserInfo
    });
  };

  const handleMessageSending = () => {
    if (copiedMessage) {
      readMessages();
      sendMessage({
        message: copiedMessage,
        suggestionId: copiedMessageId
      });

      if (copiedMessageId) {
        markMessageSuggestionAsSent({
          messageSuggestionSentDto: {
            suggestionId: copiedMessageId,
            userId: nextUserInfo?.user_id ?? ""
          }
        });
      }

      trackSendMessage({
        message: copiedMessage,
        messageSuggestionId: copiedMessageId
      });
    }
  };

  const handleMessageChange = (value) => {
    if (setCopiedMessage) {
      setCopiedMessage(value);
    } else {
      setMessageInInput(value);
    }
  };

  if (!userDetails?.user || !program) return null;

  return (
    <>
      <div className={styles.wrapper}>
        <section className={styles.chatWrapper}>
          <section className={styles.chatHeader}>
            <CustomSelect
              placeholder={t("messages.chooseCoach")}
              valueKey="value"
              value={selectedCoachId}
              options={getCoachOptions()}
              renderOption={({ label }) => label}
              wrapperClassName={styles.dropdown}
              className={styles.dropdownButton}
              onChange={(e) => setSelectedCoachId(e.target.value)}
            />
            <SearchInput
              onChange={setSearchQuery}
              placeholder={t("general.search")}
              value={searchQuery}
              className={styles.searchInput}
            />
          </section>
          <section className={styles.chat}>
            <MessageList
              unreadMessages={unreadMessages}
              messages={filteredMessages()}
              searchQuery={searchQuery}
              optedOut={false}
              user={userDetails}
              setMessageBeingRepliedTo={(value) =>
                setMessageBeingRepliedTo(value)
              }
              usersForAvatars={usersForAvatars}
              authUser={authUser}
              className={styles.messageList}
              chosenManager={
                selectedCoachId !== DEFAULT_FILTER_VALUE
                  ? usersForAvatars[selectedCoachId ?? ""]
                  : undefined
              }
              programThatUserIsIn={program}
            />
          </section>
        </section>

        <section className={styles.footer}>
          <div className={styles.inputContainer}>
            <div className={styles.textAreaWrapper}>
              {replyingToMessage && "text" in replyingToMessage && (
                <>
                  <div
                    className={styles.replyMessage}
                    title={replyingToMessage?.text}
                  >
                    <p>{`${t("general.to")} ${
                      usersForAvatars[replyingToMessage?.recipientUserId]
                        .fullName
                    }`}</p>
                    <p>{replyingToMessage?.text}</p>
                  </div>
                  <button
                    className={styles.closeReplyButton}
                    onClick={() => setMessageBeingRepliedTo(undefined)}
                    data-testid="message-window-footer-close-reply"
                  >
                    <Close color={colors.white} />
                  </button>
                </>
              )}

              <RichTextEditor
                footer={getInputFooter()}
                onChange={handleMessageChange}
                value={copiedMessage ? copiedMessage : messageInInput}
                placeholder={t("messages.inputPlaceholder", "Enter here...")}
              />
            </div>
            {!newNextStep && (
              <div className={styles.actions}>
                <Button onClick={() => setShowRecommendedMessagesModal(true)}>
                  {t("messages.messagesWindow.recommendedMessages")}
                </Button>
                <div className={styles.userActions}>
                  <Button
                    className={styles.skip}
                    onClick={() => {
                      readMessages();
                      registerActionForRecommendedUser({
                        careManagerAction:
                          CareManagerActions.CMA_NO_ACTION_NEEDED,
                        timeOfRanking: nextUserInfo?.time_of_ranking,
                        userId: nextUserInfo?.user_id,
                        carePriorityType: undefined
                      });
                    }}
                  >
                    {t("nextStep.messages.skip")}
                  </Button>
                  <Button
                    className={styles.save}
                    onClick={() => {
                      registerActionForRecommendedUser({
                        careManagerAction:
                          CareManagerActions.CMA_SAVE_FOR_LATER,
                        timeOfRanking: nextUserInfo?.time_of_ranking,
                        userId: nextUserInfo?.user_id,
                        carePriorityType: undefined
                      });
                    }}
                  >
                    {t("nextStep.messages.saveForLater")}
                  </Button>
                  <Button
                    disabled={!messageInInput}
                    className={styles.send}
                    onClick={() => {
                      readMessages();
                      sendMessage({ message: messageInInput });
                      trackSendMessage({ message: messageInInput });
                    }}
                  >
                    {t("messages.sendAndGetNextUser")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      {showRecommendedMessagesModal && (
        <CoachRecommendedMessageModal
          onSubmitMessage={(msg) => {
            setMessageInInput(msg.message);
            trackNextStepRecommendedMessageCopied({
              messageSuggestionId: msg.messageSuggestionId ?? ""
            });
          }}
          setShowRecommendedReplies={setShowRecommendedMessagesModal}
          userId={nextUserInfo?.user_id ?? ""}
          programId={nextUserInfo?.program_catalog_item_id ?? ""}
          locale={nextUserInfo?.locale ?? ""}
          submitButtonText={"Copy to chat"}
        />
      )}
    </>
  );
};

export default NextStepChatView;

import { createContext } from "react";

interface PROTemplateContextProps {
  proImages: Map<string, string>;
  setProImage: (key: string, image: string) => void;
  onCoachRatingChange?: (props: {
    coachRatingValue: number;
    surveyResultId: string;
    shouldUpdateDetails: boolean;
  }) => Promise<void>;
  uniqueValues: number[];
}

export const PROTemplateContext = createContext<PROTemplateContextProps>({
  proImages: new Map(),
  setProImage: () => undefined,
  onCoachRatingChange: async () => undefined,
  uniqueValues: []
});

import classNames from "classnames";
import { useState, useCallback } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import styles from "./Login.module.scss";

import Input from "~/components/input/Input";
import config from "~/config";
import { t } from "~/i18n";

const cx = classNames.bind(styles);

type LoginProps = {
  onLogin: (email: string, password: string) => void;
  error?: string;
  isLoading?: boolean;
  isInModal?: boolean;
};

type LoginFormProps = {
  onLogin: (email: string, password: string) => void;
  isLoading?: boolean;
  isInModal?: boolean;
  setShouldShowFormLogin: (value: boolean) => void;
};

// Subcomponent for the login form
const LoginForm = ({
  onLogin,
  isLoading,
  isInModal,
  setShouldShowFormLogin
}: LoginFormProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPW, setInvalidPW] = useState(false);
  // Focus the input field on mount
  // Validation logic for email and password fields
  const validateFields = () => {
    let isValid = true;

    if (!email.trim()) {
      setInvalidEmail(true);
      isValid = false;
    } else {
      setInvalidEmail(false);
    }

    if (!password.trim()) {
      setInvalidPW(true);
      isValid = false;
    } else {
      setInvalidPW(false);
    }

    return isValid;
  };
  const autoFocus = useCallback((el: HTMLInputElement | null) => {
    if (el) el.focus();
  }, []);

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    toast.remove();

    if (validateFields()) {
      onLogin(email, password);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.inputsWrapper}>
        <Input
          id="email"
          type="email"
          autoComplete="username"
          placeholder={t("login.enterEmail")}
          className="input"
          ref={autoFocus}
          value={email}
          onChange={setEmail}
          label="Email"
          error={invalidEmail ? t("login.errors.missingEmail") : ""}
        />

        <Input
          id="password"
          type="password"
          autoComplete="password"
          placeholder={t("login.enterPassword")}
          className="input"
          value={password}
          onChange={setPassword}
          label="Password"
          error={invalidPW ? t("login.errors.missingPassword") : ""}
        />
      </div>

      {!isInModal && (
        <Link to="/resetpassword" className={styles.reset}>
          {t("login.forgotpassword")}
        </Link>
      )}

      {config.isAnthem ? (
        <ElevanceLoginFormActions
          isLoading={isLoading}
          setShouldShowFormLogin={setShouldShowFormLogin}
          isInModal={isInModal}
        />
      ) : (
        <LoginFormActions isInModal={isInModal} isLoading={isLoading} />
      )}
    </form>
  );
};

const LoginFormActions = ({
  isInModal,
  isLoading
}: {
  isInModal?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <div className={styles.Login__actions}>
      {!isInModal && (
        <Link to="/signup">
          <button type="button" className="btn-secondary">
            {t("signup.signup")}
          </button>
        </Link>
      )}
      <button type="submit" className="btn-primary" disabled={isLoading}>
        {t("login.login")}
      </button>
    </div>
  );
};

const ElevanceLoginFormActions = ({
  isLoading,
  isInModal,
  setShouldShowFormLogin
}: {
  isLoading?: boolean;
  isInModal?: boolean;
  setShouldShowFormLogin: (value: boolean) => void;
}) => {
  return (
    <div>
      <div
        className={cx(styles.Login__actions, {
          [styles.elevance]: config.isAnthem
        })}
      >
        <button
          onClick={() => setShouldShowFormLogin(false)}
          type="button"
          className="btn-secondary"
        >
          {t("general.back")}
        </button>
        <button type="submit" className="btn-primary" disabled={isLoading}>
          {t("login.login")}
        </button>
      </div>
      {!isInModal && (
        <div className={styles.showFormBtnWrapper}>
          <p>{t("login.newUser")}</p>
          <Link to="/signup">
            <button type="button" className={styles.showFormBtn}>
              {t("login.createAccount")}
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

const ElevanceLogin = ({
  isInModal,
  setShouldShowFormLogin
}: {
  isInModal?: boolean;
  setShouldShowFormLogin: (value: boolean) => void;
}) => (
  <div className={cx({ [styles.elevanceLoginWrapper]: isInModal })}>
    <a
      href={`/auth/anthem${
        isInModal ? `?redirect=${encodeURIComponent(window.location.href)}` : ""
      }`}
      className={`${styles.anthemLogin} btn-primary`}
    >
      {t("login.loginElevance")}
    </a>
    <div className={styles.showFormBtnWrapper}>
      <p>{t("login.areYouAdmin")}</p>
      <button
        type="button"
        className={styles.showFormBtn}
        onClick={() => setShouldShowFormLogin(true)}
      >
        {t("login.loginHere")}
      </button>
    </div>
  </div>
);

const Login = ({
  onLogin,
  error,
  isLoading,
  isInModal = false
}: LoginProps) => {
  const [shouldShowFormLogin, setShouldShowFormLogin] = useState(
    !config.isAnthem
  );

  const getHeaderTitle = () => {
    if (config.isAnthem && shouldShowFormLogin) {
      return t("login.adminLogin");
    }
    return t("login.login");
  };

  // Determine the form container classes
  const formClasses = [
    styles.Login,
    error && styles["Login--error"],
    config.isAnthem && styles.elevance
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={formClasses}>
      {!isInModal && <h1>{getHeaderTitle()}</h1>}
      {shouldShowFormLogin && (
        <div>
          {config.isAnthem && (
            <p className={styles.loginWarning}>{t("login.elevance.warning")}</p>
          )}

          <LoginForm
            onLogin={onLogin}
            isLoading={isLoading}
            isInModal={isInModal}
            setShouldShowFormLogin={setShouldShowFormLogin}
          />
        </div>
      )}
      {config.isAnthem && !shouldShowFormLogin && (
        <ElevanceLogin
          isInModal={isInModal}
          setShouldShowFormLogin={setShouldShowFormLogin}
        />
      )}
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default Login;

import classNames from "classnames";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";

import styles from "./Input.module.scss";

import ProgressCircle from "../progressCircle/ProgressCircle";

import CloseIcon from "~/assets/close-circle.svg";
import ExclamationIcon from "~/assets/exclamation-error.svg";
import SuccessIcon from "~/assets/success.svg";

const cx = classNames.bind(styles);

interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (value: string) => void;
  label?: string;
  dataTestId?: string;
  error?: string;
  className?: string;
  wrapperClassName?: string;
  icon?: string;
  isLoading?: boolean;
  hint?: string;
  leadingIcon?: React.ReactNode;
  regexValidation?: RegExp;
}

interface IProps extends InputProps {
  inputRef?: ForwardedRef<HTMLInputElement>;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, inputRef) => {
  return <InputComponent {...props} inputRef={inputRef} />;
});

const InputIcon = ({
  isError,
  isLoading,
  isSuccess,
  isRequired
}: {
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  isRequired?: boolean;
}) => {
  if (isLoading) {
    return <ProgressCircle />;
  }

  if (isError) {
    return (
      <img
        className={cx(styles.inputIcon, {
          [styles.error]: isError
        })}
        src={CloseIcon}
      />
    );
  }

  if (isSuccess) {
    return (
      <img
        className={cx(styles.inputIcon, {
          [styles.success]: isSuccess
        })}
        src={SuccessIcon}
      />
    );
  }

  if (isRequired) {
    return <img className={cx(styles.inputIcon)} src={ExclamationIcon} />;
  }

  return null;
};

export const InputHint = ({
  hint,
  isError,
  isSuccess
}: {
  hint?: string;
  isSuccess?: boolean;
  isError?: boolean;
}) => {
  if (!hint) return null;

  return (
    <div
      className={styles.hintWrapper}
      data-testid={isError ? "input-error" : "input-hint"}
    >
      <InputIcon isError={isError} isSuccess={isSuccess} />

      <p
        className={cx(styles.hint, {
          [styles.error]: isError,
          [styles.success]: isSuccess && !isError
        })}
      >
        {hint}
      </p>
    </div>
  );
};

const InputComponent = ({
  onChange,
  type = "text",
  label,
  required,
  dataTestId,
  inputRef,
  value,
  error,
  className,
  wrapperClassName,
  isLoading,
  hint,
  leadingIcon,
  disabled,
  regexValidation,
  ...rest
}: IProps) => {
  const [errorToDisplay, setErrorToDisplay] = useState<string | undefined>(
    error
  );
  const [showError, setShowError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [currentValue, setCurrentValue] = useState<string>(
    value ? String(value) : ""
  );

  const handleChange = (value: string) => {
    setShowError(false);
    setIsValid(false);

    if (type === "number") {
      setCurrentValue(value.replace(/[^\d.]/gi, ""));
    } else {
      setCurrentValue(value);
    }
  };

  useEffect(() => {
    if (currentValue !== undefined) {
      onChange?.(currentValue);
    }
  }, [currentValue]);

  useEffect(() => {
    setErrorToDisplay(error);
    setShowError(!!error);
  }, [error]);

  const handleValidation = () => {
    if (!regexValidation) return;

    const isValidInput = regexValidation.test(currentValue.toLowerCase());

    if (isValidInput) {
      setErrorToDisplay(undefined);
      setIsValid(true);
    } else {
      setErrorToDisplay("Invalid input");
      setShowError(true);
    }
  };

  const shouldShowError = showError && !!errorToDisplay;

  return (
    <div
      className={cx({
        [styles.wrapper]: true,
        [wrapperClassName ?? ""]: !!wrapperClassName
      })}
    >
      {label && (
        <label className={styles.label}>
          {label}
          {required && <span className={styles.star}>*</span>}
        </label>
      )}
      <div
        className={cx(styles.inputWrapper, {
          [styles.error]: shouldShowError,
          [styles.loading]: isLoading,
          [styles.success]: isValid && !shouldShowError,
          [className ?? ""]: !!className
        })}
        data-testid="input-wrapper"
      >
        {leadingIcon}
        <input
          data-testid={dataTestId}
          ref={inputRef}
          onChange={(e) => handleChange(e.target.value)}
          value={currentValue}
          type={type}
          onBlur={handleValidation}
          disabled={disabled || isLoading}
          {...rest}
        />
        <InputIcon
          isError={shouldShowError}
          isLoading={isLoading}
          isSuccess={isValid}
          isRequired={required && !currentValue}
        />
      </div>
      {(!!hint || !!errorToDisplay) && (
        <div className={styles.hintsContainer}>
          <InputHint hint={hint} isSuccess={isValid && !shouldShowError} />
          {shouldShowError && (
            <InputHint hint={errorToDisplay} isError={true} />
          )}
        </div>
      )}
    </div>
  );
};

Input.displayName = "Input";
export default Input;

import { Placement } from "@floating-ui/react";
import { useRef, useState } from "react";

import styles from "./Options.module.scss";

import FloatingElement from "../floatingElement/FloatingElement";

import MenuIcon from "~/assets/menu.svg";
import { OptionItem } from "~/typing/carePortalTypes";

type OptionsProps = {
  options: OptionItem[];
  mainOption?: OptionItem;
  hideOptions?: boolean;
  className?: string;
  iconSrc?: string;
  dataTestId?: string;
  optionsPlacement?: Placement;
};

const Options = ({
  options,
  mainOption,
  className,
  hideOptions,
  iconSrc,
  dataTestId,
  optionsPlacement = "bottom"
}: OptionsProps) => {
  const listRef = useRef(null);

  const [showOptions, setShowOptions] = useState(false);

  const optionsMarkup = (
    <div id="options-list" className={styles.options}>
      <ul>
        {mainOption && (
          <>
            <li className={styles.mainOption}>
              <button
                type="button"
                onClick={() => {
                  mainOption.func();
                }}
              >
                {mainOption.renderOption
                  ? mainOption.renderOption()
                  : mainOption.title}
              </button>
            </li>
            <div className={styles.seperator} />
          </>
        )}
        {options
          .filter((option) => !option.hidden)
          .map((option, index) => (
            <li className={option.className} key={`option-${index}`}>
              <button
                type="button"
                onClick={() => {
                  option.func();
                  setShowOptions(false);
                }}
              >
                {option.renderOption ? option.renderOption() : option.title}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );

  return (
    <FloatingElement
      placement={optionsPlacement}
      triggerOnClick
      floatingContent={optionsMarkup}
      tooltipClassName={styles.optionsTooltip}
      controlledOpenState={{
        isOpen: showOptions,
        setIsOpen: setShowOptions
      }}
    >
      <div
        className={`${styles.container} ${className ?? ""}  ${
          hideOptions ? styles.hide : ""
        }`}
        data-testid={dataTestId}
        ref={listRef}
      >
        <button
          type="button"
          className={styles.menu}
          data-testid="options-menu"
        >
          <img src={iconSrc || MenuIcon} />
        </button>
      </div>
    </FloatingElement>
  );
};

export default Options;

import styles from "./UserLayersTooltip.module.scss";

import { Layer } from "~/typing/sidekickTypes";

type UserLayersTooltipProps = {
  layers: Layer[];
};

const UserLayersTooltip = ({ layers }: UserLayersTooltipProps) => {
  return (
    <div className={styles.userLayersTooltip}>
      <ul className={styles.layerList}>
        {layers.map((layer: Layer) => (
          <li
            key={layer.id}
            className={styles.layerItem}
            style={{ color: layer.displayColor }}
          >
            {layer.carePortalAbbreviation || layer.title || layer.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserLayersTooltip;

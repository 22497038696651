import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  RouterProvider
} from "react-router-dom";

import App from "./App";
import AppErrorBoundary from "./AppErrorBoundary";
import { PrivateRoute, Logout } from "./auth/auth";
import config from "./config";
import { isLocalhost } from "./helpers/browser/browserHelpers";
import AccessManagementPage from "./pages/accessManagement/AccessManagementPage";
import ActOnInvitePage from "./pages/accessManagement/actonvite/ActOnInvitePage";
import GoToUserPage from "./pages/go-to-user/GoToUserPage";
import GroupsOverview from "./pages/groups/GroupsOverview";
import GroupsUpload from "./pages/groups/GroupsUpload";
import IframePage from "./pages/iframe/IframePage";
import ExportAnthemUsersPage from "./pages/import/ExportAnthemUsersPage";
import ImportAnthemUsersPage from "./pages/import/ImportAnthemUsersPage";
import ImportEntriesUsersPage from "./pages/import/ImportEntriesUsersPage";
import LoginPage from "./pages/login/LoginPage";
import TaskListPage from "./pages/nextStep/TaskListPage";
import TaskPage from "./pages/nextStep/TaskPage";
import NotFound from "./pages/notFound/NotFound";
import ProfilePage from "./pages/profile/ProfilePage";
import ProgramPage from "./pages/program/ProgramPage";
import ProgramUsersPage from "./pages/program/programUsers/ProgramUsersPage";
import ProgramRegistrationsPage from "./pages/program/registration/ProgramRegistrationsPage";
import ProgramsOverviewPage from "./pages/programOverview/ProgramsOverviewPage";
import ResetPasswordPage from "./pages/resetpassword/ResetPasswordPage";
import SignupPage from "./pages/signup/SignupPage";
import InvitesList from "./pages/team/InvitesList";
import TeamList from "./pages/team/TeamList";
import TeamPage from "./pages/team/TeamPage";
import DocumentsPage from "./pages/user/documents/DocumentsPage";
import InformationPage from "./pages/user/information/InformationPage";
import MedicationPage from "./pages/user/medication/MedicationPage";
import FoodJournalPage from "./pages/user/performance/journals/FoodJournalPage";
import MentalPage from "./pages/user/performance/mental/MentalPage";
import MissionsPage from "./pages/user/performance/missions/MissionsPage";
import PerformancePage from "./pages/user/performance/PerformancePage";
import PhysicalPage from "./pages/user/performance/physical/PhysicalPage";
import PROPage from "./pages/user/pro/PROPage";
import UserPage from "./pages/user/userPage/UserPage";
import UserGuidePage from "./pages/userGuide/UserGuidePage";
import store from "./state/store";
import { fetchUser } from "./state/user/userSlice";

import { AutoTranslateProvider } from "~/contexts/translationContext/AutoTranslateProvider";
import "./i18n";
import "./index.scss";

if (!isLocalhost) {
  Sentry.init({
    dsn: config.sentryKey,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    environment: config.platform,
    tracesSampleRate: 0.5,
    ignoreErrors: [/__gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost/]
  });
}

await store.dispatch(fetchUser());

const root = createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route element={<PrivateRoute />}>
        <Route index element={<Navigate replace to="programs" />} />
        <Route path="exporting" element={<ExportAnthemUsersPage />} />
        <Route path="importing" element={<ImportAnthemUsersPage />} />
        <Route path="imports" element={<ImportEntriesUsersPage />} />
        <Route path="access-management" element={<AccessManagementPage />} />
        <Route path="actoninvite" element={<ActOnInvitePage />} />
        <Route path="programs" element={<ProgramsOverviewPage />} />
        <Route path="iframe" element={<IframePage />} />
        <Route path="tasks" element={<TaskListPage />} />
        <Route path="tasks/:user_id" element={<TaskPage />} />
        <Route
          path="program/:program_id/locale/:locale"
          element={<ProgramPage />}
        >
          <Route path={"users"} element={<ProgramUsersPage />} />
          <Route path={"registration"} element={<ProgramRegistrationsPage />} />

          <Route index element={<Navigate replace={true} to="users" />} />
        </Route>
        <Route
          path="program/:program_id/locale/:locale/team"
          element={<TeamPage />}
        >
          <Route path={"coaches"} element={<TeamList />} />
          <Route path={"invites"} element={<InvitesList />} />
          <Route index element={<Navigate replace to={"coaches"} />} />
        </Route>
        <Route
          path="program/:program_id/locale/:locale/users/:user_id"
          element={<UserPage />}
        >
          <Route path={"medication"} element={<MedicationPage />} />
          <Route path={"information"} element={<InformationPage />} />
          <Route path={"missions"} element={<MissionsPage />} />
          <Route path={"food-journal"} element={<FoodJournalPage />} />
          <Route path={"documents"} element={<DocumentsPage />} />
          <Route index element={<Navigate replace to={`information`} />} />

          <Route path={"performance"} element={<PerformancePage />}>
            <Route path={"physical"}>
              <Route path={":physical_category"} element={<PhysicalPage />} />
              <Route
                index
                element={<Navigate replace={true} to="activity" />}
              />
            </Route>
            <Route path={"mental"}>
              <Route path={":mental_category"} element={<MentalPage />} />
              <Route index element={<Navigate replace={true} to="sleep" />} />
            </Route>
            <Route index element={<Navigate replace to={"physical"} />} />
          </Route>
          <Route path={"pro"} element={<PROPage />}>
            <Route path={":survey_id"} element={<PROPage />} />
          </Route>
        </Route>
        <Route path="me" element={<ProfilePage />} />
      </Route>
      <Route path="userguide" element={<UserGuidePage />} />
      {/* <Route path="groups" element={config.isAnthem ? <GroupsUpload /> : null} /> */}
      <Route path="groups">
        <Route path={":file_upload_id"} element={<GroupsOverview />} />
        <Route index element={<GroupsOverview />} />
        <Route path={"upload"} element={<GroupsUpload />} />
      </Route>
      <Route path="login" element={<LoginPage />} />
      <Route path="signup" element={<SignupPage />} />
      <Route path="resetpassword" element={<ResetPasswordPage />} />
      <Route path="logout" element={<Logout />} />
      <Route path="go-to-user" element={<GoToUserPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools
          buttonPosition="bottom-left"
          initialIsOpen={false}
        />
        <HelmetProvider>
          <AppErrorBoundary>
            <AutoTranslateProvider>
              <RouterProvider router={router} />
            </AutoTranslateProvider>
          </AppErrorBoundary>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
);

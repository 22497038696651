import { TimeInMs } from "~/constants/measurements";
import {
  SymptomColorMap,
  Symptoms,
  SymptomSeverity,
  SymptomTrend
} from "~/constants/symptoms";
import { SymptomLogDetails } from "~/typing/graphql/types";

type IconImportFunction = () => Promise<{
  default: React.ComponentType<any>;
}>;

const getSymptomColor = (
  trend: SymptomTrend,
  severity: SymptomSeverity
): string => {
  switch (true) {
    case trend === SymptomTrend.Severe:
      return SymptomColorMap.Severe;
    case trend === SymptomTrend.Improvement:
      return SymptomColorMap.Improvement;
    case severity === SymptomSeverity.NotTracked:
      return SymptomColorMap.NotTracked;
    default:
      return SymptomColorMap.None;
  }
};

export const digaMenoIconResolver = async (
  id: string,
  severityString: SymptomSeverity,
  trend: SymptomTrend
): Promise<React.ReactNode | null> => {
  const severity = getSeverityNumber(severityString);

  const color = getSymptomColor(trend, severityString);

  const icons = {
    // Brain fog
    BrainFogMild: () => import("~/assets/digaMeno/BrainFogMild"),
    BrainFogModerate: () => import("~/assets/digaMeno/BrainFogModerate"),
    BrainFogSevere: () => import("~/assets/digaMeno/BrainFogSevere"),
    BrainFogStrong: () => import("~/assets/digaMeno/BrainFogStrong"),

    // Fatigue
    FatigueMild: () => import("~/assets/digaMeno/FatigueMild"),
    FatigueModerate: () => import("~/assets/digaMeno/FatigueModerate"),
    FatigueSevere: () => import("~/assets/digaMeno/FatigueSevere"),
    FatigueStrong: () => import("~/assets/digaMeno/FatigueStrong"),

    // Hot flashes
    HotFlashesMild: () => import("~/assets/digaMeno/HotFlashesMild"),
    HotFlashesModerate: () => import("~/assets/digaMeno/HotFlashesModerate"),
    HotFlashesSevere: () => import("~/assets/digaMeno/HotFlashesSevere"),
    HotFlashesStrong: () => import("~/assets/digaMeno/HotFlashesStrong"),

    // Incontinence
    IncontinenceMild: () => import("~/assets/digaMeno/IncontinenceMild"),
    IncontinenceModerate: () =>
      import("~/assets/digaMeno/IncontinenceModerate"),
    IncontinenceSevere: () => import("~/assets/digaMeno/IncontinenceSevere"),
    IncontinenceStrong: () => import("~/assets/digaMeno/IncontinenceStrong"),

    // Low mood
    LowMoodMild: () => import("~/assets/digaMeno/LowMoodMild"),
    LowMoodModerate: () => import("~/assets/digaMeno/LowMoodModerate"),
    LowMoodSevere: () => import("~/assets/digaMeno/LowMoodSevere"),
    LowMoodStrong: () => import("~/assets/digaMeno/LowMoodStrong"),

    // Low sex drive
    LowSexDriveMild: () => import("~/assets/digaMeno/LowSexDriveMild"),
    LowSexDriveModerate: () => import("~/assets/digaMeno/LowSexDriveModerate"),
    LowSexDriveSevere: () => import("~/assets/digaMeno/LowSexDriveSevere"),
    LowSexDriveStrong: () => import("~/assets/digaMeno/LowSexDriveStrong"),

    // Night sweats
    NightSweatsMild: () => import("~/assets/digaMeno/NightSweatsMild"),
    NightSweatsModerate: () => import("~/assets/digaMeno/NightSweatsModerate"),
    NightSweatsSevere: () => import("~/assets/digaMeno/NightSweatsSevere"),
    NightSweatsStrong: () => import("~/assets/digaMeno/NightSweatsStrong"),

    // Sleep problems
    SleepProblemsMild: () => import("~/assets/digaMeno/SleepProblemsMild"),
    SleepProblemsModerate: () =>
      import("~/assets/digaMeno/SleepProblemsModerate"),
    SleepProblemsSevere: () => import("~/assets/digaMeno/SleepProblemsSevere"),
    SleepProblemsStrong: () => import("~/assets/digaMeno/SleepProblemsStrong"),

    // Stress
    StressMild: () => import("~/assets/digaMeno/StressMild"),
    StressModerate: () => import("~/assets/digaMeno/StressModerate"),
    StressSevere: () => import("~/assets/digaMeno/StressSevere"),
    StressStrong: () => import("~/assets/digaMeno/StressStrong"),

    // Vaginal dryness
    VaginalDrynessMild: () => import("~/assets/digaMeno/VaginalDrynessMild"),
    VaginalDrynessModerate: () =>
      import("~/assets/digaMeno/VaginalDrynessModerate"),
    VaginalDrynessSevere: () =>
      import("~/assets/digaMeno/VaginalDrynessSevere"),
    VaginalDrynessStrong: () => import("~/assets/digaMeno/VaginalDrynessStrong")
  };

  const iconFetcher = async (iconImportFunction: IconImportFunction) => {
    const IconComponent = (await iconImportFunction()).default;
    return <IconComponent color={color} width={48} height={48} />;
  };

  switch (id) {
    case "Brain fog":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.BrainFogMild);
        case 2:
          return iconFetcher(icons.BrainFogModerate);
        case 3:
          return iconFetcher(icons.BrainFogSevere);
        case 4:
          return iconFetcher(icons.BrainFogStrong);
        default:
          return iconFetcher(icons.BrainFogMild);
      }

    case "Fatigue":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.FatigueMild);
        case 2:
          return iconFetcher(icons.FatigueModerate);
        case 3:
          return iconFetcher(icons.FatigueSevere);
        case 4:
          return iconFetcher(icons.FatigueStrong);
        default:
          return iconFetcher(icons.FatigueMild);
      }
    case "Hot flushes":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.HotFlashesMild);
        case 2:
          return iconFetcher(icons.HotFlashesModerate);
        case 3:
          return iconFetcher(icons.HotFlashesSevere);
        case 4:
          return iconFetcher(icons.HotFlashesStrong);
        default:
          return iconFetcher(icons.HotFlashesMild);
      }
    case "Incontinence":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.IncontinenceMild);
        case 2:
          return iconFetcher(icons.IncontinenceModerate);
        case 3:
          return iconFetcher(icons.IncontinenceSevere);
        case 4:
          return iconFetcher(icons.IncontinenceStrong);
        default:
          return iconFetcher(icons.IncontinenceMild);
      }
    case "Low mood":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.LowMoodMild);
        case 2:
          return iconFetcher(icons.LowMoodModerate);
        case 3:
          return iconFetcher(icons.LowMoodSevere);
        case 4:
          return iconFetcher(icons.LowMoodStrong);
        default:
          return iconFetcher(icons.LowMoodMild);
      }
    case "Low sex drive":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.LowSexDriveMild);
        case 2:
          return iconFetcher(icons.LowSexDriveModerate);
        case 3:
          return iconFetcher(icons.LowSexDriveSevere);
        case 4:
          return iconFetcher(icons.LowSexDriveStrong);
        default:
          return iconFetcher(icons.LowSexDriveMild);
      }
    case "Night sweats":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.NightSweatsMild);
        case 2:
          return iconFetcher(icons.NightSweatsModerate);
        case 3:
          return iconFetcher(icons.NightSweatsSevere);
        case 4:
          return iconFetcher(icons.NightSweatsStrong);
        default:
          return iconFetcher(icons.NightSweatsMild);
      }
    case "Sleep loss":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.SleepProblemsMild);
        case 2:
          return iconFetcher(icons.SleepProblemsModerate);
        case 3:
          return iconFetcher(icons.SleepProblemsSevere);
        case 4:
          return iconFetcher(icons.SleepProblemsStrong);
        default:
          return iconFetcher(icons.SleepProblemsMild);
      }
    case "Stress":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.StressMild);
        case 2:
          return iconFetcher(icons.StressModerate);
        case 3:
          return iconFetcher(icons.StressSevere);
        case 4:
          return iconFetcher(icons.StressStrong);
        default:
          return iconFetcher(icons.StressMild);
      }
    case "Vaginal dryness":
      switch (severity) {
        case 0:
        case 1:
          return iconFetcher(icons.VaginalDrynessMild);
        case 2:
          return iconFetcher(icons.VaginalDrynessModerate);
        case 3:
          return iconFetcher(icons.VaginalDrynessSevere);
        case 4:
          return iconFetcher(icons.VaginalDrynessStrong);
        default:
          return iconFetcher(icons.VaginalDrynessMild);
      }
    default:
      return null;
  }
};

export const getSeverityString = (severity: number): string => {
  switch (severity) {
    case -1:
      return "Not tracked";
    case 0:
      return "None";
    case 1:
      return "Mild";
    case 2:
      return "Moderate";
    case 3:
      return "Severe";
    case 4:
      return "Very severe";
    default:
      return "Not tracked";
  }
};

const getSeverityNumber = (severity: string): number => {
  switch (severity) {
    case "Not tracked":
      return -1;
    case "None":
      return 0;
    case "Mild":
      return 1;
    case "Moderate":
      return 2;
    case "Severe":
      return 3;
    case "Very severe":
      return 4;
    default:
      return -1;
  }
};

export const getMissingSymptoms = (symptoms: any[]): any => {
  const missingSymptoms = Symptoms.filter((symptom) => {
    return !symptoms.find((s) => s.symptomTitle === symptom);
  });

  return missingSymptoms;
};

export const isNewestLogWeekOld = (logs: SymptomLogDetails[]): boolean => {
  if (logs?.length) {
    const newestLog = logs[logs.length - 1];
    const newestLogDate = new Date(newestLog.loggedAt);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - newestLogDate.getTime());
    const diffDays = Math.ceil(diffTime / TimeInMs.Day);
    return diffDays >= 7;
  }
  return false;
};

import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./InviteCoach.module.scss";

import api from "~/api/api";
import Button from "~/components/button/Button";
import Input from "~/components/input/Input";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import Modal from "~/components/modal/Modal";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";

type InviteCoachProps = {
  onClose: () => void;
  programId: string;
  locale: string;
  userId?: string;
  invalidateInvites?: () => void;
};

const InviteCoach = ({
  onClose,
  programId,
  locale,
  userId,
  invalidateInvites
}: InviteCoachProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { trackInviteCoach } = useAmplitudeTracking();

  const inviteCoach = async (email) => {
    const path = `/coach/programcatalogitems/${programId}/locales/${locale}/invites`;
    const payload = {
      email,
      role: "role_care_manager",
      userId
    };

    await api.post(path, payload).then((res) => {
      trackInviteCoach();

      if (invalidateInvites) invalidateInvites();
      return res;
    });
  };

  const handleInviteCoach = async (e) => {
    e.preventDefault();
    setLoading(true);
    await inviteCoach(email)
      .then(() => {
        displaySuccessToast({ message: t("team.addCoachSuccess", { email }) });
        onClose();
      })
      .catch(() => {
        displayErrorToast({ message: t("team.addCoachError") });
        setLoading(false);
      });
  };

  return (
    <Modal
      title={t("team.inviteCoach")}
      className={styles.modal}
      onClose={onClose}
    >
      {loading && <LoadingSpinner />}
      <form
        onSubmit={handleInviteCoach}
        className={`${loading ? styles.hidden : ""} form--block`}
      >
        <Input
          type="email"
          value={email}
          onChange={setEmail}
          autoFocus
          label={t("general.email", "Email")}
        />
        <Button
          type="submit"
          disabled={!email} // let browser validate email if non empty string
        >
          {t("general.invite")}
        </Button>
      </form>
    </Modal>
  );
};

export default InviteCoach;

import { QueryKeyFactory } from "./queryKeysFactory";
import useApi from "./useApi";

import config from "~/config";
import { Survey } from "~/typing/sidekickTypes";

export default function useSurvey({
  programCatalogItemId,
  locale,
  surveyId,
  disabled = false
}: {
  programCatalogItemId: string;
  locale: string;
  surveyId: string;
  disabled?: boolean;
}) {
  const path = `/coach/programs/${programCatalogItemId}/locales/${locale}/surveys/${surveyId}${
    config.isAnthem ? `?displayLocale=en` : ""
  }`;
  const { data, isError, isLoading, invalidate } = useApi<Survey>({
    path,
    queryKey: QueryKeyFactory.programs.surveys(programCatalogItemId, locale),
    disabled: !programCatalogItemId || !locale || !surveyId || disabled
  });
  return {
    survey: data,
    isError,
    isLoading,
    invalidate
  };
}

import api from "../api";

import { UserSpecialty } from "~/typing/sidekickTypes";

export const assignSpecialtyToCoach = async (specialtyId: string) => {
  return await api
    .post<UserSpecialty>("/coach/me/specialty", {
      specialtyId
    })
    .then((res) => res.data);
};

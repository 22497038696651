import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./BaseWeightModal.module.scss";

import { InformationPageContext } from "../../informationPageContext";

import { saveBaseWeight } from "~/api/requests/weightRequests";
import Input from "~/components/input/Input";
import Modal from "~/components/modal/Modal";
import round from "~/helpers/number/round";
import { lbsTokg } from "~/helpers/units/conversion";
import kgToLbs from "~/helpers/units/kgToLbs";
import useUseMetric from "~/hooks/useUseMetric";
import { t } from "~/i18n";
import { UserURLParams } from "~/typing/carePortalTypes";

type BaseWeightModalProps = {
  onClose: () => void;
};

const BaseWeightModal = ({ onClose }: BaseWeightModalProps) => {
  const [baseWeight, setBaseWeight] = useState<number>();

  const useMetric = useUseMetric();

  const { baseWeight: userBaseWeight, invalidateBaseWeight } = useContext(
    InformationPageContext
  );
  const {
    user_id = "",
    program_id = "",
    locale = ""
  } = useParams<UserURLParams>();

  const handleSaveBaseWeight = async () => {
    const value = useMetric ? baseWeight : lbsTokg(baseWeight ?? 0);

    await saveBaseWeight(value ?? 0, program_id, locale, user_id);
    if (invalidateBaseWeight) invalidateBaseWeight();
    onClose();
  };

  return (
    <Modal title={t("user.health.baseWeight")} onClose={onClose}>
      <div className={styles.baseWeightModal}>
        <Input
          placeholder={t("weightHistory.enterWeight")}
          onChange={(value) => setBaseWeight(parseFloat(value))}
          type="number"
          value={
            useMetric
              ? round(userBaseWeight?.value, 1)?.toString()
              : kgToLbs(userBaseWeight?.value ?? 0)?.toString()
          }
        />
        <button onClick={handleSaveBaseWeight} className="btn-sm btn-primary">
          {t("general.save")}
        </button>
      </div>
    </Modal>
  );
};

export default BaseWeightModal;

import { AxiosResponse } from "axios";

import { Exception } from "~/classes/exception/Exception";
import { t } from "~/i18n";

export const responseHandler = (response: AxiosResponse) => response;

export const attachmentResponseHandler = async (
  res: AxiosResponse
): Promise<AxiosResponse> => {
  // Convert the arraybuffer to a Uint8Array
  const uint8Array = new Uint8Array(res.data);

  const rs = new ReadableStream({
    start(controller) {
      // Enqueue the data in chunks
      controller.enqueue(uint8Array);
      controller.close();
    }
  });

  //Create the blob url for the attachment
  const response = new Response(rs);
  const blob = await response.blob();
  const attachmentURL = URL.createObjectURL(blob);

  return { ...res, data: attachmentURL };
};

export const defaultErrorHandler = (error: any) => {
  let errorTitle: string | undefined;
  let errorStatusCode: number | undefined;
  let errorMessage: string | undefined;

  if (error.response) {
    if (error.response.status === 401) {
      errorTitle =
        t("apiError.message.sessionExpired") +
        " " +
        t("apiError.description.sessionExpired");
    } else if (error.response.status === 404) {
      errorTitle = `${t("apiError.message.notFound")} ${t(
        "apiError.description.notFound"
      )}`;
    } else if (error.response.status === 500) {
      errorTitle = `${t("apiError.message.internalServerError")} ${t(
        "apiError.description.internalServerError"
      )}`;
    } else if (error.response.status === 403) {
      errorTitle = `${t("apiError.message.forbidden")} ${t(
        "apiError.description.forbidden"
      )}`;
    } else if (error.response.status === 400) {
      errorTitle = `${t("apiError.message.badRequest")} ${t(
        "apiError.description.badRequest"
      )}`;
    } else if (error.response.status === 422) {
      errorTitle = `${t("apiError.message.unproccesableEntity")} ${t(
        "apiError.description.unproccesableEntity"
      )}`;
    } else if (error.response.status === 429) {
      errorTitle = `${t("apiError.message.tooManyRequests")} ${t(
        "apiError.description.tooManyRequests"
      )}`;
    } else if (error.response.status === 503) {
      errorTitle = `${t("apiError.message.serviceUnavailable")} ${t(
        "apiError.description.serviceUnavailable"
      )}`;
    } else {
      errorTitle = `${t("apiError.message.somethingWentWrong")} ${t(
        "apiError.description.somethingWentWrong"
      )}`;
    }
    if (error.response.data?.error?.message) {
      errorMessage = error.response.data.error.message;
    }
    errorStatusCode = error.response.status;
  } else {
    errorStatusCode = error.statusCode ?? 400;
  }

  const exception = new Exception({
    title: errorTitle ?? "Bad request",

    message:
      errorMessage ??
      error.message ??
      "An error occurred while handling the response from the server",
    context: {
      error
    },
    statusCode: errorStatusCode,
    path: "/api/apiResponseHandlers.ts (defaultErrorHandler)"
  }).log();

  if (exception.isNotAuthenticated()) {
    if (!window.location.pathname.includes("login")) {
      window.dispatchEvent(new Event("session-expired"));
    }
  } else {
    throw exception;
  }
};

export const authErrorHandler = (error: any) => {
  const errorName = error.response.data.error;
  const errorDescription = error.response.data.error_description;
  let errorMessage = t("apiError.auth.systemError");
  const exception = new Exception({
    title: errorMessage ?? "Bad request",
    message:
      error?.message ??
      "An error occurred while handling the response from the server",
    context: {
      error
    },
    statusCode: error?.response?.status ?? 400,
    path: "/api/apiResponseHandlers.ts (authErrorHandler)"
  });

  if (errorName === "account_blocked_until") {
    const time = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric"
    }).format(new Date(errorDescription));
    errorMessage = t("apiError.auth.tooManyFailedAttempts", {
      time: time
    });
  }
  if (errorName === "account_not_found") {
    errorMessage = t("apiError.auth.accountNotFound");
  }
  if (errorName === "login_failed") {
    errorMessage = t("apiError.auth.loginFailed");
  }

  exception.setMessage(errorMessage).log();

  throw exception;
};

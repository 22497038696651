import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import RegistrationIcon from "~/assets/registration.svg";
import UsersIcon from "~/assets/users.svg";
import TopNav from "~/components/topNav/TopNav";
import { NavData } from "~/typing/carePortalTypes";

type ProgramNavProps = {
  userNotifications?: number;
  showRegistration: boolean;
};

export default function ProgramNav({
  userNotifications = 0,
  showRegistration
}: ProgramNavProps) {
  const { t } = useTranslation();
  const { program_id = "", locale = "" } = useParams();

  const getNavData = () => {
    const navData: NavData[] = [
      {
        title: t("general.users", "Users"),
        url: "users",
        iconSrc: UsersIcon,
        notificationCount: userNotifications
      }
    ];

    if (showRegistration) {
      navData.push({
        title: t("programNav.registration", "Registration"),
        url: "registration",
        iconSrc: RegistrationIcon
      });
    }

    return navData;
  };

  return (
    <TopNav
      baseUrl={`/program/${program_id}/locale/${locale}`}
      navData={getNavData()}
    />
  );
}

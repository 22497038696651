import { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import Zendesk, { ZendeskAPI } from "react-zendesk";

import "./App.scss";

import config from "./config";
import { useSessionManagement } from "./hooks/useSessionManagement";
import LoginModal from "./pages/login/LoginModal";
import colors from "./styles/colors";
import Toast from "./Toast";
import { initAmplitude } from "./tracking/amplitudeHelpers";

import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";

initAmplitude();

function App() {
  useEffect(() => {
    const handleWindowReload = () => {
      window.location.reload();
    };
    window.addEventListener("vite:preloadError", handleWindowReload);

    return () => {
      window.removeEventListener("vite:preloadError", handleWindowReload);
    };
  }, []);

  const { displayLoginModal, setDisplayLoginModal } = useSessionManagement();

  return (
    <div className="App">
      <Helmet titleTemplate="%s | Care Portal" defer={false} />
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
      {config.enableZendesk && (
        <Zendesk
          defer
          zendeskKey={config.zendeskKey}
          color={{ theme: colors.darkBlue100 }}
          position={{ horizontal: "left" }}
          onLoaded={() => {
            if (config.isAnthem) {
              ZendeskAPI("webWidget", "setLocale", "en");
            }
          }}
        />
      )}
      {displayLoginModal ? (
        <LoginModal onClose={() => setDisplayLoginModal(false)} />
      ) : (
        <Toast />
      )}
    </div>
  );
}

export default App;

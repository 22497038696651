import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MessageSuggestion.module.scss";
import { MessageSuggestionReportModal } from "./MessageSuggestionReportModal";
import MessageSuggestionDismissalModal from "./MessageSuggestionsDismissalModal";
import MessageSuggestionToggle from "./MessageSuggestionToggle";

import Button from "../button/Button";

import Copy from "~/assets/copy.svg";
import Flag from "~/assets/flag.svg";
import { useFallbackMessage } from "~/hooks/graphql/useFallbackMessage";
import { useReportMessage as useReportMessageGQL } from "~/hooks/graphql/useReportMessage";
import {
  ReportMessageProps,
  useReportMessage
} from "~/hooks/useApi/useCoachSuggestedMessages";
import {
  KeyboardShortcuts,
  useKeyboardShortcut
} from "~/hooks/useKeyboardShortcut";
import { t } from "~/i18n";
import { TaskPageContext } from "~/pages/nextStep/TaskPage.context";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  MessageSuggestionDto,
  MutationReportMessageArgs
} from "~/typing/graphql/types";

type MessageSuggestionProps = {
  title?: string;
  userId: string;
  messages: MessageSuggestionDto[];
  simulateMessageGeneration?: boolean;
  graphQLMessageReport?: boolean;
  showFallbackMessage?: boolean;
};

const MessageSuggestion = ({
  title = t("messages.suggestedReply"),
  userId,
  messages,
  simulateMessageGeneration = false,
  graphQLMessageReport = false,
  showFallbackMessage = false
}: MessageSuggestionProps) => {
  useKeyboardShortcut([
    {
      keys: KeyboardShortcuts.copy,
      callback: () => handleCopy(messages[currentMessageIndex])
    },
    {
      keys: KeyboardShortcuts.report,
      callback: () => setShowReportModal(true)
    }
  ]);

  const { t } = useTranslation();

  const { setCopiedMessage, setCopiedMessageId, program } = useContext(
    TaskPageContext
  );

  const {
    fallbackMessage,
    isLoading: fallbackMessageLoading
  } = useFallbackMessage(
    {
      userId,
      locale: program?.locale ?? "en"
    },
    showFallbackMessage
  );

  const { reportMessageMutation, isPending } = useReportMessage();
  const {
    reportMessage: reportMessageMutationGQL,
    isPending: isPendingGQL
  } = useReportMessageGQL();

  const handleReportMessage = (
    reportMessage: ReportMessageProps,
    index: number
  ) => {
    if (!reportMessage) return;
    if (graphQLMessageReport) {
      const reportMessageGQL: MutationReportMessageArgs = {
        issues: reportMessage.issues.join(","),
        message: reportMessage.message,
        note: reportMessage.note,
        suggestionId: reportMessage.suggestionId,
        userId: parseInt(userId as string)
      };

      reportMessageMutationGQL(reportMessageGQL);
      handleAddReportedMessage(index);

      return;
    } else if (!graphQLMessageReport) {
      reportMessageMutation(reportMessage);
      handleAddReportedMessage(index);
    }
  };

  const { trackMessageSuggestionCopied } = useAmplitudeTracking();

  const [reportedMessageMap, setReportedMessageMap] = useState<
    Map<number, boolean>
  >(new Map());
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showDismissalModal, setShowDismissalModal] = useState(false);
  const [canDismissAllMessages, setCanDismissAllMessages] = useState(
    simulateMessageGeneration ? false : true
  );

  const handleAddReportedMessage = (index: number) => {
    const newReportedMessageMap = new Map(reportedMessageMap);
    newReportedMessageMap.set(index, true);
    setReportedMessageMap(newReportedMessageMap);
  };

  const handleCopy = (message: MessageSuggestionDto) => {
    if (!message?.message) return;
    setCopiedMessage?.(message?.message);
    setCopiedMessageId?.(message?.suggestionId);
    trackMessageSuggestionCopied({
      messageSuggestionId: message?.suggestionId
    });
  };

  const handleKeyDown = (
    event:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>,
    onClick: () => void
  ) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <h4>{title}</h4>
        <div className={styles.wrapper}>
          {reportedMessageMap.get(currentMessageIndex) ? (
            <div className={styles.report}>
              {t("nextStep.messages.messageReported")}
            </div>
          ) : null}
          <div className={styles.message}>
            {messages?.length ? (
              <p key={messages[currentMessageIndex]?.suggestionId}>
                {messages[currentMessageIndex]?.message ??
                  t("nextStep.errors.noMessage")}
              </p>
            ) : null}
            {messages?.length === 0 && (
              <p>{t("nextStep.errors.noMessageSuggestion")}</p>
            )}
          </div>
          <div className={styles.actions}>
            <MessageSuggestionToggle
              messages={messages}
              onMessageIndexChange={(index) => {
                if (!canDismissAllMessages && index !== currentMessageIndex) {
                  setCanDismissAllMessages(true);
                }
                setCurrentMessageIndex(index);
              }}
              simulateMessageGeneration={simulateMessageGeneration}
            />
            {messages.length ? (
              <div className={styles.buttons}>
                {!reportedMessageMap.get(currentMessageIndex) && (
                  <button
                    className={styles.button}
                    onClick={() => setShowReportModal(true)}
                    onKeyDown={(e) =>
                      handleKeyDown(e, () => setShowReportModal(true))
                    }
                  >
                    <img src={Flag} alt="Flag" />
                  </button>
                )}
                <button
                  className={styles.button}
                  disabled={!!reportedMessageMap.get(currentMessageIndex)}
                  onClick={() => handleCopy(messages[currentMessageIndex])}
                  onKeyDown={(e) =>
                    handleKeyDown(e, () =>
                      handleCopy(messages[currentMessageIndex])
                    )
                  }
                >
                  <img src={Copy} alt="Copy" />
                  <p>{t("general.copy")}</p>
                </button>
              </div>
            ) : null}
          </div>
        </div>
        {!fallbackMessageLoading &&
          fallbackMessage?.message &&
          showFallbackMessage && (
            <Button
              size="sm"
              inverted
              onClick={() => setShowDismissalModal(true)}
              onKeyDown={(e) =>
                handleKeyDown(e, () => setShowDismissalModal(true))
              }
              className={styles.unusableBtn}
              disabled={!canDismissAllMessages}
              title={
                !canDismissAllMessages
                  ? "Please generate new messages before reporting"
                  : undefined
              }
            >
              {t("generic.unusable")}
            </Button>
          )}
        <p className={styles.disclaimer}>
          <strong>{t("general.disclaimer")}:</strong>{" "}
          {t("messages.suggestedReplies.disclaimer")}
        </p>
      </div>
      {showReportModal && (
        <MessageSuggestionReportModal
          message={messages[currentMessageIndex]}
          userId={userId}
          setShowReportModal={setShowReportModal}
          handleMessageReported={handleReportMessage}
          reportIsPending={isPending || isPendingGQL}
          currentMessageIndex={currentMessageIndex}
        />
      )}
      {showDismissalModal && (
        <MessageSuggestionDismissalModal
          setShowDismissalModal={setShowDismissalModal}
          userId={userId}
          messages={messages}
          onCopyMessage={handleCopy}
          reportedMessageMap={reportedMessageMap}
          fallbackMessage={fallbackMessage}
          handleMessageReported={handleReportMessage}
          reportIsPending={isPending || isPendingGQL}
        />
      )}
    </>
  );
};

export default MessageSuggestion;

import {
  useFloating,
  offset,
  flip,
  shift,
  arrow,
  autoUpdate,
  Placement,
  size
} from "@floating-ui/react";
import {
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useClick
} from "@floating-ui/react";
import { useState, useRef } from "react";

const useCustomFloating = ({
  placement = "top",
  triggerOnClick = false,
  enableShift = true,
  matchWidth = false,
  controlledIsOpen,
  controlledSetIsOpen
}: {
  placement: Placement;
  triggerOnClick?: boolean;
  enableShift?: boolean;
  matchWidth?: boolean;
  controlledIsOpen?: boolean;
  controlledSetIsOpen?: (isOpen: boolean) => void;
}) => {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const isOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : uncontrolledIsOpen;
  const setIsOpen =
    controlledSetIsOpen !== undefined
      ? controlledSetIsOpen
      : setUncontrolledIsOpen;

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      ...(enableShift
        ? [
            shift({ padding: 32 }),
            flip({
              padding: 32
            })
          ]
        : []),
      ...(matchWidth
        ? [
            size({
              apply({ rects, elements }) {
                Object.assign(elements.floating.style, {
                  minWidth: `${rects.reference.width}px`
                });
              }
            })
          ]
        : []),
      arrow({
        element: arrowRef
      })
    ],
    whileElementsMounted: autoUpdate,
    placement
  });

  const hover = useHover(context, {
    move: !triggerOnClick,
    enabled: !triggerOnClick
  });
  const click = useClick(context, { enabled: triggerOnClick });
  const focus = useFocus(context);
  const dismiss = useDismiss(context, {
    outsidePress: true,
    referencePress: false,
    enabled: true
  });
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
    focus,
    dismiss,
    role
  ]);

  return {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    arrowRef,
    isOpen,
    setIsOpen,
    context
  };
};

export default useCustomFloating;

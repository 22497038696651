import classNames from "classnames/bind";
import { useState, ReactNode, useEffect, useMemo, useId } from "react";

import checkBoxStyles from "./CustomCheckBoxSelect.module.scss";
import { Dependencies, SelectLabel } from "./CustomSelect.components";
import styles from "./CustomSelect.module.scss";
import { OptionDependencies } from "./CustomSelect.types";

import CheckboxInput from "../checkboxInput/CheckboxInput";
import FloatingElement from "../floatingElement/FloatingElement";
import SearchInput from "../searchInput/SearchInput";

import { UNDEFINED_OPTION } from "~/constants/options";
import { getStringLengthInPixels } from "~/helpers/string/stringHelpers";
import { t } from "~/i18n";
import { SelectOption } from "~/typing/carePortalTypes";

const cx = classNames.bind(styles);
const checkBoxCx = classNames.bind(checkBoxStyles);

const OPTION_TEXT_PIXELS_LIMIT = 173;

type CustomSelectProp = {
  onChange: (values: string[]) => void;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;

  options: SelectOption[];
  placeholder?: string;
  noOptions?: ReactNode;
  iconSrc?: string;
  selected?: string[];
  label?: string;
  includeNoValueOption?: boolean;
  dataTestId?: string;
  allowSearch?: boolean;
  displayOptionsCount?: boolean;
  error?: string;
  isReadOnly?: boolean;
  required?: boolean;
  expanded?: boolean;
  dependencies?: OptionDependencies;
  noValuesSelectedPlaceholder?: string;
};

const CustomCheckBoxSelect = ({
  onChange,
  className = "",
  wrapperClassName = "",

  disabled,
  options,
  placeholder = t("select.placeholder"),
  noOptions = t("select.noOptions", "No options"),
  iconSrc,
  selected,
  label,
  includeNoValueOption,
  dataTestId,
  allowSearch,
  displayOptionsCount,
  isReadOnly,
  required,
  error,
  expanded,
  dependencies,
  noValuesSelectedPlaceholder = t("select.noneSelected")
}: CustomSelectProp) => {
  const id = useId();
  const [searchString, setSearchString] = useState("");
  const [showError, setShowError] = useState(false);

  const filteredOptions = useMemo(() => {
    if (!allowSearch || !searchString) return options;
    return options.filter((option) =>
      option.text?.toLowerCase().includes(searchString.toLowerCase())
    );
  }, [searchString, options]);

  const [showOptions, setShowOptions] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

  // Check if no values are selected. Behave as if all values are selected if no values are selected.
  const noValuesSelected = selectedOptions.length === 0;

  useEffect(() => {
    setSelectedOptions(
      selected?.map((select) => ({ value: select ?? "", text: "" })) ?? []
    );
  }, [selected]);

  useEffect(() => setShowOptions(expanded ?? false), [expanded]);

  useEffect(() => {
    setSelectedOptions((prev) =>
      prev.filter((option) => {
        if (includeNoValueOption && option.value === UNDEFINED_OPTION) {
          return true;
        }
        return options.some((o) => o.value === option.value);
      })
    );
  }, [options, selected?.length]);

  const handleOptionClick = (selectedOption: SelectOption) => {
    const copiedList = [...selectedOptions];
    const index = copiedList.findIndex(
      (option) => option.value === selectedOption.value
    );

    if (index < 0) {
      copiedList.push(selectedOption);
    } else {
      copiedList.splice(index, 1);
    }

    setSelectedOptions(copiedList);
    onChange(copiedList.map((option) => option.value));
  };

  const clearAll = () => {
    setSelectedOptions([]);
    onChange([]);
  };

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  const getSelectPlaceholder = () => {
    if (allValuesSelected) {
      return t("select.allSelected");
    }
    if (selectedOptions.length === 0) {
      return noValuesSelectedPlaceholder;
    }

    return selectedOptions.length > 0
      ? t("select.selected", { count: selectedOptions.length })
      : placeholder;
  };

  const renderedOptions = (includeNoValueOption
    ? [
        {
          text: t("select.noValueDefined"),
          value: UNDEFINED_OPTION
        } as SelectOption
      ]
    : []
  ).concat(filteredOptions);

  const allValuesSelected = renderedOptions.every((option) =>
    selectedOptions.some((filtered) => filtered.value === option.value)
  );

  const selectAll = () => {
    const newSelectedOptions = renderedOptions.filter(
      (option) =>
        !selectedOptions.some((selected) => selected.value === option.value)
    );

    const combinedOptions = [...selectedOptions, ...newSelectedOptions];

    setSelectedOptions(combinedOptions);
    onChange(combinedOptions.map((option) => option.value));
  };

  const optionsMarkup = (
    <ul
      id={id}
      data-testid="custom-checkbox-select-options"
      tabIndex={-1}
      className={checkBoxCx({
        options: !expanded,
        expandedOptions: expanded
      })}
    >
      {!isReadOnly && (
        <li
          className={checkBoxCx({
            toggle: true
          })}
        >
          <button
            tabIndex={0}
            onClick={selectAll}
            disabled={(allValuesSelected && !noValuesSelected) || isReadOnly}
          >
            {t("general.selectAll")}
          </button>
          <button
            tabIndex={0}
            onClick={clearAll}
            disabled={selectedOptions.length === 0 || isReadOnly}
          >
            {t("general.clearAll")}
          </button>
        </li>
      )}
      {allowSearch && (
        <li>
          <SearchInput
            value={searchString}
            className={styles.search}
            placeholder={t("general.search")}
            onChange={setSearchString}
          />
        </li>
      )}
      {dependencies && <Dependencies dependencies={dependencies} />}
      <span className={checkBoxStyles.scrollable}>
        {renderedOptions.map((option, index) => {
          return (
            <li
              key={`list-item-${index}-${option.value} `}
              className={checkBoxCx({
                option: true,
                carousel:
                  getStringLengthInPixels(option?.text ?? "") >
                    OPTION_TEXT_PIXELS_LIMIT && !expanded,
                readOnly: isReadOnly
              })}
            >
              <CheckboxInput
                label={
                  option.renderOption ? option.renderOption() : option.text
                }
                checked={selectedOptions.some((o) => o.value == option.value)}
                onChange={() => handleOptionClick(option)}
                disabled={isReadOnly}
              />
            </li>
          );
        })}
      </span>
      {options.length === 0 && !includeNoValueOption && <li>{noOptions}</li>}
    </ul>
  );

  return (
    <div className={wrapperClassName} id={`wrapper-${id}`}>
      <SelectLabel
        label={label}
        required={required}
        options={displayOptionsCount ? filteredOptions : undefined}
      />
      {expanded ? (
        optionsMarkup
      ) : (
        <FloatingElement
          floatingContent={optionsMarkup}
          matchWidth
          triggerOnClick
          enableShift={false}
          placement="bottom-start"
          tooltipClassName={styles.optionsWrapper}
          controlledOpenState={{
            isOpen: showOptions,
            setIsOpen: setShowOptions
          }}
        >
          <div
            data-testid={dataTestId}
            className={cx({
              [checkBoxStyles.wrapper]: true,
              [className]: className !== undefined,
              [styles.customSelect]: true
            })}
          >
            <button
              onClick={() => setShowOptions(!showOptions)}
              className={cx({
                select: true,
                selectButton: true,
                disabled: disabled,
                active: showOptions,
                hasError: !!error && showError,
                [className]: className !== undefined
              })}
              tabIndex={disabled ? undefined : 0}
              type="button"
            >
              {iconSrc && (
                <img src={iconSrc} alt="select icon" className={styles.icon} />
              )}
              <span>{getSelectPlaceholder()}</span>
            </button>
            {error && showError && <p className={styles.error}>{error}</p>}
          </div>
        </FloatingElement>
      )}
    </div>
  );
};

export default CustomCheckBoxSelect;

import { UserProgram } from "~/typing/sidekickTypes";

const GEN_EMPTY_SHELL_LOOP_GUID = "0190366a-2e41-77b3-ad93-a7d19947e193";
const WHOLE_HEALTH_COM_PCI_ID = "69";
const WHOLE_HEALTH_COM_UAT_PCI_ID = "94";

export default function (
  userPrograms: UserProgram[] | undefined,
  programId: string,
  locale: string
) {
  if (!userPrograms?.length) return undefined;

  const programsWithTemplate = userPrograms.filter((program) => {
    // TODO: Remove this temporary exclusion once we stop fetching the most recent program
    // Exclude the Gen Empty Shell Loop programTemplate from the list, but only for Whole Health COM programs
    const isWholeHealthComProgram =
      program.programCatalogItemId === WHOLE_HEALTH_COM_PCI_ID ||
      program.programCatalogItemId === WHOLE_HEALTH_COM_UAT_PCI_ID;

    if (
      isWholeHealthComProgram &&
      program.programTemplateId === GEN_EMPTY_SHELL_LOOP_GUID
    ) {
      return false;
    }

    return (
      program.programCatalogItemId === programId && program.locale === locale
    );
  });

  const sorted = programsWithTemplate.sort((a, b) => {
    const aVal = a.joinedDate;
    const bVal = b.joinedDate;

    // Always sort undefined to bottom
    if (aVal === undefined || aVal === "") {
      return 1;
    }
    if (bVal === undefined || bVal === "") {
      return -1;
    }

    return aVal < bVal ? 1 : -1;
  });

  const mostRecentProgram = sorted?.[0];
  return mostRecentProgram;
}

import styles from "./MessageWindow.module.scss";

import Notification from "~/components/notification/Notification";
import { t } from "~/i18n";
import { UnreadMessagesState } from "~/pages/user/userPage/unreadMessagesReducer";
import { OptionItem } from "~/typing/carePortalTypes";
import {
  CareTeamMember,
  Coach,
  EnrichedAuthenticatedUser,
  MessageType,
  SMSMessage,
  Specialty,
  UserDetailsUser
} from "~/typing/sidekickTypes";

const messagesContainsUserId = (
  messages: MessageType[] | SMSMessage[],
  userId: string
) =>
  messages.some(
    (msg) => msg.senderUserId === userId || msg.recipientUserId === userId
  );

export const shouldBeReadByAuthenticatedUser = (
  message: MessageType,
  userId: string
) => {
  return (
    !message.seenDate &&
    message.senderUserId === userId &&
    "unseenByNonRecipient" in message &&
    message.unseenByNonRecipient
  );
};

const messageToIntendedCoachIsUnread = (
  message: MessageType,
  coachUserId: string
) => {
  return !message.seenDate && message.recipientUserId === coachUserId;
};

export const coachHasUnreadMessage = (
  messages: MessageType[],
  coachUserId: string
) => {
  if (!coachUserId) return false;
  return messages.some((message) =>
    messageToIntendedCoachIsUnread(message, coachUserId)
  );
};

export const getCoachTitle = ({
  coachId,
  usersForAvatars,
  specialties,
  specialtyCoaches
}: {
  coachId: string;
  usersForAvatars?: { [key: number]: Coach };
  specialtyCoaches?: CareTeamMember[];
  specialties?: Specialty[];
}) => {
  if (!usersForAvatars || !specialtyCoaches || !specialties) return "";

  let title = usersForAvatars[coachId]?.title ?? "";

  const specialtyCoach = specialtyCoaches.find(
    (coach) => coach.coachUserId === coachId
  );

  if (specialtyCoach) {
    title = specialties.find(
      (specialty) => specialty.id === specialtyCoach.specialtyId
    )?.localizedName;
  }

  return title;
};

export const getCoachOptions = ({
  messages,
  unreadMessages,
  usersForAvatars,
  patient,
  loggedInUser,
  onOptionClick,
  specialties,
  specialtyCoaches
}: {
  messages: MessageType[];
  unreadMessages: UnreadMessagesState | undefined;
  usersForAvatars: { [key: number]: Coach };
  patient: UserDetailsUser;
  loggedInUser: EnrichedAuthenticatedUser;
  onOptionClick: (coachId?: string) => void;
  specialtyCoaches?: CareTeamMember[];
  specialties?: Specialty[];
}) => {
  const options: OptionItem[] = Object.keys(usersForAvatars)
    .filter((userId) => {
      return (
        userId !== patient.userId &&
        userId !== loggedInUser.id &&
        messagesContainsUserId(messages, userId)
      );
    })
    .map(
      (userId) =>
        ({
          title: usersForAvatars[userId]?.displayName,
          func: () => {
            onOptionClick(userId);
          },
          renderOption: function CoachOption() {
            const unreadMessagesForCoach = unreadMessages
              ? unreadMessages.unreadMessages.filter(
                  (message) => message.recipientId === userId
                )
              : undefined;

            const title = getCoachTitle({
              coachId: userId,
              usersForAvatars,
              specialtyCoaches,
              specialties
            });

            return (
              <div className={styles.coachOption}>
                <p>
                  <span className={styles.coachName}>
                    {usersForAvatars[userId]?.displayName}
                  </span>
                  <span className={styles.coachTitle}>{title}</span>
                </p>
                {(unreadMessagesForCoach?.length ?? 0) > 0 && (
                  <Notification
                    className={
                      unreadMessagesForCoach?.some(
                        (message) => message.unreadByAuthUser
                      )
                        ? styles.blueBubble
                        : styles.greyBubble
                    }
                    size="xs"
                    count={1}
                  />
                )}
              </div>
            );
          }
        } as OptionItem)
    );

  options.unshift({
    func: () => onOptionClick(),
    renderOption: function CoachOption() {
      return (
        <div className={styles.coachOption}>
          <p>
            <span className={styles.coachName}>
              {t("notes.allCareManagers")}
            </span>
          </p>
        </div>
      );
    }
  });

  return options;
};

export const getCoachOverallNotifiactionStyle = (
  unreadMessages: UnreadMessagesState
) => {
  if (unreadMessages.unreadMessages.some((message) => message.toAuthUser)) {
    return styles.pinkBubble;
  }

  if (
    unreadMessages.unreadMessages.some((message) => message.unreadByAuthUser)
  ) {
    return styles.blueBubble;
  }

  if (unreadMessages.unreadMessages.some((message) => !message.toAuthUser)) {
    return styles.greyBubble;
  }

  return undefined;
};

export const idBelongsToUser = (
  id: string,
  user?: EnrichedAuthenticatedUser
) => {
  return id === user?.userId || id === user?.id;
};

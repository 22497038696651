import { chatApi } from "../api";

import { displayErrorToast } from "~/helpers/toast/displayToast";
import { t } from "~/i18n";
import { MessageSuggestionType } from "~/typing/sidekickTypes";

export const getMessageSuggestions = async ({
  programCatalogItemId,
  locale,
  userId,
  numMessages = 3,
  messageType = MessageSuggestionType.WeeklyFeedback
}: {
  programCatalogItemId: string;
  locale: string;
  userId: string;
  numMessages?: number;
  messageType?: MessageSuggestionType;
}): Promise<any> => {
  return await chatApi
    .get(
      `/program/${programCatalogItemId}/locale/${locale}/user/${userId}/get_n_message_suggestions?num_messages=${numMessages}&message_type=${messageType}`
    )
    .then((res) => res.data || [])
    .catch(() => {
      displayErrorToast({
        message: t("errors.fetch", { resource: "Message Suggestions" }),
        id: "message-suggestions-error"
      });
    });
};

/**
 * According to backend engineers, this endpoint will be deprecated soon, and totally replaced by the a grapgql endpoint.
 */
export const postReportMessage = async ({
  suggestionId,
  message,
  user_id,
  issues,
  note
}: {
  suggestionId: string;
  message: string;
  user_id: number;
  issues: string[];
  note: string;
}): Promise<any> => {
  return await chatApi
    .post("/report_message", {
      suggestionId,
      message,
      user_id,
      issues: issues.join(","), //Issues was originally an array of strings, but now the API expects a comma separated string.
      note
    })
    .catch(() => {
      displayErrorToast({
        message: t("errors.action", {
          resource: t("nextStep.reportMessage.title")
        })
      });
      return [];
    });
};

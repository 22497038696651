import classNames from "classnames";
import { DateTime } from "luxon";

import styles from "./PROTemplateHeader.module.scss";

import { SurveyResultToDisplay } from "../../hooks/useSurveyResultsToDisplay";

import ChevronRightThick from "~/assets/chevron-right-thick.svg";
import ChevronRight from "~/assets/chevron-right.svg";
import { dateIsInFuture, dateIsToday } from "~/helpers/date/dateHelpers";
import { t } from "~/i18n";

const cx = classNames.bind(styles);

const PROTemplateHeader = ({
  surveyResultsToDisplay,
  useTextBased,
  headerMonths,
  isFirstDay,
  isNewestEntry,
  offsetFromToday,
  setOffsetFromToday
}: {
  surveyResultsToDisplay: SurveyResultToDisplay[];
  useTextBased: boolean;
  headerMonths: Map<number, string>;
  isFirstDay: boolean;
  isNewestEntry: boolean;
  offsetFromToday: number;
  setOffsetFromToday: (offset: number) => void;
}) => {
  const dayClasses = (date: string) => {
    if (dateIsInFuture(date)) {
      return styles.dayColorFuture;
    }
    if (dateIsToday(date)) {
      return styles.dayColorToday;
    }
    return "";
  };
  return (
    <thead>
      <tr>
        {
          <td colSpan={12} className={styles.todayCell}>
            <button
              type="button"
              onClick={() => setOffsetFromToday(0)}
              className={styles.today}
            >
              {t("time.latest")}
              <img src={ChevronRightThick} />
            </button>
          </td>
        }
      </tr>
      <tr>
        <td />

        {/* Header for month */}
        {surveyResultsToDisplay?.map((pro, index) => {
          return (
            <td key={`month-${pro.id}-${index}-${pro.date}`}>
              <div className={styles.header}>{headerMonths.get(index)}</div>
            </td>
          );
        })}

        {!useTextBased && (
          <td
            colSpan={
              surveyResultsToDisplay ? surveyResultsToDisplay?.length - 2 : 0
            }
          ></td>
        )}
      </tr>
      <tr>
        <td>
          {!isFirstDay && (
            <div className={styles.arrowLeftContainer}>
              <img
                className={cx(styles.arrow, styles.arrowLeft)}
                src={ChevronRight}
                onClick={() => setOffsetFromToday(offsetFromToday + 1)}
                data-testid="protemplate-arrow-left"
              />
            </div>
          )}
        </td>
        {surveyResultsToDisplay.map((pro, index) => (
          <td key={`survey-display-${pro.id}-${index}-${pro.date}`}>
            <div
              className={`${styles.dayName} ${dayClasses(
                pro.dateTime.toString()
              )}`}
            >
              {pro.dateTime.weekdayShort}
            </div>
            <div
              className={`${styles.dayNumber} ${dayClasses(
                pro.dateTime.toString()
              )}`}
            >
              {pro.dateTime.day}
            </div>
          </td>
        ))}
        <td>
          <img
            className={cx(styles.arrow, {
              [styles.arrowHidden]: isNewestEntry
            })}
            src={ChevronRight}
            onClick={() => setOffsetFromToday(offsetFromToday - 1)}
          />
        </td>
      </tr>
      <tr>
        <td />
        {
          <>
            {surveyResultsToDisplay.map((pro, index) => {
              return (
                <td key={`time-simple-${pro.id}-${index}`}>
                  <div className={styles.time}>
                    {pro.dateTime.toLocaleString(DateTime.TIME_SIMPLE)}
                  </div>
                </td>
              );
            })}
          </>
        }
      </tr>
    </thead>
  );
};

export default PROTemplateHeader;

import countries from "~/constants/countries";
import { EnrolmentStatuses } from "~/constants/enrolmentStatuses";
import { HipaaStatus } from "~/constants/hipaaStatus";
import { DEFAULT_FILTER_VALUE } from "~/constants/options";
import states from "~/constants/states";
import {
  TextMessageOkStatuses,
  TextMessageErrorStatuses,
  SMSStatus
} from "~/constants/textMessaging";
import { t } from "~/i18n";
import { SelectOption } from "~/typing/carePortalTypes";
import { ExternalUser, Program } from "~/typing/sidekickTypes";

export const INCORRECT_OPTION = "Incorrect Option";

export const getCallConsentStatusText = (status) => {
  switch (status) {
    case 1:
      return "programRegistrationList.consentToCall.dnc";
    case 2:
      return "programRegistrationList.consentToCall.concent";
    case 3:
      return "programRegistrationList.consentToCall.wrongNo";
    default:
      return "general.unknown";
  }
};

export const getEnrolmentStatusText = (status: number) => {
  switch (status) {
    case EnrolmentStatuses.Eligible:
      return "programRegistrationList.enrolment.eligible";
    case EnrolmentStatuses.NotEligible:
      return "programRegistrationList.enrolment.notEligible";
    case EnrolmentStatuses.NotInterested:
      return "programRegistrationList.enrolment.notInterested";
    case EnrolmentStatuses.CallBack:
      return "programRegistrationList.enrolment.callBack";
    case EnrolmentStatuses.LeftMessage:
      return "programRegistrationList.enrolment.leftMessage";
    case EnrolmentStatuses.BusyUnableToTalk:
      return "programRegistrationList.enrolment.busy";
    case EnrolmentStatuses.Enrolled:
      return "programRegistrationList.enrolment.enrolled";
    default:
      return "general.unknown";
  }
};

export const getEnrolmentFilterTypes = ({
  includeAll
}: {
  includeAll?: boolean;
}): SelectOption<number>[] => {
  const types: SelectOption<number>[] = [0, 1, 2, 3, 4, 5, 6, 7].map(
    (index) => ({
      value: index,
      text: getEnrolmentStatusText(index)
    })
  );
  if (includeAll) {
    types.unshift({ value: -1, text: "All" });
  }

  return types;
};
export const getCallConsentTypes = (includeAll?: boolean) => {
  const types = [0, 1, 2, 3].map((index) => ({
    value: index,
    text: getCallConsentStatusText(index)
  }));
  if (includeAll) {
    types.unshift({ value: parseInt(DEFAULT_FILTER_VALUE), text: "All" });
  }
  return types;
};
export const getSmsFilterTypes = (
  includeAll?: boolean
): SelectOption<number>[] => {
  const array = [
    { value: 0, text: t("programRegistrationList.sms.notSent") },
    { value: 1, text: t("programRegistrationList.sms.ok") },
    { value: 2, text: t("programRegistrationList.sms.error") }
  ];
  if (includeAll) {
    array.unshift({ value: parseInt(DEFAULT_FILTER_VALUE), text: "All" });
  }
  return array;
};
export const getHipaaCertifiedTypes = (
  includeAll?: boolean
): SelectOption<number>[] => {
  const types: { value: number; text: string }[] = [
    {
      value: HipaaStatus.Unknown,
      text: "general.unknown"
    },
    {
      value: HipaaStatus.No,
      text: "programRegistrationList.hipaaVerifified.no"
    },
    {
      value: HipaaStatus.Yes,
      text: "programRegistrationList.hipaaVerifified.yes"
    }
  ];
  if (includeAll) {
    types.unshift({ value: parseInt(DEFAULT_FILTER_VALUE), text: "All" });
  }
  return types;
};
export const getHipaaCertifiedStatusText = (status: HipaaStatus) => {
  switch (status) {
    case HipaaStatus.Yes:
      return "programRegistrationList.hipaaVerifified.yes";
    case HipaaStatus.No:
      return "programRegistrationList.hipaaVerifified.no";
    default:
      return "general.unknown";
  }
};
export const getCallRecordingDisclaimerTypes = (
  includeAll?: boolean
): SelectOption<number>[] => {
  const types: SelectOption<number>[] = [
    {
      value: 0,
      text: "general.unknown"
    },
    { value: 2, text: "programRegistrationList.callrecording.notprovided" },
    { value: 1, text: "programRegistrationList.callrecording.provided" }
  ];
  if (includeAll) {
    types.unshift({ value: parseInt(DEFAULT_FILTER_VALUE), text: "All" });
  }
  return types;
};

export const getLineOfBusinessOptionsForUsers = (
  externalUsers: ExternalUser[]
): SelectOption[] => {
  const options = Array.from(
    new Set(
      externalUsers
        .filter((user) => Boolean(user.lineOfBusiness?.trim()))
        .map((user) => user.lineOfBusiness ?? "")
    )
  ).map((value) => ({
    value,
    text: value
  }));

  return options;
};

export const getOutreachTypeOptions = () => [
  { value: "CALL", text: "Call" },
  { value: "TEXT", text: "Text" }
];

export const getOutreachTypeOptionsForUsers = (
  externalUsers: ExternalUser[]
) => {
  const options = Array.from(
    new Set(
      externalUsers
        .filter((user) => Boolean(user.outreachType?.trim()))
        .map((user) => user.outreachType ?? "")
    )
  ).map((value) => ({
    value,
    text: value
  }));

  return options;
};

export const getBrandOptionsForUsers = (
  externalUsers: ExternalUser[]
): SelectOption[] => {
  const options = Array.from(
    new Set(
      externalUsers
        .filter((user) => Boolean(user.brand?.trim()))
        .map((user) => user.brand ?? "")
    )
  ).map((value) => ({
    value,
    text: value
  }));

  return options;
};

export const getCallRecordingDisclaimerStatusText = (status: number) => {
  switch (status) {
    case 1:
      return "programRegistrationList.callrecording.provided";
    case 2:
      return "programRegistrationList.callrecording.notprovided";
    default:
      return "general.unknown";
  }
};

export const getRelationshipText = (value: string) => {
  // Relationship can only be one of the following values: Self, Dependent (case insensitive)
  // If the value is not one of the above, then it is displayed as an empty string ("")
  switch (value.trim().toLowerCase()) {
    case "self":
      return t("registerPatient.self");
    case "dependent":
      return t("registerPatient.dependent");
    default:
      return "";
  }
};

export const getConditionsText = (user: ExternalUser, program?: Program) => {
  if (!user.conditions) return "";
  const conditions = user.conditions.split(";");
  return program?.variablesSelectableOnInvite
    ?.filter((variable) => conditions.includes(variable.name))
    .map((variable) => variable.title ?? variable.name)
    .sort()
    .join(", ");
};

export const getCountryByCountryCode = (
  countryCode: string,
  preferredCountry?: string
) => {
  if (preferredCountry === "USA") {
    preferredCountry = "US"; // i noticed that United states is set as USA in admin portal
  }
  // find a countryCode that matches the phonenumber, defaulting to preferredCountry
  // since for example US and canada have the same country code
  let code = countries.find(
    (code) => countryCode === code.dial_code && preferredCountry === code.code
  );
  // if anything is found return it
  if (code) {
    return code;
  } else {
    // else try searching without the default
    code = countries.find((code) => code.dial_code === countryCode);
  }
  if (code) {
    return code;
  } else {
    // if nothing found just return the default (some data on dev atleast has invalid phonenumbers)
    return null;
  }
};

export const getUnderlyingRiskTypes = () => {
  return [
    {
      value: "1",
      text: "programRegistrationList.underlyingRisk.low"
    },
    { value: "2", text: "programRegistrationList.underlyingRisk.medium" },
    { value: "3", text: "programRegistrationList.underlyingRisk.high" }
  ];
};

export const memberStateFilterOptions = (users: ExternalUser[]) => {
  const usStates = states.map((state) => ({
    value: state.code,
    text: state.name
  }));

  const filteredStates = usStates.filter((state) =>
    users.some((user) => user.memberState === state.value)
  );

  const usersHaveIncorrectState = users.some(
    (user) =>
      user.memberState &&
      !usStates.some((state) => state.value === user.memberState)
  );

  if (usersHaveIncorrectState) {
    filteredStates.unshift({ value: INCORRECT_OPTION, text: "Incorrect" });
  }

  return filteredStates;
};

export const planStateFilterOptions = (users: ExternalUser[]) => {
  const usStates = states.map((state) => ({
    value: state.code,
    text: state.name
  }));

  const filteredStates = usStates.filter((state) =>
    users.some((user) => user.planState === state.value)
  );

  const usersHaveIncorrectState = users.some(
    (user) =>
      user.planState &&
      !usStates.some((state) => state.value === user.planState)
  );

  if (usersHaveIncorrectState) {
    filteredStates.unshift({ value: INCORRECT_OPTION, text: "Incorrect" });
  }
  return filteredStates;
};

export const getRetriggerOptions = (includeAll?: boolean) => {
  const options = [
    { value: "1", text: t("general.yes") },
    { value: "0", text: t("general.no") }
  ];
  if (includeAll) {
    options.unshift({ value: DEFAULT_FILTER_VALUE, text: "All" });
  }
  return options;
};

export const smsFilterMatchesStatus = (
  smsStatus: number,
  smsFilter: number
) => {
  switch (smsFilter) {
    case 0:
      return smsStatus === SMSStatus.NotSent;
    case 1:
      return isSmsStatusOk(smsStatus);
    case 2:
      return isSmsStatusError(smsStatus);
    default:
      return false;
  }
};
export const isSmsStatusOk = (smsStatus: number) => {
  return TextMessageOkStatuses.includes(smsStatus);
};

export const isSmsStatusError = (smsStatus: number) => {
  return TextMessageErrorStatuses.includes(smsStatus);
};

export const filterUserByGroupNumber = (
  groupNumber?: string,
  groupNumbers?: string[]
): boolean => {
  if (!groupNumbers || groupNumbers.length === 0) return true;

  if (groupNumber) {
    return groupNumbers?.includes(groupNumber) ?? false;
  }

  return false;
};

export const filterUserByGroupName = (
  groupName?: string,
  groupNames?: string[]
): boolean => {
  if (!groupNames || groupNames.length === 0) return true;

  if (groupName) {
    return groupNames?.includes(groupName) ?? false;
  }

  return false;
};

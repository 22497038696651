import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./BloodSugarGraph.module.scss";

import {
  getAnalyzedBloodSugarData,
  getCellData,
  mapBloodSugarResponse
} from "../../../../../helpers/vitals/bloodSugarHelpers";

import { getBloodSugarData } from "~/api/requests/scoreRequests";
import ButtonLink from "~/components/buttonLink/ButtonLink";
import ChartRating from "~/components/charts/ChartRating";
import CustomLineChart from "~/components/charts/CustomLineChart";
import DateRangeNavigator from "~/components/dateRangeNavigator/DateRangeNavigator";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import { getLineData } from "~/helpers/vitals/vitalsHelpers";
import { t } from "~/i18n";
import colors from "~/styles/colors";
import { BloodSugarLineData, UserURLParams } from "~/typing/carePortalTypes";
import { BloodSugarData } from "~/typing/sidekickTypes";

type BloodSugarGraphProps = {
  useMetric: boolean;
  type: string;
  latestLogDate?: string;
};

const DEFAULT_DAYS_TO_DISPLAY = 7;
//Details view should display the last 30 logs for the past 2 years
const DETAILS_DAYS_TO_DISPLAY = 730;
const DETAILS_LOGS_TO_DISPLAY = 30;

const BloodSugarGraph = ({
  useMetric,
  type,
  latestLogDate
}: BloodSugarGraphProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bloodSugarData, setBloodSugarData] = useState<BloodSugarData[]>([]);
  const latestDate = latestLogDate
    ? DateTime.fromISO(latestLogDate)
    : DateTime.now();
  const [endDate, setEndDate] = useState(latestDate);
  const [daysToDisplay, setDaysToDisplay] = useState(DEFAULT_DAYS_TO_DISPLAY);

  const {
    user_id = "",
    program_id = "",
    locale = ""
  } = useParams<UserURLParams>();

  const isDetailsView = () => {
    return daysToDisplay === DETAILS_DAYS_TO_DISPLAY;
  };

  const getAndSetData = async () => {
    setIsLoading(true);
    const bloodSugarResponse = await getBloodSugarData({
      userId: user_id,
      locale,
      programId: program_id,
      startDate: isDetailsView()
        ? DateTime.now().minus({ days: daysToDisplay - 1 })
        : endDate.minus({ days: daysToDisplay - 1 }),
      endDate: isDetailsView() ? DateTime.now() : endDate,
      pageSize: isDetailsView() ? DETAILS_LOGS_TO_DISPLAY : undefined,
      type: type
    });
    const mappedData = mapBloodSugarResponse(
      bloodSugarResponse,
      useMetric,
      type
    );
    setBloodSugarData(mappedData);
    setIsLoading(false);
  };

  useEffect(() => {
    getAndSetData();
  }, [endDate, daysToDisplay]);

  useEffect(() => {
    setEndDate(latestDate);
  }, [type]);

  const lineData = getLineData(bloodSugarData);

  const analyzedData = getAnalyzedBloodSugarData(lineData);

  const highestValue = lineData.reduce(
    (prev, curr) => (curr.value > prev ? curr.value : prev),
    0
  );

  const lowestValue = lineData.reduce(
    (prev, curr) => (curr.value < prev ? curr.value : prev),
    1000
  );

  const getAxisDomain = () => {
    let upper;
    let lower;
    if (!useMetric && type === "hba1c") {
      upper = Math.round(highestValue) + 1;
      lower = Math.round(lowestValue) - 1;
    } else {
      upper = Math.round(highestValue) + 20;
      lower = Math.round(lowestValue) - 20;
    }

    return [lower > 0 ? lower : 0, upper];
  };

  const getUnits = () => {
    if (type === "hba1c" && !useMetric) {
      return t("units.percentage");
    } else if (type === "hba1c" && useMetric) {
      return t("units.mmolMol");
    } else {
      return t("units.mgDl");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.infoWrapper}>
          {analyzedData.map((info, index) => (
            <ChartRating
              key={`${info.title}-${index}`}
              title={info.title}
              cellTitle={info.cellTitle}
              cellColor={info.cellColor}
              value={info.value}
              unit={getUnits()}
              isLoading={isLoading}
            />
          ))}
        </div>
        <div className={styles.navWrapper}>
          <ToggleSwitch
            switchData={[
              {
                label: "7d",
                value: 7
              },
              {
                label: "30d",
                value: 30
              },
              {
                label: t("time.detail"),
                value: DETAILS_DAYS_TO_DISPLAY
              }
            ]}
            setValue={(value) => setDaysToDisplay(value)}
            currentValue={daysToDisplay}
            className={styles.switch}
            useLinks={true}
          />
          {isDetailsView() ? (
            <div className={styles.last30Link}>
              <ButtonLink
                active={isDetailsView()}
                title={t("time.last30Logs")}
                onClick={() => undefined}
              />
            </div>
          ) : (
            <DateRangeNavigator
              className={styles.navigator}
              increaseDate={() =>
                setEndDate((prev) => prev.plus({ days: daysToDisplay }))
              }
              decreaseDate={() =>
                setEndDate((prev) => prev.minus({ days: daysToDisplay }))
              }
              startDate={
                endDate.minus({ days: daysToDisplay - 1 }).toISO() ?? ""
              }
              endDate={endDate.toISO() ?? ""}
              onTodayClick={() => setEndDate(latestDate)}
              todayLabel={t("time.latest")}
            />
          )}
        </div>
      </div>

      <CustomLineChart<BloodSugarLineData>
        lineData={lineData}
        height={400}
        YAxisDomain={getAxisDomain()}
        lines={[
          {
            name: `${t("user.health.bloodSugar.bloodSugar")}`,
            dataKey: "value",
            lineColor: colors.darkBlue140
          }
        ]}
        units={getUnits()}
        getCellData={getCellData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default BloodSugarGraph;

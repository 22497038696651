import { useMutation } from "@tanstack/react-query";

import { approveAllSurveyResults } from "~/api/graphql/surveys";
import { ApproveAllSurveyResultsDtoInput } from "~/typing/graphql/types";

export const useApproveAllSurveyResults = () => {
  const {
    mutate,
    mutateAsync,
    isPending,
    isSuccess,
    isError,
    error
  } = useMutation({
    mutationKey: ["approveAllSurveyResults"],
    mutationFn: (props: ApproveAllSurveyResultsDtoInput) =>
      approveAllSurveyResults(props)
  });

  return {
    approveAllSurveyResultsAsync: mutateAsync,
    approveAllSurveyResults: mutate,
    isPending,
    isSuccess,
    isError,
    error
  };
};

import { useEffect, useState } from "react";

import styles from "./ImportModal.module.scss";

import UploadDocument from "../user/documents/UploadDocument";

import {
  getImportableExternalUserType,
  uploadExternalUsers
} from "~/api/requests/externalUserRequests";
import Input from "~/components/input/Input";
import Modal from "~/components/modal/Modal";
import CustomSelect from "~/components/select/CustomSelect";
import { FileUploadType } from "~/constants/fileUploadTypes";
import { t } from "~/i18n";
import { ExternalUserType } from "~/typing/sidekickTypes";

type ImportInfoModalProps = {
  close: () => void;
  updateImportEntries: () => void;
};

const ImportModal = ({ close, updateImportEntries }: ImportInfoModalProps) => {
  const [uploadToken, setUploadToken] = useState("");
  const [uploadName, setUploadName] = useState("");
  const [externalUserTypes, setExternalUserTypes] = useState<
    ExternalUserType[]
  >([]);
  const [selectedUserTypeName, setSelectedUserTypeName] = useState<
    string | undefined
  >();

  useEffect(() => {
    const getExternalUserTypes = async () => {
      const importableUserTypes = await getImportableExternalUserType();
      setExternalUserTypes(importableUserTypes);
    };
    getExternalUserTypes();
  }, []);

  const handleDocumentUpload = async ({
    encodedFile
  }: {
    encodedFile: string;
  }) => {
    const response = await uploadExternalUsers({
      externalUserTypeName: selectedUserTypeName ?? "",
      uploadToken,
      encodedFile,
      fileUploadType: FileUploadType.AnthemImport,
      uploadName
    });

    if (response?.id && response.status) {
      updateImportEntries();
      close();
    }
  };

  return (
    <Modal
      title={t("importExport.importModal.newImport", "New import")}
      onClose={close}
      className={styles.modal}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <Input
            label="Upload token"
            onChange={setUploadToken}
            placeholder={"Upload token"}
          />
          <Input
            label="Upload name"
            onChange={setUploadName}
            placeholder={"Upload name"}
          />
          <CustomSelect
            label="Programs"
            options={externalUserTypes}
            valueKey="name"
            value={selectedUserTypeName}
            onChange={(event) => {
              setSelectedUserTypeName(event.target.value);
            }}
            renderOption={(option: ExternalUserType) =>
              `${option?.title} - ${option?.name}`
            }
            wrapperClassName={styles.select}
          />
        </div>
        <p className={styles.description}>
          Click browse to upload your csv file, alternatively you can export
          users using the export button.
        </p>
        <UploadDocument
          buttonText={t("general.confirm", "Confirm")}
          onUpload={handleDocumentUpload}
          acceptedFiles={"text/csv"}
          buttonDisabled={!uploadToken || !selectedUserTypeName}
        />
      </div>
    </Modal>
  );
};

export default ImportModal;

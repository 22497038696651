export const QueryKeyNames = {
  isFeatureEnabled: "isFeatureEnabled",
  userDetail: "userDetail",
  baseWeight: "baseWeight",
  physicalActivity: "physicalActivity",
  pinnedNotes: "pinnedNotes",
  notes: "notes",
  documents: "documents",
  communicationPreferences: "communicationPreferences",
  surveyResults: "surveyResults",
  suggestedMessages: "suggestedMessages",
  missionSummary: "missionSummary",
  settings: "settings",
  lastWeekPoints: "lastWeekPoints",
  program: "program",
  programs: "programs",
  invites: "invites",
  surveys: "surveys",
  specialties: "specialties",
  scoreMeals: "scoreMeals",
  users: "users",
  missions: "missions",
  importEntries: "importEntries",
  programLocales: "programLocales",
  carePriorities: "carePriorities",
  userContextInfo: "userContextInfo",
  careManagerTasks: "careManagerTasks",
  fallbackMessage: "fallbackMessage",
  translations: "translations",
  markMessageSuggestionAsSent: "markMessageSuggestionAsSent",
  userSymptomsLoggedMetadata: "userSymptomsLoggedMetadata",
  reportMessage: "reportMessage"
};

export const QueryKeyFactory = {
  segmentation: (featureName: string, userId?: string) => [
    QueryKeyNames.isFeatureEnabled,
    featureName,
    userId
  ],
  externalUsers: {
    user: (externalUserType: string, externalUserId: string) => [
      "externalUser",
      externalUserType,
      externalUserId
    ]
  },
  users: {
    messages: (programId: string, locale: string, userId: string) => [
      "messages",
      programId,
      locale,
      userId
    ],
    sms: (
      programId: string,
      locale: string,
      externalUserType: string,
      externalUserId: string
    ) => ["sms", programId, locale, externalUserType, externalUserId],
    detail: (programId: string, locale: string, userId: string) => [
      QueryKeyNames.userDetail,
      programId,
      locale,
      userId
    ],
    baseWeight: (programId: string, locale: string, userId: string) => [
      QueryKeyNames.baseWeight,
      programId,
      locale,
      userId
    ],
    physicalActivity: (
      programId: string,
      locale: string,
      userId: string,
      startDate: string,
      endDate: string
    ) => [
      QueryKeyNames.physicalActivity,
      programId,
      locale,
      userId,
      startDate,
      endDate
    ],
    pinnedNotes: (userId: string) => [QueryKeyNames.pinnedNotes, userId],
    notes: (userId: string) => [QueryKeyNames.notes, userId],
    documents: (userId: string) => [QueryKeyNames.documents, userId],
    communicationPreferences: (userId: string) => [
      QueryKeyNames.communicationPreferences,
      userId
    ],
    surveyResults: (userId: string, surveyId: string) => [
      QueryKeyNames.surveyResults,
      userId,
      surveyId
    ],
    suggestedMessages: (
      programId: string,
      locale: string,
      userId: string,
      messageType?: string
    ) => [
      QueryKeyNames.suggestedMessages,
      programId,
      locale,
      userId,
      messageType
    ],
    missionSummary: (
      userId: string,
      programCatalogItemId: string,
      locale: string,
      startDate: string
    ) => [
      QueryKeyNames.missionSummary,
      programCatalogItemId,
      locale,
      startDate,
      userId
    ],
    missionScores: (
      userId: string,
      missionId: string,
      programCatalogItemId: string,
      locale: string
    ) => ["missionScores", programCatalogItemId, locale, userId, missionId],
    settings: (userId: string) => ["settings", userId],
    scoreMeals: (programId: string, locale: string, userId: string) => [
      QueryKeyNames.scoreMeals,
      programId,
      locale,
      userId
    ],
    lastWeekPoints: (programId: string, locale: string, userId: string) => [
      QueryKeyNames.lastWeekPoints,
      programId,
      locale,
      userId
    ],
    coachesForUser: (programId: string, locale: string, userId: string) => [
      "coachesForUser",
      programId,
      locale,
      userId
    ],
    carePriorities: (userId: string) => [QueryKeyNames.carePriorities, userId],
    userContextInfo: (userId: string) => [
      QueryKeyNames.userContextInfo,
      userId
    ],
    careManagerTasks: (userId: string) => [
      QueryKeyNames.careManagerTasks,
      userId
    ],
    fallbackMessage: (locale: string, userId: string) => [
      QueryKeyNames.fallbackMessage,
      locale,
      userId
    ],
    markMessageSuggestionAsSent: () => [
      QueryKeyNames.markMessageSuggestionAsSent
    ],
    userSymptomsLoggedMetadata: (userId: string) => [
      QueryKeyNames.userSymptomsLoggedMetadata,
      userId
    ],
    reportMessage: () => [QueryKeyNames.reportMessage]
  },
  specialties: {
    all: [QueryKeyNames.specialties],
    authenticatedUser: (userId: string) => ["specialties", "me", userId]
  },
  translations: (
    userId: string,
    translationType: string,
    id: string,
    index: string
  ) => [QueryKeyNames.translations, userId, translationType, id, index],
  programs: {
    all: [QueryKeyNames.programs],
    single: (programCatalogItemId: string, locale: string) => [
      QueryKeyNames.program,
      programCatalogItemId,
      locale
    ],
    invites: (programCatalogItemId: string, locale: string) => [
      QueryKeyNames.invites,
      programCatalogItemId,
      locale
    ],
    surveys: (programCatalogItemId: string, locale: string) => [
      QueryKeyNames.surveys,
      programCatalogItemId,
      locale
    ],
    specialties: (programCatalogItemId: string, locale: string) => [
      QueryKeyNames.specialties,
      programCatalogItemId,
      locale
    ],
    users: (programCatalogItemId: string, locale: string) => [
      QueryKeyNames.users,
      programCatalogItemId,
      locale
    ],
    programLocales: (programCatalogItemId: string) => [
      QueryKeyNames.programLocales,
      programCatalogItemId
    ]
  },
  missions: ["missions"],
  importEntries: ["importEntries"],
  nextRecommendedUser: ["nextRecommendedUser"],
  userGroups: (skipTerminatedClients: boolean) => [
    "userGroups",
    skipTerminatedClients
  ]
};

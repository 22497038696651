import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./GroupsOverview.module.scss";

import api from "~/api/api";
import CustomList, {
  CustomListColumn
} from "~/components/customList/CustomList";
import Layout from "~/components/layout/Layout";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { getDateAndTimeString } from "~/helpers/date/dateHelpers";
import { isLeadCoach } from "~/helpers/navigation/navigationHelpers";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import useUser from "~/hooks/useUser";
import { t } from "~/i18n";
import { ExternalUserGroupOutput } from "~/typing/sidekickTypes";

const GroupsOverview = () => {
  const { user } = useUser();

  const groupsUploader = user && isLeadCoach(user);
  const [groupOutputs, setGroupOutputs] = useState<ExternalUserGroupOutput[]>();
  const { file_upload_id = "latest" } = useParams<{ file_upload_id: string }>();
  const isLatest = file_upload_id === "latest";
  const navigate = useNavigate();
  const fetchGroups = async () => {
    await api
      .get<{ items: ExternalUserGroupOutput[] }>(
        `/coach/externalusergroups/${file_upload_id}`
      )
      .then((res) => {
        setGroupOutputs(res?.data?.items);
      })
      .catch(() =>
        displayErrorToast({
          message: t("groups.error.notFound") + file_upload_id
        })
      );
  };
  useEffect(() => {
    fetchGroups();
  }, []);
  const fileUploadId = groupOutputs?.at(0)?.fileUploadId;
  const createdDate = getDateAndTimeString(
    groupOutputs?.at(0)?.createdDate || ""
  );
  const approvedDate = getDateAndTimeString(
    groupOutputs?.at(0)?.approvedDate || ""
  );
  const renderBoolean = (b: boolean) =>
    b ? t("general.yes") : t("general.no");
  const groupColumns: CustomListColumn<ExternalUserGroupOutput>[] = [
    {
      heading: {
        text: t("groups.column.id"),
        tooltip: {
          text: t("groups.tooltip.id")
        }
      },
      render: ({ id }) => id,
      conditional: false
    },
    {
      heading: {
        text: t("groups.column.fileUploadId"),
        tooltip: {
          text: t("groups.tooltip.fileUploadId")
        }
      },
      render: ({ fileUploadId }) => fileUploadId,
      conditional: false
    },
    {
      heading: {
        text: t("groups.column.createdDate"),
        tooltip: {
          text: t("groups.tooltip.createdDate")
        }
      },
      render: ({ createdDate }) => getDateAndTimeString(createdDate || ""),
      conditional: false
    },
    {
      heading: {
        text: t("groups.column.createdByUserId"),
        tooltip: {
          text: t("groups.tooltip.createdByUserId")
        }
      },
      render: ({ createdByUserId }) => createdByUserId,
      conditional: false
    },
    {
      heading: {
        text: t("groups.column.approvedDate"),
        tooltip: {
          text: t("groups.tooltip.approvedDate")
        }
      },
      render: ({ approvedDate }) => getDateAndTimeString(approvedDate || ""),
      conditional: false
    },
    {
      heading: {
        text: t("groups.column.approvedByUserId"),
        tooltip: {
          text: t("groups.tooltip.approvedByUserId")
        }
      },
      render: ({ approvedByUserId }) => approvedByUserId,
      conditional: false
    },
    {
      heading: {
        text: t("groups.column.name"),
        tooltip: {
          text: t("groups.tooltip.name")
        }
      },
      render: ({ name }) => name
    },
    {
      heading: {
        text: t("groups.column.shortName"),
        tooltip: {
          text: t("groups.tooltip.shortName")
        }
      },
      render: ({ shortName }) => shortName
    },
    {
      heading: {
        text: t("groups.column.number"),
        tooltip: {
          text: t("groups.tooltip.number")
        }
      },
      render: ({ number }) => number
    },
    {
      heading: {
        text: t("groups.column.ibcJV"),
        tooltip: {
          text: t("groups.tooltip.ibcJV")
        }
      },
      render: ({ ibcJV }) => renderBoolean(ibcJV)
    },
    {
      heading: {
        text: t("groups.column.careFirst"),
        tooltip: {
          text: t("groups.tooltip.careFirst")
        }
      },
      render: ({ careFirst }) => renderBoolean(careFirst)
    },
    {
      heading: {
        text: t("groups.column.clientEffectiveDate"),
        tooltip: {
          text: t("groups.tooltip.clientEffectiveDate")
        }
      },
      render: ({ clientEffectiveDate }) =>
        clientEffectiveDate
          ? getDateAndTimeString(clientEffectiveDate || "")
          : ""
    },
    {
      heading: {
        text: t("groups.column.fullOffshoreRestrictions"),
        tooltip: {
          text: t("groups.tooltip.fullOffshoreRestrictions")
        }
      },
      render: ({ fullOffshoreRestrictions }) =>
        renderBoolean(fullOffshoreRestrictions)
    },
    {
      heading: {
        text: t("groups.column.memberFacingOffshoreRestrictions"),
        tooltip: {
          text: t("groups.tooltip.memberFacingOffshoreRestrictions")
        }
      },
      render: ({ memberFacingOffshoreRestrictions }) =>
        renderBoolean(memberFacingOffshoreRestrictions)
    },
    {
      heading: {
        text: t("groups.column.clientTerminationDate"),
        tooltip: {
          text: t("groups.tooltip.clientTerminationDate")
        }
      },
      render: ({ clientTerminationDate }) =>
        clientTerminationDate ? getDateAndTimeString(clientTerminationDate) : ""
    }
  ];

  async function handleApprove(): Promise<void> {
    await api
      .post(`/coach/externalusergroups/${file_upload_id}/approve`, undefined)
      .then(() => {
        displaySuccessToast({ message: t("groups.success.approved") });
        navigate("/programs");
      })
      .catch(() =>
        displayErrorToast({ message: t("groups.error.approveFailed") })
      );
  }

  const actionItems = groupsUploader
    ? [
        {
          text: t("groups.actions.upload_new"),
          onClick: () => {
            navigate("/groups/upload");
          },
          primary: false
        },
        isLatest
          ? undefined
          : {
              text: t("groups.actions.approve"),
              onClick: () => handleApprove(),
              primary: true
            }
      ]
    : [];

  return (
    <SentryErrorBoundary transactionName="GroupsUploadOverview">
      <Layout
        title={
          isLatest ? t("groups.overview.active") : t("groups.overview.uploaded")
        }
        actionItems={actionItems}
      >
        <div className={styles.header}>
          <span>
            <b>{t("groups.header.fileUploadId")}:</b>
            {fileUploadId}
          </span>
          <span>
            <b>{t("groups.header.created")}:</b>
            {createdDate}
          </span>
          {isLatest && (
            <span>
              {" "}
              <b>{t("groups.header.approved")}:</b>
              {approvedDate}
            </span>
          )}
        </div>

        <div className="wrapper">
          <div className={styles.content}>
            <CustomList
              isLoading={!groupOutputs}
              columns={groupColumns}
              entities={groupOutputs || []}
              customListUniqueId={"elevance-medicare-group-page"}
              pagination={{
                entitiesPerPage: 8
              }}
            ></CustomList>
          </div>
        </div>
      </Layout>
    </SentryErrorBoundary>
  );
};

export default GroupsOverview;

import classNames from "classnames";
import { useEffect } from "react";
import { Trans } from "react-i18next";

import BehavioralPRO from "./behavioralPro/BehavioralPRO";
import {
  getCarePriorityMessage,
  getCarePriorityMessageType
} from "./CarePriorities.helpers";
import styles from "./CarePriority.module.scss";
import ProgramProgression from "./programProgression/ProgramProgression";
import SurveyResult from "./surveyResult/SurveyResult";
import SymptomHighlightPanel from "./symptomHighlight/SymptomHighlightPanel";

import ActivityPanel from "../components/ActivityPanel";

import Chevron from "~/assets/svgComponents/Chevron";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import { MessageSuggestionWrapper } from "~/components/messageSuggestion/MessageSuggestionWrapper";
import config from "~/config";
import { convertEnumToTitleCase } from "~/helpers/string/stringHelpers";
import { useChildrenTabIndexToggle } from "~/hooks/useChildrenTabIndexToggle";
import { CarePriorityType, UserContextInfo } from "~/typing/graphql/types";
import { CarePriorityData } from "~/typing/sidekickTypes";

export type CarePriorityProps = {
  carePriorityData: CarePriorityData;
  userContext?: UserContextInfo;
  userId: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
  completed?: boolean;
  expanded?: boolean;
  onClick?: () => void;
};

const cx = classNames.bind(styles);

const CarePriority = ({
  carePriorityData,
  userContext,
  userId,
  checked,
  onChange,
  completed,
  expanded,
  onClick
}: CarePriorityProps) => {
  const handleCheckboxClick = () => {
    onChange(!checked);
  };

  const { parentRef } = useChildrenTabIndexToggle({
    childrenHidden: !expanded
  });

  useEffect(() => {
    if (expanded) {
      parentRef.current?.scrollTo({
        behavior: "instant",
        top: 0
      });
    }
  }, [expanded]);

  const messageSuggestionTypeToFetch = getCarePriorityMessageType(
    carePriorityData.type
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={cx({
          [styles.header]: true,
          [styles.expanded]: expanded
        })}
      >
        <CheckboxInput
          checked={checked || completed || false}
          onChange={handleCheckboxClick}
          label={convertEnumToTitleCase(carePriorityData.type)}
          className={styles.checkbox}
          disabled={completed || false}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCheckboxClick();
            }
          }}
        />
        <button
          className={cx({
            [styles.toggle]: true,
            [styles.rotate]: expanded,
            [styles.completed]: completed
          })}
          tabIndex={0}
          onClick={onClick}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClick?.();
            }
          }}
        >
          <Chevron direction={"down"} />
        </button>
      </div>
      <div
        className={cx({
          [styles.content]: true,
          [styles.contentHidden]: !expanded
        })}
        ref={parentRef}
      >
        <Trans components={{ strong: <strong /> }}>
          <p>
            {getCarePriorityMessage({
              data: carePriorityData,
              userName: userContext?.userName ?? ""
            })}
          </p>
        </Trans>
        {carePriorityData.type ===
          CarePriorityType.BehaviouralSurveyFeedback && (
          <BehavioralPRO
            behavioralData={carePriorityData.metadata}
            userId={userId}
          />
        )}
        {carePriorityData.type === CarePriorityType.SymptomLogFeedback && (
          <SymptomHighlightPanel userId={userId} />
        )}
        {carePriorityData.type === CarePriorityType.ProgramProgression && (
          <ProgramProgression
            userId={userId}
            metadata={carePriorityData.metadata}
          />
        )}
        {carePriorityData.type === CarePriorityType.SurveyResultCreated && (
          <SurveyResult
            userId={userId}
            metadata={carePriorityData.metadata}
            programId={userContext?.programCatalogItemId}
            locale={userContext?.locale ?? ""}
            userName={userContext?.userName ?? ""}
          />
        )}
        {messageSuggestionTypeToFetch && config.showMessageSuggestions && (
          <MessageSuggestionWrapper
            messageType={messageSuggestionTypeToFetch}
            locale={userContext?.locale ?? ""}
            numMessages={3}
            programId={userContext?.programCatalogItemId}
            userId={userId}
          />
        )}
        <ActivityPanel userContextInfo={userContext} />
      </div>
    </div>
  );
};

export default CarePriority;

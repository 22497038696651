import {
  MessageFilterOption,
  CoachAssignedValue
} from "./filters/filterOptions";

import { INCORRECT_OPTION } from "../registration/helper";

import { DEFAULT_FILTER_VALUE, NOT_SUBMITTED } from "~/constants/filters";
import { TimeInMs } from "~/constants/measurements";
import { UNDEFINED_OPTION } from "~/constants/options";
import { ProgramBehaviour } from "~/constants/programBehaviours";
import { SentimentThreshold } from "~/constants/sentiment";
import states from "~/constants/states";
import getLatestSurvey from "~/helpers/getLatestSurvey";
import {
  getSurveyByName,
  getSurveyRating
} from "~/helpers/surveys/surveyHelpers";
import {
  getRetriggeredValue,
  hasBeenDischargedFromProgram,
  hasFinishedProgram,
  hasQuitOrFinished,
  hasQuitProgram,
  isInMaintenance,
  isPausedInProgram
} from "~/helpers/user/userHelpers";
import colors from "~/styles/colors";
import { CoachOptionUserList } from "~/typing/carePortalTypes";
import {
  CoachUser,
  Program,
  ProgramSpecialty,
  Survey,
  SurveyResult
} from "~/typing/sidekickTypes";

export const getCoachFilterDefaultValue = (
  groupCoaches: CoachOptionUserList[],
  users: Partial<CoachUser>[],
  programSpecialties: Partial<ProgramSpecialty>[] | undefined
) => {
  if (groupCoaches.length === 0) {
    return undefined;
  }

  const isAssigned = (coach: CoachOptionUserList) =>
    programSpecialties?.length
      ? users.some((user) =>
          user.coaches?.some((c) => c.coachUserId === coach.userId)
        )
      : users.some((user) => user.assignedCoachUserId === coach.userId);

  const me = groupCoaches.find((coach) => coach.isMe);

  if (me && isAssigned(me)) {
    return me.userId;
  }

  return DEFAULT_FILTER_VALUE;
};

enum UserStatusText {
  Active = "Active",
  New = "New",
  Discharged = "Discharged",
  Maintenance = "Maintenance",
  Inactive7Days = "Inactive 7 Days",
  Inactive14Days = "Inactive 14 Days",
  Inactive30Days = "Inactive 30 Days",
  Restarted = "Restarted",
  Removed = "Removed",
  Quit = "Quit",
  Finished = "Finished",
  Pause = "Paused",
  Scale14Day = "Scale inactive for 14 - 20 days",
  Scale21Day = "Scale inactive for 21+ days"
}

export enum UserStatus {
  Active = "active",
  New = "new",
  Discharged = "discharged",
  Maintenance = "maintenance",
  Inactive7Days = "inactive-7-day",
  Inactive14Days = "inactive-14-day",
  Inactive30Days = "inactive-30-day",
  Restarted = "restarted",
  Removed = "removed",
  Quit = "quit",
  Finished = "finished",
  Pause = "paused",
  Scale14Day = "scale-inactive-14-day",
  Scale21Day = "scale-inactive-21-day"
}

export const getUserStatuses = (user: CoachUser, program?: Program) => {
  const now = Date.now();
  const lastActiveDateOrJoined = user.lastActiveDate
    ? new Date(user.lastActiveDate)
    : new Date(user.joinedDate);
  const lastActiveDate = user.lastActiveDate
    ? new Date(user.lastActiveDate)
    : undefined;

  const inactiveDays = (now - lastActiveDateOrJoined.getTime()) / TimeInMs.Day;
  const joinedDate = new Date(user.joinedDate);
  const latestScaleDate = user.latestScaleDate
    ? new Date(user.latestScaleDate)
    : undefined;
  const usingBodyTraceScale =
    program?.behaviour &&
    program.behaviour & ProgramBehaviour.UsingBodyTraceScale;

  const statuses: {
    text: UserStatusText;
    value: string;
    fontColor?: string;
    color: string;
  }[] = [];

  if (isPausedInProgram(user.programStatus)) {
    statuses.push({
      color: colors.navyBlue30,
      text: UserStatusText.Pause,
      value: UserStatus.Pause
    });
  }

  if (hasQuitProgram(user.programStatus, user.userProgramStatusReason)) {
    statuses.push({
      color: colors.navyBlue10,
      text: UserStatusText.Quit,
      value: UserStatus.Quit
    });
    return statuses;
  }

  if (hasFinishedProgram(user.programStatus, user.userProgramStatusReason)) {
    statuses.push({
      color: colors.navyBlue60,
      fontColor: colors.white,
      text: UserStatusText.Finished,
      value: UserStatus.Finished
    });
    return statuses;
  }

  if (
    hasBeenDischargedFromProgram(
      user.programStatus,
      user.userProgramStatusReason
    )
  ) {
    statuses.push({
      color: colors.red100,
      fontColor: colors.white,
      text: UserStatusText.Discharged,
      value: UserStatus.Discharged
    });
    return statuses;
  }

  if (lastActiveDate && now - lastActiveDate.getTime() < TimeInMs.Week) {
    statuses.push({
      color: colors.neonGreen100,
      text: UserStatusText.Active,
      value: UserStatus.Active
    });
  }

  if (isInMaintenance(user, program)) {
    statuses.push({
      color: colors.neonGreen140,
      fontColor: colors.white,
      text: UserStatusText.Maintenance,
      value: UserStatus.Maintenance
    });
  }

  if (now - joinedDate.getTime() < TimeInMs.Week) {
    statuses.push({
      color: colors.neonPink100,
      fontColor: colors.white,
      text: UserStatusText.New,
      value: UserStatus.New
    });
  }

  if (usingBodyTraceScale) {
    if (
      latestScaleDate &&
      now - latestScaleDate.getTime() > 3 * TimeInMs.Week
    ) {
      statuses.push({
        color: colors.navyBlue100,
        fontColor: colors.white,
        text: UserStatusText.Scale21Day,
        value: UserStatus.Scale21Day
      });
    } else if (
      latestScaleDate &&
      now - latestScaleDate.getTime() > 2 * TimeInMs.Week
    ) {
      statuses.push({
        color: colors.navyBlue80,
        fontColor: colors.white,
        text: UserStatusText.Scale14Day,
        value: UserStatus.Scale14Day
      });
    }
  }

  if (inactiveDays > 30) {
    statuses.push({
      color: colors.mustardYellow200,
      text: UserStatusText.Inactive30Days,
      fontColor: colors.white,
      value: UserStatus.Inactive30Days
    });
  } else if (inactiveDays > 14) {
    statuses.push({
      color: colors.mustardYellow120,
      text: UserStatusText.Inactive14Days,
      fontColor: colors.white,
      value: UserStatus.Inactive14Days
    });
  } else if (inactiveDays > 7) {
    statuses.push({
      color: colors.mustardYellow100,
      text: UserStatusText.Inactive7Days,
      value: UserStatus.Inactive7Days
    });
  }

  return statuses;
};

export const filterUserByMessages = (
  user: CoachUser,
  messagesFilterValue: string
): boolean => {
  if (hasQuitOrFinished(user.programStatus)) return false;

  switch (messagesFilterValue) {
    case MessageFilterOption.UnreadToOther:
      return user.hasNonRecipientsMessageActions;
    case MessageFilterOption.UnreadToYou:
      return user.hasMessageActions;
    case "technical":
      return user.possibleUnseenTechnicalProblem ?? false;
    case MessageFilterOption.Negative:
      return Boolean(
        user.overallSentiment &&
          user.overallSentiment < SentimentThreshold.Negative
      );
    default:
      return true;
  }
};

export const filterUsersByMemberState = (
  user: CoachUser,
  memberStates?: string[]
): boolean => {
  if (
    !user.memberState &&
    memberStates?.some((state) => state === UNDEFINED_OPTION)
  ) {
    return true;
  }

  const shouldBeDisplayed = states.some(
    (state) => state.code === user.memberState
  )
    ? memberStates?.some((state) => user.memberState === state)
    : user.memberState !== undefined &&
      memberStates?.some((state) => state === INCORRECT_OPTION);

  return Boolean(shouldBeDisplayed);
};

export const getRelevantSurvey = ({
  surveys,
  latestSurveyResults,
  proFilter
}: {
  surveys: Survey[];
  latestSurveyResults: SurveyResult[];
  proFilter?: string;
}) => {
  return proFilter === DEFAULT_FILTER_VALUE
    ? getLatestSurvey(latestSurveyResults, surveys)
    : latestSurveyResults?.find(
        (surveyResult) => surveyResult.surveyName === proFilter
      );
};

export const filterUserByPlanStates = (
  user: CoachUser,
  planStates?: string[]
): boolean => {
  if (
    !user.planState &&
    planStates?.some((state) => state === UNDEFINED_OPTION)
  ) {
    return true;
  }

  const shouldBeFiltered = states.some((state) => state.code === user.planState)
    ? planStates?.some((state) => user.planState === state)
    : user.planState
    ? planStates?.some((state) => state === INCORRECT_OPTION) ?? false
    : false;

  return Boolean(shouldBeFiltered);
};

export const filterUserByPRO = (
  user: CoachUser,
  surveys: Survey[],
  proFilter?: string,
  proStatusFilter?: string
) => {
  const relevantSurvey = getRelevantSurvey({
    latestSurveyResults: user.latestSurveyResults,
    proFilter,
    surveys
  });

  if (proStatusFilter === "not_submitted") {
    return relevantSurvey === undefined;
  }

  return relevantSurvey !== undefined;
};

export const filterUserByProStatus = ({
  user,
  surveys,
  proFilter,
  proStatusFilter
}: {
  user: CoachUser;
  surveys: Survey[];
  proFilter: string;
  proStatusFilter?: string;
}): boolean => {
  const surveyResult = getRelevantSurvey({
    latestSurveyResults: user.latestSurveyResults,
    surveys,
    proFilter
  });

  if (proStatusFilter === NOT_SUBMITTED) {
    return !surveyResult;
  }

  const survey = getSurveyByName(surveys, surveyResult?.surveyName ?? "");

  const rating = getSurveyRating(surveyResult?.result, survey);

  return Boolean(rating && rating.id === proStatusFilter);
};

export const filterUserByRetrigger = (
  user: CoachUser,
  retriggerFilterValue: string,
  program?: Program
) => {
  return (
    retriggerFilterValue ===
    getRetriggeredValue(
      user.eligibleForRestart,
      user.eligibleForRestartDate,
      program?.retriggeredStatusDurationSeconds
    )
  );
};

export const filterSingleUserByActivity = (
  user: CoachUser,
  program?: Program,
  activityFilters?: string[]
): boolean => {
  const now = Date.now();

  if (!activityFilters || activityFilters?.length === 0) return false;

  if (
    !activityFilters.includes(UserStatus.Finished) &&
    hasFinishedProgram(user.programStatus, user.userProgramStatusReason)
  ) {
    return false;
  }

  if (
    !activityFilters.includes(UserStatus.Quit) &&
    hasQuitProgram(user.programStatus, user.userProgramStatusReason)
  ) {
    return false;
  }

  if (
    !activityFilters.includes(UserStatus.Discharged) &&
    hasBeenDischargedFromProgram(
      user.programStatus,
      user.userProgramStatusReason
    )
  ) {
    return false;
  }

  const lastActiveDateOrJoined = user.lastActiveDate
    ? new Date(user.lastActiveDate)
    : new Date(user.joinedDate);
  const lastActiveDate = user.lastActiveDate
    ? new Date(user.lastActiveDate)
    : undefined;
  const joinedDate = new Date(user.joinedDate);

  for (const filter of activityFilters) {
    switch (filter) {
      case UserStatus.Active:
        if (
          !hasFinishedProgram(
            user.programStatus,
            user.userProgramStatusReason
          ) &&
          lastActiveDate &&
          now - lastActiveDate.getTime() < TimeInMs.Week
        ) {
          return true;
        }
        break;
      case UserStatus.New:
        if (
          !hasFinishedProgram(
            user.programStatus,
            user.userProgramStatusReason
          ) &&
          now - joinedDate.getTime() < TimeInMs.Week
        ) {
          return true;
        }
        break;
      case UserStatus.Pause:
        if (isPausedInProgram(user.programStatus)) {
          return true;
        }
        break;
      case UserStatus.Finished:
        if (
          hasFinishedProgram(user.programStatus, user.userProgramStatusReason)
        ) {
          return true;
        }
        break;
      case UserStatus.Discharged:
        if (
          hasBeenDischargedFromProgram(
            user.programStatus,
            user.userProgramStatusReason
          )
        ) {
          return true;
        }
        break;
      case UserStatus.Quit:
        if (hasQuitProgram(user.programStatus, user.userProgramStatusReason)) {
          return true;
        }
        break;
      case UserStatus.Maintenance:
        if (isInMaintenance(user, program)) {
          return true;
        }
        break;
      case UserStatus.Inactive7Days:
        if (
          now - lastActiveDateOrJoined.getTime() > TimeInMs.Week &&
          now - lastActiveDateOrJoined.getTime() < 2 * TimeInMs.Week
        ) {
          return true;
        }
        break;

      case UserStatus.Inactive14Days:
        if (
          now - lastActiveDateOrJoined.getTime() > 2 * TimeInMs.Week &&
          now - lastActiveDateOrJoined.getTime() < 30 * TimeInMs.Day
        ) {
          return true;
        }
        break;
      case UserStatus.Inactive30Days:
        if (now - lastActiveDateOrJoined.getTime() > 30 * TimeInMs.Day) {
          return true;
        }
        break;
      case UserStatus.Scale14Day: {
        const latestScaleDate = user.latestScaleDate
          ? new Date(user.latestScaleDate)
          : undefined;
        if (
          latestScaleDate &&
          now - latestScaleDate.getTime() > 2 * TimeInMs.Week &&
          now - latestScaleDate.getTime() < 3 * TimeInMs.Week
        ) {
          return true;
        }
        break;
      }
      case UserStatus.Scale21Day: {
        const latestScaleDate = user.latestScaleDate
          ? new Date(user.latestScaleDate)
          : undefined;
        if (
          latestScaleDate &&
          now - latestScaleDate.getTime() > 3 * TimeInMs.Week
        ) {
          return true;
        }
        break;
      }

      default:
        break;
    }
  }
  return false;
};

export const filterUserByCoach = (
  user: CoachUser,
  programSpecialties?: ProgramSpecialty[],
  coachId?: string
): boolean => {
  if (coachId === CoachAssignedValue.Assigned) {
    return programSpecialties?.length
      ? Boolean(user.coaches?.length)
      : user.assignedCoachUserId !== undefined;
  }
  if (coachId === CoachAssignedValue.Unassigned) {
    return programSpecialties?.length
      ? !user.coaches?.length
      : !user.assignedCoachUserId;
  }

  if (programSpecialties?.length) {
    return (
      user.coaches?.some((coach) => coach.coachUserId === coachId) ?? false
    );
  }

  return user.assignedCoachUserId === coachId;
};

export const filterUserByLayer = (
  user: CoachUser,
  value?: string[]
): boolean => {
  if (!user.coachUserLayersForDisplay && value?.includes(UNDEFINED_OPTION)) {
    return true;
  }
  return (
    user?.coachUserLayersForDisplay?.some((layer) =>
      value?.includes(layer.id)
    ) ?? false
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./ExportExternalUsersPage.module.scss";

import { getImportableExternalUserType } from "~/api/requests/externalUserRequests";
import CheckboxInput from "~/components/checkboxInput/CheckboxInput";
import CustomDatePicker from "~/components/customDatePicker/CustomDatePicker";
import Input from "~/components/input/Input";
import Layout from "~/components/layout/Layout";
import CustomSelect from "~/components/select/CustomSelect";
import { UTMParameters } from "~/typing/carePortalTypes";
import { ExternalUserType } from "~/typing/sidekickTypes";

type Props = {
  importEntryInfo: {
    id?: string;
    name?: string;
  };
  getExport: (props: {
    uploadToken: string;
    externalUserTypeName: string;
    columnList: string[];
    utmParams?: UTMParameters;
    dateFromString: string;
    dateToString: string;
    setResponseMessage: (message: string) => void;
    importEntryId?: string;
  }) => Promise<void>;
};

const ExportExternalUsersPage = ({ importEntryInfo, getExport }: Props) => {
  const exportColumns = [
    "MBR Key",
    "MCID",
    "HRS_MemberID  (HRS Member ID)",
    "Case ID",
    "HCID (Anthem Member ID)",
    "Id Date",
    "Member Name",
    "Date of Birth",
    "Email",
    "Phone Number on File",
    "Phone Number2",
    "Phone Number3",
    "Plan State",
    "Member State",
    "Brand",
    "Primary Risk Factor",
    "CMDM Source System",
    "Program Name",
    "Line of Business",
    "Access Code",
    "Unique Access URL",
    "Outreach Type",
    "Enrolment status",
    "Consent to call",
    "Linked user ID",
    "Member Proxy ID",
    "Patient Bond",
    "Group Name",
    "Group Number",
    "Relationship"
  ];

  const { locale = "" } = useParams<{
    locale: string;
  }>();
  const { t } = useTranslation();
  const [uploadToken, setUploadToken] = useState("");
  const [columnList, setColumnList] = useState(exportColumns);
  const [externalUserTypeName, setExternalUserTypeName] = useState<
    string | undefined
  >(undefined);
  const [responseMessage, setResponseMessage] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [externalUserTypes, setExternalUserTypes] = useState<
    ExternalUserType[]
  >([]);
  const [usingUTM, setUsingUTM] = useState(false);
  const [utmErrorMessage, setUtmErrorMessage] = useState("");
  const [filterErrorMessage, setFilterErrorMessage] = useState("");
  const [utm_CampaignSource, setUtm_CampaignSource] = useState("");
  const [utm_CampaignMedium, setUtm_CampaignMedium] = useState("");
  const [utm_CampaignName, setUtm_CampaignName] = useState("");
  const [utm_CampaignTerm, setUtm_CampaignTerm] = useState("");
  const [utm_CampaignContent, setUtm_CampaignContent] = useState("");
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();

  useEffect(() => {
    const getExternalUserTypes = async () => {
      const externalUserTypes = await getImportableExternalUserType();
      setExternalUserTypes(externalUserTypes);
    };
    getExternalUserTypes();
  }, []);

  const validateFilters = () => {
    if (dateFrom !== undefined && dateTo !== undefined) {
      if (dateFrom > dateTo) {
        setFilterErrorMessage("'Date to' can not be before 'Date from'");
        return false;
      }
    }
    return true;
  };

  const validateUTM = (): {
    utmResult: boolean;
    utmParams: UTMParameters | undefined;
  } => {
    if (!usingUTM) {
      return { utmResult: true, utmParams: undefined };
    }

    if (!utm_CampaignMedium || !utm_CampaignName || !utm_CampaignSource) {
      setUtmErrorMessage("Campaign source, name and medium are required");
      return { utmResult: false, utmParams: undefined };
    }

    return {
      utmResult: true,
      utmParams: {
        utmSource: utm_CampaignSource,
        utmMedium: utm_CampaignMedium,
        utmCampaign: utm_CampaignName,
        utmTerm: utm_CampaignTerm,
        utmContent: utm_CampaignContent
      }
    };
  };

  const handleExportClick = async () => {
    if (!validateFilters()) {
      return false;
    } else {
      setFilterErrorMessage("");
    }

    const { utmResult, utmParams } = validateUTM();
    if (!utmResult) {
      return false;
    } else {
      setUtmErrorMessage("");
    }

    if (dateTo !== undefined) {
      dateTo.setHours(23, 59, 59, 999);
    }
    if (dateFrom !== undefined) {
      dateFrom.setHours(0, 0, 0, 0);
    }
    const dateFromString = dateFrom === undefined ? "" : dateFrom.toISOString();
    const dateToString = dateTo === undefined ? "" : dateTo.toISOString();

    setIsExporting(true);
    await getExport({
      uploadToken,
      externalUserTypeName: externalUserTypeName ?? "",
      columnList,
      utmParams,
      dateFromString,
      dateToString,
      setResponseMessage,
      importEntryId: importEntryInfo.id
    });
    setIsExporting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const toggleColumn = (column: string) => {
    const index = columnList.indexOf(column);
    const copiedList = [...columnList];
    if (index > -1) {
      copiedList.splice(index, 1);
    } else {
      copiedList.push(column);
    }
    setColumnList(copiedList);
  };

  const canExport = (): boolean => {
    const hasChosenProgramOrImportEntry =
      Boolean(externalUserTypeName) || Boolean(importEntryInfo.id);

    if (!usingUTM) {
      return (
        !isExporting && Boolean(uploadToken) && hasChosenProgramOrImportEntry
      );
    }

    return (
      !isExporting &&
      Boolean(uploadToken) &&
      hasChosenProgramOrImportEntry &&
      Boolean(utm_CampaignSource) &&
      Boolean(utm_CampaignMedium) &&
      Boolean(utm_CampaignName)
    );
  };

  return (
    <Layout>
      <div className={styles.wrapper}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.container}>
            <h1>
              {t("importExport.exportPage.exportHeader", {
                resource: importEntryInfo.name
                  ? `: ${importEntryInfo.name}`
                  : ""
              })}
            </h1>
            <Input
              value={uploadToken}
              onChange={setUploadToken}
              placeholder={t(
                "importExport.exportPage.tokenPlaceholder",
                "Enter token"
              )}
            />

            {!importEntryInfo.id && (
              <div>
                <h4>
                  <b>{t("importExport.exportPage.filters", "Filters")}</b>
                </h4>
                {filterErrorMessage.length > 0 && (
                  <p className="input__error-msg">{filterErrorMessage}</p>
                )}
                <div>
                  <CustomSelect
                    value={externalUserTypeName}
                    placeholder={t(
                      "importExport.exportPage.programPlaceholder",
                      "Select program"
                    )}
                    valueKey="name"
                    onChange={(e) => setExternalUserTypeName(e.target.value)}
                    renderOption={(option) =>
                      `${option?.title} - ${option?.name}`
                    }
                    options={externalUserTypes}
                    className={styles.select}
                  />

                  <div
                    style={{ marginRight: "2%" }}
                    className={styles.datePicker}
                  >
                    <CustomDatePicker
                      placeholderText={t(
                        "importExport.exportPage.from",
                        "From"
                      )}
                      selected={dateFrom}
                      onChange={(date) => setDateFrom(date)}
                      locale={locale}
                      onClear={() => setDateFrom(undefined)}
                    />
                  </div>
                  <div className={styles.datePicker}>
                    <CustomDatePicker
                      placeholderText={t("importExport.exportPage.to", "To")}
                      selected={dateTo}
                      onChange={(date) => setDateTo(date)}
                      locale={locale}
                      onClear={() => setDateTo(undefined)}
                    />
                  </div>
                </div>
              </div>
            )}

            <div>
              <h4>
                <b>{t("importExport.exportPage.columns", "Columns")}</b>
              </h4>
              <div className={styles.link}>
                <button
                  disabled={columnList.length === exportColumns.length}
                  onClick={() => setColumnList([...exportColumns])}
                >
                  {t("select.selectAll", "Select All")}
                </button>

                <button
                  disabled={columnList.length === 0}
                  onClick={() => setColumnList([])}
                >
                  {t("select.clearAll", "Clear All")}
                </button>
              </div>

              <div className={styles.columns}>
                {exportColumns.map((column, index) => (
                  <div className={styles.column} key={`export-column-${index}`}>
                    <CheckboxInput
                      checked={columnList.includes(column)}
                      onChange={() => toggleColumn(column)}
                      label={column}
                    />
                  </div>
                ))}
              </div>
            </div>

            <CheckboxInput
              checked={usingUTM}
              onChange={(checked) => setUsingUTM(checked)}
              label={t(
                "importExport.exportPage.useUtmLabel",
                "Use UTM parameters"
              )}
            />
            <div>
              <button
                className={`btn-sm btn-secondary ${styles.exportBtn}`}
                disabled={!canExport()}
                onClick={() => handleExportClick()}
              >
                {t("importExport.exportPage.export", "Export")}
              </button>
              <div className={styles.fileInfo}>{responseMessage}</div>
            </div>
          </div>
          {usingUTM && (
            <div className={styles.utmParameters}>
              <div className={styles.utmHeader}>
                <h2>
                  {t("importExport.exportPage.utmParams", "UTM Parameters")}
                </h2>
                <p>
                  {t(
                    "importExport.exportPage.utmParamHelp",
                    "If any of these is set, it is required to fill in those marked with"
                  )}{" "}
                  <span className={styles.star}>*</span>.
                </p>
              </div>

              {utmErrorMessage.length > 0 && (
                <p className="input__error-msg">{utmErrorMessage}</p>
              )}
              <Input
                label={"Campaign Source"}
                required
                placeholder="Campaign Source"
                value={utm_CampaignSource}
                onChange={setUtm_CampaignSource}
              />

              <Input
                required
                label="Campaign Medium"
                placeholder="Campaign Medium"
                value={utm_CampaignMedium}
                onChange={setUtm_CampaignMedium}
              />
              <Input
                required
                label="Campaign Name"
                placeholder="Campaign Name"
                value={utm_CampaignName}
                onChange={setUtm_CampaignName}
              />
              <Input
                label="Campaign Term"
                placeholder="Campaign Term"
                value={utm_CampaignTerm}
                onChange={setUtm_CampaignTerm}
              />
              <Input
                label="Campaign Content"
                placeholder="Campaign Content"
                value={utm_CampaignContent}
                onChange={setUtm_CampaignContent}
              />
            </div>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default ExportExternalUsersPage;

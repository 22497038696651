import axios from "axios";

import {
  responseHandler,
  defaultErrorHandler,
  attachmentResponseHandler,
  authErrorHandler
} from "./apiResponseHandlers";
import { GraphqlClient } from "./graphql/client";

import config from "../config";

interface HeadersType {
  [key: string]: string;
}

interface OptionsType {
  headers: HeadersType;
  credentials?: RequestCredentials;
}

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-SK-PORTAL": `care-${config.platform}`,
  Pragma: "no-cache",
  "Cache-Control": "stale-while-revalidate=86400"
};

const defaultOptions: OptionsType = {
  headers,
  credentials: "include"
};

const gqlClient = new GraphqlClient(
  config.carePortalGraphQlUrl,
  defaultOptions
);

const api = axios.create({
  headers,
  baseURL: config.apiBaseUrl,
  withCredentials: true
});

const baseApiWithoutIntercept = axios.create({
  headers,
  baseURL: config.apiBaseUrl,
  withCredentials: true
});

const authApi = axios.create({
  headers,
  baseURL: config.authBaseUrl,
  withCredentials: true
});

const chatApi = axios.create({
  headers,
  baseURL: config.assistanctCoachUrl,
  withCredentials: true
});

const attachmentApi = axios.create({
  headers,
  withCredentials: true,
  responseType: "arraybuffer"
});

authApi.defaults.headers["Authorization"] = "BasicCustom";

api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => defaultErrorHandler(error)
);

authApi.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => authErrorHandler(error)
);

chatApi.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => defaultErrorHandler(error)
);

attachmentApi.interceptors.response.use(
  (response) => attachmentResponseHandler(response),
  (error) => defaultErrorHandler(error)
);

export { authApi, gqlClient, baseApiWithoutIntercept, chatApi, attachmentApi };
export default api;

import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";

import { logout } from "~/auth/authRedcuer";
import { TimeInMs } from "~/constants/measurements";
import { AppDispatch } from "~/state/store";
import { setSessionExpired } from "~/state/user/userSlice";

export const useSessionManagement = () => {
  const [displayLoginModal, setDisplayLoginModal] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const handleIdleUser = () => {
    dispatch(
      logout({
        callback: () => window.dispatchEvent(new Event("session-expired")),
        fromIdleActivity: true
      })
    );
  };

  useIdleTimer({
    onIdle: handleIdleUser,
    timeout: TimeInMs.Minute * 20,
    crossTab: true,
    syncTimers: 200
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    window.addEventListener("session-expired", () => {
      setDisplayLoginModal(true);
      queryClient.removeQueries();
      dispatch(setSessionExpired(true));
    });

    return () => {
      window.removeEventListener("session-expired", () =>
        setDisplayLoginModal(false)
      );
    };
  }, []);

  return { displayLoginModal, setDisplayLoginModal };
};

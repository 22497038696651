import { gql } from "graphql-request";

import { gqlClient } from "../api";

import { ApproveAllSurveyResultsDtoInput } from "~/typing/graphql/types";

export const approveAllSurveyResults = async (
  approveAllSurveyResultsDto: ApproveAllSurveyResultsDtoInput
) => {
  const mutation = gql`
    mutation ApproveSurveyResults(
      $approveAllSurveyResultsDto: ApproveAllSurveyResultsDtoInput!
    ) {
      approveAllSurveyResults(
        approveAllSurveyResultsDto: $approveAllSurveyResultsDto
      )
    }
  `;

  return await gqlClient.request(mutation, {
    approveAllSurveyResultsDto
  });
};

import { useParams } from "react-router-dom";

import PROTemplate from "./PROTemplate";

import { PROURLParams } from "~/typing/carePortalTypes";
import { Survey } from "~/typing/sidekickTypes";

type PROTemplateWrapperProps = {
  survey: Survey | undefined;
};

const PROTemplateWrapper = ({ survey }: PROTemplateWrapperProps) => {
  const {
    program_id = "",
    locale = "",
    survey_id = "",
    user_id = ""
  } = useParams<PROURLParams>();
  return (
    <PROTemplate
      survey={survey}
      user_id={user_id}
      program_id={program_id}
      survey_id={survey_id}
      locale={locale}
    />
  );
};

export default PROTemplateWrapper;

import { DateTime } from "luxon";

import { TimeInMs } from "~/constants/measurements";
import { isSameDate } from "~/helpers/date/dateHelpers";
import { t } from "~/i18n";
import { MetricType, UserMetricData } from "~/typing/carePortalTypes";
import {
  DailySummary,
  MessageThread,
  Mission,
  MissionScore,
  MissionsSlotDay,
  UserDetails
} from "~/typing/sidekickTypes";

type TaskCompliance = {
  completedMissions: number;
  totalMissions: number;
};

type DayWithTaskCompliance = {
  date: DateTime;
  taskCompliance?: TaskCompliance;
};

const getTaskComplianceForSlotDay = (
  slotDay: MissionsSlotDay
): TaskCompliance => {
  const completedMissions =
    slotDay.tasks?.filter((task) => task.currentValue >= task.targetValue)
      .length ?? 0;

  return {
    completedMissions,
    totalMissions: slotDay.tasks?.length ?? 0
  };
};

const getTaskComplianceForActiveDays = (days: DayWithTaskCompliance[]) => {
  const taskComplianceData = days.reduce(
    (acc, day) => {
      return {
        completedMissions:
          acc.completedMissions + (day.taskCompliance?.completedMissions ?? 0),
        totalMissions:
          acc.totalMissions + (day.taskCompliance?.totalMissions ?? 0)
      };
    },
    { completedMissions: 0, totalMissions: 0 }
  );

  if (taskComplianceData.totalMissions === 0) return 0;

  return Math.round(
    (taskComplianceData.completedMissions / taskComplianceData.totalMissions) *
      100
  );
};

const calculateActiveDays = ({
  slotDays,
  messageThreads,
  userDetail,
  from,
  to
}: {
  slotDays?: MissionsSlotDay[];
  messageThreads?: MessageThread;
  userDetail?: UserDetails;
  from: DateTime;
  to: DateTime;
}): {
  activeDays: number;
  taskCompliance: number;
} => {
  const activeDays: DayWithTaskCompliance[] = [];

  for (let i = from.toMillis(); i < to.toMillis(); i = i + TimeInMs.Day) {
    const dayWithFinishedMission = slotDays?.find(
      (day) =>
        isSameDate(DateTime.fromISO(day.date), DateTime.fromMillis(i)) &&
        day.tasks?.some((task) => task.currentValue >= task.targetValue)
    );

    if (dayWithFinishedMission) {
      activeDays.push({
        date: DateTime.fromMillis(i),
        taskCompliance: getTaskComplianceForSlotDay(dayWithFinishedMission)
      });
      continue;
    }

    const sentMessageOnDay = messageThreads?.messages?.some(
      (message) =>
        isSameDate(
          DateTime.fromISO(message.createdDate),
          DateTime.fromMillis(i)
        ) && message.senderUserId === userDetail?.user?.userId
    );

    const activeDayInDailySummary = userDetail?.dailySummary?.find(
      (summary) =>
        isSameDate(DateTime.fromISO(summary.date), DateTime.fromMillis(i)) &&
        (summary.scoreCount > 0 ||
          summary.stepCount > 0 ||
          summary.physicalActivityMinutes > 0)
    );

    if (sentMessageOnDay || activeDayInDailySummary) {
      activeDays.push({
        date: DateTime.fromMillis(i)
      });
    }
  }

  return {
    activeDays: activeDays.length,
    taskCompliance: getTaskComplianceForActiveDays(activeDays ?? [])
  };
};

const calculatePercentageChangeBetweenWeeks = (
  lastWeek: number,
  weekBeforeLast: number
) => {
  const change = weekBeforeLast
    ? ((lastWeek - weekBeforeLast) / weekBeforeLast) * 100
    : 0;

  return Math.round(change);
};

export const calculateActiveDaysAndTaskCompliance = (
  slotDays: MissionsSlotDay[],
  messageThreads?: MessageThread,
  userDetail?: UserDetails
): UserMetricData[] => {
  const dayAfterToday = DateTime.now().plus({ day: 1 });

  const activeDaysLastWeek = calculateActiveDays({
    slotDays,
    messageThreads,
    userDetail,
    from: dayAfterToday.minus({ week: 1 }),
    to: dayAfterToday
  });

  const activeDaysWeekBeforeLast = calculateActiveDays({
    slotDays,
    messageThreads,
    userDetail,
    from: dayAfterToday.minus({ week: 2 }),
    to: dayAfterToday.minus({ week: 1 })
  });

  return [
    {
      title: MetricType.ActiveDays,
      value: activeDaysLastWeek.activeDays,
      metric: "days",
      change: {
        value: calculatePercentageChangeBetweenWeeks(
          activeDaysLastWeek.activeDays,
          activeDaysWeekBeforeLast.activeDays
        ),
        negative:
          activeDaysLastWeek.activeDays - activeDaysWeekBeforeLast.activeDays <
          0
      },
      info: t("nextStep.metrics.info.activeDays")
    },
    {
      title: MetricType.TaskCompliance,
      value: activeDaysLastWeek.taskCompliance,
      metric: "%",
      change: {
        value: calculatePercentageChangeBetweenWeeks(
          activeDaysLastWeek.taskCompliance,
          activeDaysWeekBeforeLast.taskCompliance
        ),
        negative:
          activeDaysLastWeek.taskCompliance -
            activeDaysWeekBeforeLast.taskCompliance <
          0
      }
    }
  ];
};

export const getMetricsMissionIds = (missions: Mission[]) => {
  const sleepMissionId =
    missions.find((mission) => mission.imageName === "mission_sleep_quality")
      ?.id ?? "";
  const energyMissionId =
    missions.find((mission) => mission.imageName === "mission_energy_levels")
      ?.id ?? "";
  const stressMissionId =
    missions.find((mission) => mission.imageName === "mission_stress_levels")
      ?.id ?? "";

  return {
    sleepMissionId,
    energyMissionId,
    stressMissionId
  };
};

export const convertMissionScoresToLineData = ({
  sleepScores,
  energyScores,
  stressScores
}: {
  sleepScores: MissionScore[];
  energyScores: MissionScore[];
  stressScores: MissionScore[];
}) => {
  const processScores = (scores: MissionScore[]) => {
    return scores
      .map((score) => {
        return {
          date: score.date,
          score: score.scoreMissions.reduce(
            (acc, scoreMission) => acc + scoreMission.value1,
            0
          )
        };
      })
      .reverse();
  };

  return {
    sleep: processScores(sleepScores),
    energy: processScores(energyScores),
    stress: processScores(stressScores)
  };
};

export const convertMissionScoresToMetricData = ({
  sleepScores,
  energyScores,
  stressScores
}: {
  sleepScores: MissionScore[];
  energyScores: MissionScore[];
  stressScores: MissionScore[];
}): UserMetricData[] => {
  const today = DateTime.now();
  const lastWeek = DateTime.now().minus({ weeks: 1 });
  const weekBeforeLast = DateTime.now().minus({ weeks: 2 });

  const calculateAverageScore = ({
    from,
    to,
    scores
  }: {
    scores: MissionScore[];
    from: DateTime;
    to: DateTime;
  }) => {
    const filteredScores = scores.filter((score) => {
      const date = DateTime.fromISO(score.date);
      return date > from && date <= to;
    });

    return filteredScores.length > 0
      ? filteredScores.reduce(
          (total, score) => total + averageMissionValue(score.scoreMissions),
          0
        ) / filteredScores.length
      : 0;
  };

  const averageMissionValue = (
    missions: { missionId: string; value1: number }[]
  ) => {
    return (
      missions.reduce((total, mission) => total + mission.value1, 0) /
      missions.length
    );
  };

  const createMetricData = (
    title: MetricType,
    scores: MissionScore[],
    downIsNegative = true
  ) => {
    const averageLastWeek = calculateAverageScore({
      scores,
      from: lastWeek,
      to: today
    });

    const averageWeekBefore = calculateAverageScore({
      scores,
      from: weekBeforeLast,
      to: lastWeek
    });

    const percentageChange = calculatePercentageChangeBetweenWeeks(
      averageLastWeek,
      averageWeekBefore
    );

    return {
      title: title,
      value: averageLastWeek,
      metric: "rating",
      change: {
        value: percentageChange,
        negative: downIsNegative ? percentageChange < 0 : percentageChange > 0
      }
    };
  };

  return [
    createMetricData(MetricType.SleepQuality, sleepScores),
    createMetricData(MetricType.EnergyLevels, energyScores),
    createMetricData(MetricType.StressLevels, stressScores, false)
  ];
};

export const convertDailyMissionsToMetricData = ({
  dailySummary = []
}: {
  dailySummary?: DailySummary[];
}): UserMetricData[] => {
  const today = DateTime.now();
  const startDateLastWeek = DateTime.now().minus({ weeks: 2 });
  const endDateLastWeek = DateTime.now().minus({ weeks: 1 });

  const filterSummaries = (start: DateTime, end: DateTime) =>
    dailySummary.filter((summary) => {
      const date = DateTime.fromISO(summary.date);
      return date >= start && date < end;
    });

  // Calculate total for a given key in the daily summary
  const calculateTotal = (summaries: DailySummary[], key: keyof DailySummary) =>
    summaries.reduce((acc, summary) => {
      return acc + (summary[key] as number);
    }, 0);

  const dailySummaryLastWeek = filterSummaries(endDateLastWeek, today);
  const dailySummaryWeekBeforeLast = filterSummaries(
    startDateLastWeek,
    endDateLastWeek
  );

  const createMetricData = ({
    title,
    key,
    metric
  }: {
    title: MetricType;
    key: keyof DailySummary;
    metric: string;
  }): UserMetricData => {
    const lastWeekTotal = calculateTotal(dailySummaryLastWeek, key);
    const weekBeforeLastTotal = calculateTotal(dailySummaryWeekBeforeLast, key);

    const percentageChange = calculatePercentageChangeBetweenWeeks(
      lastWeekTotal,
      weekBeforeLastTotal
    );

    return {
      title,
      value: lastWeekTotal,
      metric,
      change: {
        value: percentageChange,
        negative: percentageChange < 0
      }
    };
  };

  return [
    createMetricData({
      title: MetricType.MissionsCompleted,
      key: "scoreCount",
      metric: "missions"
    }),
    createMetricData({
      title: MetricType.PhysicalActivityMinutes,
      key: "physicalActivityMinutes",
      metric: "minutes"
    }),

    createMetricData({
      title: MetricType.StepCount,
      key: "stepCount",
      metric: "steps"
    })
  ];
};

import { Role } from "./amplitudeTypes";

import { DefaultConfiguration, ampli } from "../ampli";

import config from "~/config";
import { UserPrivileges } from "~/constants/privileges";
import { isLocalhost } from "~/helpers/browser/browserHelpers";

export const initAmplitude = (): void => {
  if (isLocalhost) return;

  const isProduction = import.meta.env.VITE_SK_PLATFORM.includes("prod");

  ampli.load({
    environment: isProduction ? "production" : "development",
    client: {
      apiKey: config.amplitudeKey,
      configuration: {
        ...DefaultConfiguration,
        minIdLength: 1,
        defaultTracking: {
          fileDownloads: false,
          formInteractions: false,
          pageViews: false
        }
      }
    }
  });
};

export const hasValidRole = (role?: Role) =>
  role && Object.values(Role).includes(role);

const getCoachType = (privileges: number): string => {
  const privilegeMapping: { [key: number]: string } = {
    [UserPrivileges.Coach]: "Coach",
    [UserPrivileges.LeadCoach]: "Lead Coach",
    [UserPrivileges.Admin]: "Admin"
  };

  return privilegeMapping[privileges] || "Unknown";
};

export const identifyAmplitudeUser = ({
  userId,
  role,
  privileges
}: {
  userId: string;
  role?: Role;
  privileges: number;
}) => {
  if (isLocalhost) return;

  const coachType = getCoachType(privileges);

  ampli.identify(userId, {
    environment: config.isAnthem ? "elevance" : "sidekick",
    role: hasValidRole(role) ? role : Role.N_A,
    coachType: coachType
  });
};

import classNames from "classnames";
import { DateTime } from "luxon";

import {
  BehavioralPROStatusType,
  mapBehavioralPROQuestion
} from "./BehavioralPRO.constants";
import styles from "./BehavioralPRO.module.scss";
import {
  PROStatusTooltip,
  PROBehavioralAnswerTooltip,
  BehavioralPROInformationTooltip
} from "./BehavioralPRO.tooltips";

import FloatingElement from "~/components/floatingElement/FloatingElement";
import InfoTooltip from "~/components/infoTooltip/InfoTooltip";
import MessageSuggestion from "~/components/messageSuggestion/MessageSuggestion";
import {
  BehaviouralSurveyInstance,
  BehaviouralSurveyMetadata,
  MessageSuggestionDto
} from "~/typing/graphql/types";

const cx = classNames.bind(styles);

const analyzeBehavioralPRO = (instance: BehaviouralSurveyInstance) => {
  const {
    exercise,
    goalSetting,
    manageNegativity,
    menoKnowledge,
    practiceMindfulness
  } = instance;

  const properties = [
    exercise,
    goalSetting,
    manageNegativity,
    menoKnowledge,
    practiceMindfulness
  ];

  if (properties.every((value) => value && value <= 4)) {
    return BehavioralPROStatusType.Struggling;
  }
  if (properties.every((value) => value && value >= 5)) {
    return BehavioralPROStatusType.Thriving;
  }
  return BehavioralPROStatusType.Managing;
};

const BehavioralPROSurveyHeader = () => {
  return (
    <thead>
      <tr>
        <th className={styles.date}>Date</th>
        <th className={styles.score}>Score</th>
        <th className={styles.exercise}>Restructure</th>
        <th className={styles.goalSetting}>Relax</th>
        <th className={styles.manageNegativity}>Educate</th>
        <th className={styles.menoKnowledge}>Exercise</th>
        <th className={styles.practiceMindfulness}>Set goals</th>
      </tr>
    </thead>
  );
};

const BehavioralPROAnswer = ({
  value = 0,
  name
}: {
  value?: number;
  name: string;
}) => {
  const mediumValues = [2, 3, 4];
  const highValues = [5, 6];

  const proData = mapBehavioralPROQuestion(name);

  if (!proData) return null;

  return (
    <FloatingElement
      floatingContent={
        <PROBehavioralAnswerTooltip data={proData} answer={value} />
      }
      useMaxWidth={false}
    >
      <div className={styles.proAnswerWrapper}>
        <div
          className={cx(styles.proAnswer, {
            [styles.low]: value <= 1,
            [styles.medium]: mediumValues.includes(value),
            [styles.high]: highValues.includes(value),
            [styles.severe]: value > 6
          })}
        >
          {value}
        </div>
        <p>{proData.shortValues[value - 1]}</p>
      </div>
    </FloatingElement>
  );
};

const BehavioralPROStatus = ({
  status
}: {
  status: BehavioralPROStatusType;
}) => {
  const tooltipContent = PROStatusTooltip(status);

  return (
    <FloatingElement floatingContent={tooltipContent}>
      <div
        className={cx({
          [styles.status]: true,
          [styles.struggling]: status === BehavioralPROStatusType.Struggling,
          [styles.progressing]: status === BehavioralPROStatusType.Managing,
          [styles.thriving]: status === BehavioralPROStatusType.Thriving
        })}
      >
        {status}
      </div>
    </FloatingElement>
  );
};

const BehavioralPROInstance = ({
  instance
}: {
  instance: BehaviouralSurveyInstance;
}) => {
  const { answeredAt, ...rest } = instance;

  if (!answeredAt) return null;

  const proAnalysis = analyzeBehavioralPRO(instance);

  return (
    <tr>
      <td className={styles.date}>
        {DateTime.fromFormat(answeredAt, "yyyy-MM-dd").toFormat("d. LLL.")}
      </td>

      <td>
        <BehavioralPROStatus status={proAnalysis} />
      </td>
      {Object.keys(rest)
        .filter((key) => key !== "totalScore")
        .map((key) => (
          <td key={`pro-answer-${key}-${answeredAt}`}>
            <BehavioralPROAnswer value={rest[key]} name={key} />
          </td>
        ))}
    </tr>
  );
};

const BehavioralPRO = ({
  behavioralData,
  userId
}: {
  userId: string;
  behavioralData: BehaviouralSurveyMetadata;
}) => {
  const { week1, week6, week12, messageSuggestion } = behavioralData;

  const proResults: BehaviouralSurveyInstance[] = [week1, week6, week12].filter(
    (x): x is BehaviouralSurveyInstance => !!x
  );

  const validMessages: MessageSuggestionDto[] = messageSuggestion.filter(
    (message): message is MessageSuggestionDto =>
      message !== null && message !== undefined
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h4>Survey results</h4>
        <InfoTooltip content={<BehavioralPROInformationTooltip />} />
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.proTable}>
          <BehavioralPROSurveyHeader />
          <tbody>
            {proResults.map((pro, index) => (
              <BehavioralPROInstance key={index} instance={pro} />
            ))}
          </tbody>
        </table>
      </div>

      <MessageSuggestion
        messages={validMessages}
        simulateMessageGeneration
        userId={userId}
        graphQLMessageReport
      />
    </div>
  );
};

export default BehavioralPRO;

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./NavProfile.module.scss";

import LogoutIcon from "~/assets/logout.svg";
import Chevron from "~/assets/svgComponents/Chevron";
import ProfileIcon from "~/assets/user.svg";
import Avatar from "~/components/avatar/Avatar";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { EnrichedAuthenticatedUser } from "~/typing/sidekickTypes";

type DropdownItem = {
  link: string;
  text: string;
  icon: string;
};

type NavProfileProps = {
  user: EnrichedAuthenticatedUser;
};

const NavProfile = ({ user }: NavProfileProps) => {
  const { t } = useTranslation();

  const { isEnabled: nextStepEnabled } = useFeatureToggle({
    featureName: SegmentationKey.CoachCentricView
  });

  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  const dropdownItems: DropdownItem[] = [
    {
      link: "/me",
      text: t("header.myPage", "My page"),
      icon: ProfileIcon
    },
    {
      link: "/logout",
      text: t("header.logout", "Log out"),
      icon: LogoutIcon
    }
  ];

  const navItems = dropdownItems.map(({ link, text, icon }) => {
    return (
      <li key={text} className={styles.NavProfile__NavItem}>
        <Link to={link}>
          <img src={icon} alt={text} className={styles.NavProfile__Icon} />
          <span>{text}</span>
        </Link>
      </li>
    );
  });

  return (
    <div className={styles.wrapper} ref={dropdownRef}>
      <button
        className={styles.NavProfile__User}
        onClick={() => setShowDropdown(!showDropdown)}
        data-testid="nav-profile-dropdown"
      >
        <Avatar
          user={user}
          size="md"
          className={styles.NavProfile__User__Avatar}
        />

        {nextStepEnabled ? (
          <Chevron direction="down" color="white" />
        ) : (
          <p>{user?.displayName}</p>
        )}
      </button>
      {showDropdown && (
        <div className={styles.NavProfile__ProfileDropdown}>
          <ul className={styles.NavProfile}>
            <div className={styles.NavProfile__Notch} />
            {navItems}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavProfile;

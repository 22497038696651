import classNames from "classnames";
import { t } from "i18next";
import { DateTime } from "luxon";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import EmptyPROContainer from "./components/emptyProContainer/EmptyProContainer";
import PROQuestionRow from "./components/proQuestionRow/PROQuestionRow";
import PROResultRow from "./components/proResultRow/PROResultRow";
import PROTemplateHeader from "./components/proTemplateHeader/PROTemplateHeader";
import { useSurveyQuestions } from "./hooks/useSurveyQuestions";
import { useSurveyResultsToDisplay } from "./hooks/useSurveyResultsToDisplay";
import { PROTemplateContext } from "./PROTemplate.context";
import { getUniqueValues } from "./PROTemplate.helpers";
import styles from "./PROTemplate.module.scss";

import { setCoachRatingForSurvey } from "~/api/requests/surveyRequests";
import Image from "~/components/image/Image";
import Modal from "~/components/modal/Modal";
import SkeletonPROPage from "~/components/skeletons/SkeletonPROPage";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import useSurveyResults from "~/hooks/useApi/useSurveyResults";
import useUserDetail from "~/hooks/useApi/useUserDetail";
import { Survey } from "~/typing/sidekickTypes";

const cx = classNames.bind(styles);

const switchData = [
  {
    label: t("pro.text"),
    value: true
  },
  {
    label: t("pro.numerical"),
    value: false
  }
];

type PROTemplateProps = {
  survey: Survey | undefined;
  user_id: string;
  program_id: string;
  survey_id: string;
  locale: string;
  highlightLatestSurveyResult?: boolean;
  openColorOptions?: boolean;
  handleCloseColorOptions?: () => void;
  onSurveyResultChange?: () => void;
};

const PROTemplate = ({
  survey,
  user_id,
  program_id,
  survey_id,
  locale,
  highlightLatestSurveyResult,
  openColorOptions = false,
  handleCloseColorOptions,
  onSurveyResultChange
}: PROTemplateProps) => {
  // Local state variables
  const [proImages, setProImages] = useState<Map<string, any>>(new Map());
  const [useTextBased, setUseTextBased] = useState(false);
  const [offsetFromToday, setOffsetFromToday] = useState(0);
  const [answerInModal, setAnswerInModal] = useState<
    | {
        attachmentUrl?: string;
        date?: Date;
      }
    | undefined
  >();
  const {
    userDetail,
    isLoading: userDetailLoading,
    invalidate: mutateUserDetail
  } = useUserDetail({
    programCatalogItemId: program_id,
    locale: locale,
    userId: user_id
  });
  const {
    invalidate: invalidateSurveyResults,
    isLoading: surveyResultsLoading,
    surveyResults
  } = useSurveyResults({
    locale,
    programCatalogItemId: program_id,
    userId: user_id,
    surveyId: survey_id
  });
  const { questions } = useSurveyQuestions({ survey });
  const {
    surveyResultsToDisplay,
    isFirstDay,
    isNewestEntry,
    headerMonths
  } = useSurveyResultsToDisplay({
    surveyResults,
    offsetFromToday,
    useTextBased,
    userDetail
  });

  const updateCoachRating = async ({
    coachRatingValue,
    surveyResultId,
    shouldUpdateDetails
  }: {
    coachRatingValue: number;
    surveyResultId: string;
    shouldUpdateDetails: boolean;
  }) => {
    toast.remove();

    await setCoachRatingForSurvey({
      programId: program_id,
      userId: user_id,
      locale,
      surveyResultId,
      coachRatingValue
    }).then(() => {
      invalidateSurveyResults();
      if (shouldUpdateDetails) {
        mutateUserDetail();
      }
      onSurveyResultChange?.();
    });
  };

  if (userDetailLoading || surveyResultsLoading) {
    return <SkeletonPROPage />;
  }

  const hasRatings = survey?.ratings && survey.ratings.length > 1;
  const uniqueValues = getUniqueValues({ survey, questions });

  return (
    <PROTemplateContext.Provider
      value={{
        proImages,
        setProImage: (key, image) => {
          proImages.set(key, image);
          setProImages(new Map(proImages));
        },
        onCoachRatingChange: updateCoachRating,
        uniqueValues
      }}
    >
      <ToggleSwitch
        switchData={switchData}
        setValue={setUseTextBased}
        currentValue={useTextBased}
        className={styles.switch}
      />
      <Helmet title={survey?.surveyName} defer={false} />
      {surveyResultsToDisplay?.length ? (
        <div className={styles.PROTemplate}>
          <div
            className={cx(styles.results, {
              [styles.textResults]: useTextBased
            })}
          >
            <table className={styles.table}>
              <PROTemplateHeader
                headerMonths={headerMonths}
                offsetFromToday={offsetFromToday}
                surveyResultsToDisplay={surveyResultsToDisplay}
                useTextBased={useTextBased}
                isNewestEntry={isNewestEntry}
                setOffsetFromToday={setOffsetFromToday}
                isFirstDay={isFirstDay}
              />
              <tbody className={styles.tableBody}>
                <PROResultRow
                  hasRatings={hasRatings ?? false}
                  survey={survey}
                  surveyResultsToDisplay={surveyResultsToDisplay}
                  highlightLatestSurveyResult={highlightLatestSurveyResult}
                  openColorOptions={openColorOptions}
                  handleCloseColorOptions={handleCloseColorOptions}
                />
                {questions?.map((question, index) => (
                  <PROQuestionRow
                    hasRatings={hasRatings ?? false}
                    question={question}
                    surveyResultsToDisplay={surveyResultsToDisplay}
                    useTextBased={useTextBased}
                    key={`question-${question.id}-${index}`}
                    survey={survey}
                    setAnswerInModal={setAnswerInModal}
                    highlightLatestSurveyResult={highlightLatestSurveyResult}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <EmptyPROContainer questionsToDisplay={questions ?? []} />
      )}
      {answerInModal && (
        <Modal
          title={`${
            answerInModal.date
              ? DateTime.fromISO(
                  answerInModal.date?.toISOString()
                ).toLocaleString(DateTime.DATE_HUGE)
              : ""
          }`}
          onClose={() => setAnswerInModal(undefined)}
        >
          <Image
            className={styles.proModalImage}
            dataTestId="pro-template-modal-image"
            imageUrl={answerInModal.attachmentUrl ?? ""}
          />
        </Modal>
      )}
    </PROTemplateContext.Provider>
  );
};

export default PROTemplate;

import {
  useState,
  useEffect,
  useRef,
  useCallback,
  ReactNode,
  useMemo
} from "react";

import { Dependencies, SelectLabel } from "./CustomSelect.components";
import styles from "./CustomSelect.module.scss";
import { OptionDependencies } from "./CustomSelect.types";

import FloatingElement from "../floatingElement/FloatingElement";
import SearchInput from "../searchInput/SearchInput";

import { t } from "~/i18n";

type CustomSelectProp = {
  value?: any;
  valueKey?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
  renderOption?: (option: any) => ReactNode;
  renderSelectedOption?: (option: any) => ReactNode;
  options: any[];
  placeholder?: ReactNode;
  noOptions?: ReactNode;
  label?: string;
  dataTestId?: string;
  error?: string;
  required?: boolean;
  displayOptionsCount?: boolean;
  allowSearch?: boolean;
  dependencies?: OptionDependencies;
};

const CustomSelect = ({
  value,
  valueKey = "value",
  onChange = () => undefined,
  className = "",
  wrapperClassName = "",
  disabled,
  renderOption,
  renderSelectedOption,
  options,
  placeholder = t("select.placeholder"),
  noOptions = t("select.noOptions"),
  displayOptionsCount,
  label,
  dataTestId,
  error,
  required,
  allowSearch,
  dependencies
}: CustomSelectProp) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showError, setShowError] = useState(false);

  const [searchString, setSearchString] = useState("");

  const filteredOptions = useMemo(() => {
    if (!allowSearch || !searchString) return options;
    return options.filter((option) =>
      option.text?.toLowerCase().includes(searchString.toLowerCase())
    );
  }, [searchString, options]);

  const buttonClasses = `select ${className} ${
    disabled ? styles.disabled : ""
  } ${showOptions ? styles.active : ""} ${styles.selectButton}`;

  const classes = `${className} 
  } ${styles.customSelect}`;

  const getSelectedOption = useCallback(() => {
    return options?.find((option) => option[valueKey] === value);
  }, [options, value, valueKey]);

  const [selectedOption, setSelectedOption] = useState(getSelectedOption());

  const listRef = useRef(null);

  useEffect(() => {
    setSelectedOption(getSelectedOption());
  }, [getSelectedOption, value]);

  if (renderOption === undefined) {
    renderOption = (option) => {
      if (option === undefined) {
        return null;
      }

      return option[valueKey];
    };
  }

  if (renderSelectedOption === undefined) {
    renderSelectedOption = renderOption;
  }

  const handleOnClickOption = (e, callback, isNumber) => {
    setShowError(false);
    const event = {
      ...e,
      target: {
        value: isNumber
          ? parseInt(e.currentTarget.dataset.value)
          : e.currentTarget.dataset.value
      }
    };
    if (callback) {
      callback(e);
    }

    setShowOptions(false);

    return onChange(event);
  };

  const handleOnClickSelect = () => {
    if (!disabled) {
      setShowOptions((prev) => !prev);
    }
  };

  useEffect(() => {
    const parent = document.getElementById("options");

    if (!parent) return;

    const childs = parent.querySelectorAll("li");

    childs.forEach((childEl) => {
      const button = childEl.querySelector("button");

      if (!button) return;

      if (button.scrollWidth > button.offsetWidth) {
        childEl.classList.add(styles.carousel);
      }

      const possibleChild = button.firstChild as HTMLElement;

      if (!possibleChild) return;

      if (possibleChild.scrollWidth > possibleChild.offsetWidth) {
        childEl.classList.add(styles.carousel);
      }
    });
  }, [showOptions]);

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  const optionsMarkup = (
    <ul id="options" tabIndex={-1} className={styles.options}>
      {allowSearch && (
        <li>
          <SearchInput
            value={searchString}
            className={styles.search}
            placeholder={t("general.search")}
            onChange={setSearchString}
          />
        </li>
      )}
      {dependencies && <Dependencies dependencies={dependencies} />}
      {filteredOptions
        .filter((option) => !option.hide)
        ?.map((option, index) => (
          <li
            key={`list-item-${index}-${option[valueKey]}`}
            className={`${option[valueKey] === value ? styles.selected : ""}`}
          >
            <button
              data-value={option[valueKey]}
              tabIndex={0}
              onClick={(e) =>
                handleOnClickOption(
                  e,
                  option.onClick,
                  typeof option[valueKey] === "number"
                )
              }
              disabled={option.disabled}
            >
              {renderOption ? renderOption(option) : null}
            </button>
          </li>
        ))}
      {(!options || options.length === 0) && <li>{noOptions}</li>}
    </ul>
  );

  return (
    <div ref={listRef} className={wrapperClassName}>
      <div className={styles.labelWrapper}>
        <SelectLabel
          label={label}
          required={required}
          options={displayOptionsCount ? options : undefined}
        />
      </div>
      <FloatingElement
        floatingContent={optionsMarkup}
        triggerOnClick
        placement="bottom-start"
        hideArrow
        tooltipClassName={styles.optionsWrapper}
        useMaxWidth={false}
        enableShift={false}
        matchWidth
        controlledOpenState={{
          isOpen: showOptions,
          setIsOpen: setShowOptions
        }}
      >
        <div className={classes}>
          <button
            disabled={disabled}
            data-testid={dataTestId ?? "custom-select-btn"}
            onClick={handleOnClickSelect}
            tabIndex={disabled ? undefined : 0}
            className={buttonClasses}
            type="button"
          >
            <span>
              {selectedOption
                ? renderSelectedOption(selectedOption)
                : placeholder}
            </span>
          </button>

          {error && showError && <p className={styles.error}>{error}</p>}
        </div>
      </FloatingElement>
    </div>
  );
};

export default CustomSelect;

import styles from "./Layout.module.scss";

import Button from "../button/Button";
import Heading from "../heading/Heading";
import NavHeader from "../nav-header/NavHeader";

interface ActionItem {
  text: string;
  onClick: () => void;
  primary?: boolean;
}

type LayoutProps = {
  children: any;
  title?: string;
  titleImg?: string;
  className?: string;
  actionItems?: (ActionItem | undefined)[];
};

const Layout = ({
  children,
  title,
  titleImg,
  className,
  actionItems
}: LayoutProps) => {
  const actionItemsToRender: ActionItem[] = (actionItems || []).filter(
    (a): a is ActionItem => !!a
  );

  return (
    <>
      {/* Check if NextStep is enabled, if so change out NavHeader */}
      <NavHeader />
      <div className={`${styles.Layout} ${className ?? ""}`}>
        <div className={styles.header}>
          {titleImg && (
            <img src={titleImg} alt="Decorative logo" height="24" width="24" />
          )}
          {title && (
            <Heading level="h2" className={styles.heading}>
              {title}
            </Heading>
          )}
          <div className={styles.buttons}>
            {actionItemsToRender.map((actionItem, index) => (
              <Button
                key={index}
                inverted={!actionItem.primary}
                onClick={actionItem.onClick}
              >
                {actionItem.text}
              </Button>
            ))}
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default Layout;

import classNames from "classnames";
import { Link } from "react-router-dom";

import ActionBubble from "./components/actionBubble/ActionBubble";
import MessageActionBubble from "./components/actionBubble/MessageActionBubble";
import RestartedStatus from "./components/restart/RestartedStatus";
import { CoachUserRendered } from "./ProgramUsersPage";
import styles from "./ProgramUsersPage.module.scss";

import { ReactComponent as BloodSugarIcon } from "~/assets/blood-drop-check.svg";
import { ReactComponent as BloodPressureIcon } from "~/assets/check-heart.svg";
import MealActionsIcon from "~/assets/food.svg";
import MessagesBubbleIcon from "~/assets/messages-bubble.svg";
import PROBubbleIcon from "~/assets/pro-bubble.svg";
import ScaleNotificationIcon from "~/assets/scale-notification.svg";
import SMSNotificationIcon from "~/assets/sms-notification.svg";
import Avatar from "~/components/avatar/Avatar";
import FloatingElement from "~/components/floatingElement/FloatingElement";
import LastWeekPoints from "~/components/lastWeekPoints/LastWeekPoints";
import Notification from "~/components/notification/Notification";
import config from "~/config";
import { shouldUseLightText } from "~/helpers/colours/contrast";
import {
  getFormattedDateWithTime,
  humanizedTimeSince
} from "~/helpers/date/dateHelpers";
import { isSurveyQuiz } from "~/helpers/getLatestSurvey";
import {
  getSurveyById,
  getSurveyRating
} from "~/helpers/surveys/surveyHelpers";
import mmolToMg from "~/helpers/units/mmolToMg";
import {
  hasBeenDischargedFromProgram,
  hasFinishedProgram,
  hasQuitProgram,
  isScaleInactive,
  shouldShowRestartedStatusByRestartedDate
} from "~/helpers/user/userHelpers";
import { t } from "~/i18n";
import SurveyResultBubble from "~/pages/components/surveyResultBubble/SurveyResultBubble";
import { HealthCardType } from "~/pages/user/information/healthCards/healthCardData";
import colors from "~/styles/colors";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import {
  BloodPressureData,
  BloodSugarData,
  CoachUser,
  LastMessageType,
  Program,
  Survey
} from "~/typing/sidekickTypes";

const cx = classNames.bind(styles);

export const UserNameColumn = ({
  user,
  maxPoints,
  program
}: {
  user: CoachUser;
  maxPoints: number;
  program?: Program;
}) => {
  const userId = user.user?.userId || user?.id;

  const hasQuit = hasQuitProgram(
    user.programStatus,
    user.userProgramStatusReason
  );
  const isFinished = hasFinishedProgram(user.programStatus);

  const isDischarged = hasBeenDischargedFromProgram(
    user.programStatus,
    user.userProgramStatusReason
  );

  return (
    <Link to={`${userId}/information`}>
      <div className={styles.user}>
        <Avatar user={user.user} className={styles.avatar} />
        <div className={styles.userDetails}>
          <div
            className={`${styles.userName} ${
              hasQuit || isFinished || isDischarged ? styles.disabledUser : ""
            }`}
            data-testid="users-page-username"
          >
            {user.user.fullName ?? `[${t("warnings.noName")}]`}
            {!hasQuit && !isFinished && user.currentSlot && user.dayInSlot && (
              <span
                className={styles.currentSlot}
              >{` W${user.currentSlot}D${user.dayInSlot}`}</span>
            )}
            {shouldShowRestartedStatusByRestartedDate(user, program) && (
              <RestartedStatus
                className={styles.restartedStatus}
                userId={user.id}
                restartedDate={user.restartedDate}
              />
            )}
          </div>
          <LastWeekPoints
            userId={userId}
            maxPoints={maxPoints}
            displayName={user.user.fullName}
          />
        </div>
      </div>
    </Link>
  );
};

export const LastMessageDateColumn = ({
  user,
  isSmallerThanDesktop
}: {
  user: CoachUser;
  isSmallerThanDesktop: boolean;
}) => {
  if (!user.lastMessageDate) return null;

  return (
    <div className={styles.lastMessage}>
      <Notification
        className={`${styles.lastMessageNotification} ${
          user?.lastMessageType === LastMessageType.SMS ? styles.sms : ""
        }`}
        size="xs"
        count={1}
      />
      <p>
        {humanizedTimeSince(
          user.lastMessageDate ?? "",
          Boolean(isSmallerThanDesktop)
        )}
      </p>
    </div>
  );
};

export const PROResultColumn = ({
  user,
  surveys
}: {
  user: CoachUserRendered;
  surveys: Survey[];
}) => {
  if (user.user.selectedProResult === undefined) {
    return null;
  }

  const survey = getSurveyById(surveys, user.user.selectedSurveyId ?? "");

  const rating = getSurveyRating(user.user.selectedProResult, survey);

  const hasQuit = hasQuitProgram(
    user.programStatus,
    user.userProgramStatusReason
  );
  const isFinished = hasFinishedProgram(
    user.programStatus,
    user.userProgramStatusReason
  );

  const showPROResultNotification =
    !config.isAnthem &&
    user?.latestSurveyResults?.some(
      (result) => result.surveyId === survey?.id && !result.coachRatingDate
    ) &&
    !hasQuit &&
    !isFinished;

  return (
    <SurveyResultBubble
      // TODO TVT: Remove when surveys support Care Portal Content Items
      text={
        survey?.title != null &&
        survey.title.includes("Covid-19 Symptom Survey")
          ? survey.surveyName
          : survey?.title
      }
      result={user.user.selectedProResult}
      style={{
        background:
          user.user.selectedProColor || rating?.colour || colors.navyBlue20
      }}
      useLightText={shouldUseLightText(rating)}
      showNotifcation={showPROResultNotification}
    />
  );
};

export const ActionsAwaitingColumn = ({
  user,
  surveys,
  usingBodyTraceScale
}: {
  user: CoachUserRendered;
  surveys: Survey[];
  usingBodyTraceScale: boolean;
}) => {
  const userId = user.user?.userId || user?.id;
  const hasSurveyActions = user.latestSurveyResults?.some(
    (survey) =>
      !isSurveyQuiz(survey?.surveyName, surveys) && !survey?.coachRatingDate
  );
  const hasQuit = hasQuitProgram(
    user.programStatus,
    user.userProgramStatusReason
  );
  const isFinished = hasFinishedProgram(user.programStatus);
  const isDischarged = hasBeenDischargedFromProgram(
    user.programStatus,
    user.userProgramStatusReason
  );

  const {
    trackActionAwaitingMealSelected,
    trackActionAwaitingMessageSelected,
    trackActionAwaitingPROSelected
  } = useAmplitudeTracking();

  return (
    <div className={styles.actions}>
      {!(hasQuit || isFinished || isDischarged) && (
        <>
          {user.user.shouldShowFoodLogNotification && (
            <ActionBubble
              tooltipContent={t("programUserList.actionTooltip.meal")}
              icon={MealActionsIcon}
              url={`${userId}/food-journal`}
              isActive={user.hasMealLogActions}
              bottomText={humanizedTimeSince(user?.oldestPendingFoodLogDate)}
              onClick={() => trackActionAwaitingMealSelected({ userId })}
            />
          )}
          {user.user.shouldShowPRONotification && (
            <ActionBubble
              tooltipContent={t("programUserList.actionTooltip.survey")}
              icon={PROBubbleIcon}
              url={`${userId}/pro/${user.user.selectedSurveyId || ""}`}
              isActive={hasSurveyActions}
              bottomText={humanizedTimeSince(user?.oldestPendingSurveyResult)}
              onClick={() =>
                trackActionAwaitingPROSelected({
                  userId
                })
              }
            />
          )}
          {user.user.shouldShowMessageNotification &&
            (config.isAnthem ? (
              <ActionBubble
                tooltipContent={t("programUserList.actionTooltip.message")}
                icon={MessagesBubbleIcon}
                url={`${userId}/information?openMessages=true`}
                extraAttention={user.hasMessageActions}
                isActive={
                  user.hasNonRecipientsMessageActions || user.hasMessageActions
                }
                actionCount={
                  user.unseenMessageCount || user.unseenNonRecipientMessageCount
                }
                bottomText={humanizedTimeSince(
                  user.hasMessageActions
                    ? user?.oldestUnseenMessageFromUserDate
                    : user?.oldestUnseenNonRecipientMessageFromUserDate
                )}
                onClick={() =>
                  trackActionAwaitingMessageSelected({
                    userId,
                    isSms: false,
                    isCmRecipient: user.hasMessageActions
                  })
                }
              />
            ) : (
              <MessageActionBubble
                url={`${userId}/information?openMessages=true`}
                extraAttention={user.hasMessageActions}
                isActive={
                  user.hasNonRecipientsMessageActions || user.hasMessageActions
                }
                actionCount={
                  user.unseenMessageCount || user.unseenNonRecipientMessageCount
                }
                lastMessageDate={
                  user.oldestUnseenMessageFromUserDate ||
                  user.oldestUnseenNonRecipientMessageFromUserDate ||
                  ""
                }
                overallSentiment={user.overallSentiment}
                possibleTechnicalProblem={user.possibleUnseenTechnicalProblem}
                bottomText={humanizedTimeSince(
                  user.hasMessageActions
                    ? user?.oldestUnseenMessageFromUserDate
                    : user?.oldestUnseenNonRecipientMessageFromUserDate
                )}
                userId={userId}
              />
            ))}
          {user.user.shouldShowSMSNotification && (
            <ActionBubble
              tooltipContent={t("registerPatient.unreadSms")}
              icon={SMSNotificationIcon}
              isActive={user.unseenSms ?? false}
              url={`${userId}/information?openMessages=true&useSms=true`}
              className={styles.smsBubble}
              bottomText={humanizedTimeSince(user?.lastUnseenSmsDate)}
            />
          )}
          {!!usingBodyTraceScale &&
            user.user.shouldShowInactiveScaleNotification && (
              <ActionBubble
                tooltipContent={"Weight scale inactive"}
                icon={ScaleNotificationIcon}
                isActive={isScaleInactive(user)}
              />
            )}
        </>
      )}
    </div>
  );
};

const BloodSugarMetricsColumn = ({ user }: { user: CoachUserRendered }) => {
  if (!user.fastingBloodSugar && !user.mealBloodSugar) {
    return null;
  }

  const getNewestBloodSugarEntry = (): {
    bloodSugar?: BloodSugarData;
    type: "fasting" | "meal";
  } => {
    if (!user.fastingBloodSugar)
      return { bloodSugar: user.mealBloodSugar, type: "meal" };
    if (!user.mealBloodSugar)
      return { bloodSugar: user.fastingBloodSugar, type: "fasting" };
    return user.fastingBloodSugar.date > user.mealBloodSugar.date
      ? { bloodSugar: user.fastingBloodSugar, type: "fasting" }
      : { bloodSugar: user.mealBloodSugar, type: "meal" };
  };

  const getBloodSugarStatus = ({
    bloodSugar,
    type
  }: {
    bloodSugar?: BloodSugarData;
    type: "fasting" | "meal";
  }): {
    color: string;
    colorCode: "green" | "yellow" | "red" | "orange";
  } => {
    if (!bloodSugar) return { color: colors.neonGreen140, colorCode: "green" };

    const mgValue = mmolToMg(bloodSugar.value);

    if (!mgValue) return { color: colors.neonGreen140, colorCode: "green" };

    if (type === "meal") {
      if (mgValue > 200) {
        return { color: colors.neonGreen140, colorCode: "green" };
      } else {
        return { color: colors.red120, colorCode: "red" };
      }
    } else {
      if (mgValue < 100) {
        return { color: colors.neonGreen140, colorCode: "green" };
      } else if (mgValue < 126) {
        return { color: colors.mustardYellow100, colorCode: "yellow" };
      } else {
        return { color: colors.red120, colorCode: "red" };
      }
    }
  };

  const BloodSugarTooltipContent = ({
    bloodSugar,
    type
  }: {
    bloodSugar?: BloodSugarData;
    type: "fasting" | "meal";
  }) => {
    if (!bloodSugar) return null;
    const bloodSugarStatus = getBloodSugarStatus({ bloodSugar, type });

    return (
      <HealthMetricTooltipContent
        title={
          type === "fasting"
            ? t("health.bloodSugar.fastingBloodSugar")
            : t("health.bloodSugar.nonFastingBloodSugar")
        }
        date={bloodSugar.date}
        value={`${mmolToMg(bloodSugar.value)} mg/dL`}
        colorCode={bloodSugarStatus.colorCode}
      />
    );
  };

  const newestEntry = getNewestBloodSugarEntry();

  if (!newestEntry.bloodSugar) return null;

  return (
    <FloatingElement
      floatingContent={
        <div className={styles.healthMetricTooltipWrapper}>
          <BloodSugarTooltipContent
            bloodSugar={user.fastingBloodSugar}
            type={"fasting"}
          />
          <BloodSugarTooltipContent
            bloodSugar={user.mealBloodSugar}
            type={"meal"}
          />
        </div>
      }
    >
      <div className={styles.iconWrapper}>
        <BloodSugarIcon color={getBloodSugarStatus(newestEntry).color} />
        <p>{humanizedTimeSince(newestEntry.bloodSugar.date)}</p>
      </div>
    </FloatingElement>
  );
};

const HealthMetricTooltipContent = ({
  title,
  date,
  value,
  valueDescription,
  colorCode
}: {
  title: string;
  date: string;
  value: string;
  valueDescription?: string;
  colorCode: "green" | "yellow" | "red" | "orange";
}) => {
  return (
    <div className={styles.healthMetricTooltip}>
      <p className={styles.title}>{title}</p>
      <p className={styles.date}>{getFormattedDateWithTime(date)}</p>{" "}
      <div className={cx(styles.value, styles[colorCode])}>
        {valueDescription && <p>{valueDescription}</p>}
        <p>{value}</p>
      </div>
    </div>
  );
};

const BloodPressureMetricsColumn = ({ user }: { user: CoachUserRendered }) => {
  if (!user.bloodPressure) return null;

  const getBloodPressureStatus = (
    bloodPressure: BloodPressureData
  ): {
    color: string;
    colorCode: "green" | "yellow" | "red" | "orange";
  } => {
    if (!user.bloodPressure)
      return { color: colors.neonGreen140, colorCode: "green" };
    if (bloodPressure.systolic < 120 && bloodPressure.diastolic < 80) {
      return { color: colors.neonGreen140, colorCode: "green" };
    } else if (bloodPressure.systolic < 130 && bloodPressure.diastolic < 80) {
      return { color: colors.mustardYellow100, colorCode: "yellow" };
    } else if (bloodPressure.systolic < 140 && bloodPressure.diastolic < 90) {
      return { color: colors.orange100, colorCode: "orange" };
    }

    return { color: colors.red120, colorCode: "red" };
  };

  const BloodPressureTooltipContent = ({
    bloodPressure
  }: {
    bloodPressure?: BloodPressureData;
  }) => {
    if (!bloodPressure) return null;
    const bloodPressureStatus = getBloodPressureStatus(bloodPressure);

    const getValueDescription = () => {
      switch (bloodPressureStatus.colorCode) {
        case "green":
          return t("user.health.bloodPressure.normalBp");
        case "yellow":
          return t("user.health.bloodPressure.elevatedBp");
        case "orange":
          return t("user.health.bloodPressure.stage1Hypertension");
        case "red":
          return t("user.health.bloodPressure.stage2Hypertension");
      }
    };

    return (
      <HealthMetricTooltipContent
        title={t("user.health.bloodPressure.bloodPressure")}
        valueDescription={getValueDescription()}
        date={bloodPressure.date}
        value={`${bloodPressure.systolic}/${bloodPressure.diastolic}`}
        colorCode={bloodPressureStatus.colorCode}
      />
    );
  };

  return (
    <FloatingElement
      floatingContent={
        <BloodPressureTooltipContent bloodPressure={user.bloodPressure} />
      }
    >
      <div className={styles.iconWrapper}>
        <BloodPressureIcon
          color={getBloodPressureStatus(user.bloodPressure).color}
        />
        <p>{humanizedTimeSince(user.bloodPressure.date)}</p>
      </div>
    </FloatingElement>
  );
};

export const HealthMetricsColumn = ({ user }: { user: CoachUserRendered }) => {
  return (
    <div className={styles.healthMetrics}>
      <Link
        to={`${user.id}/information?openHealthCard=${HealthCardType.BloodPressue}`}
      >
        <BloodPressureMetricsColumn user={user} />
      </Link>
      <Link
        to={`${user.id}/information?openHealthCard=${HealthCardType.BloodSugar}`}
      >
        <BloodSugarMetricsColumn user={user} />
      </Link>
    </div>
  );
};

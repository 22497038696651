import { useMutation } from "@tanstack/react-query";

import { actOnInvite, inviteCareManagers } from "~/api/graphql/invites";
import { CreateInvitesDtoInput } from "~/gql/graphql";

export const useInviteCareManagers = () => {
  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationKey: ["invites"],
    mutationFn: (invite: CreateInvitesDtoInput) => inviteCareManagers(invite)
  });

  return {
    inviteCareManagers: mutate,
    isPending,
    isSuccess,
    isError
  };
};

export const useActOnInvite = () => {
  const { mutate, isPending, isSuccess, isError, error } = useMutation({
    mutationKey: ["invites"],
    mutationFn: ({ coachId, token }: { coachId: string; token: string }) =>
      actOnInvite(coachId, token)
  });

  return {
    actOnInvite: mutate,
    isPending,
    isSuccess,
    isError,
    error
  };
};
